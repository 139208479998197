import React from "react";

import { ClientLayout } from "../../../components/Utils/Layout";
import ProjectsSection from "../../../components/Projects/ProjectsSection";

const ProjectPage = ({auth, logout}) => {
  return (
    <ClientLayout content_title="Proyectos" auth={auth} logout={logout}>
      <ProjectsSection/>
    </ClientLayout>
  );
};

export default ProjectPage;
