import React, { useState, useEffect, useRef } from "react";
import { formatFromMillisToTimeSession, getTimeSessionInMillis } from "../../../helpers/date";

const SessionTimer = ({ lastConnection, time_session }) => {

  // console.log('lastConnection', lastConnection)
  // console.log('time_session', time_session)

  const [time, setTime] = useState(new Date());

  useInterval(() => {
    setTime(new Date());
  }, 1000);

  return <>{formatFromMillisToTimeSession(time_session + getTimeSessionInMillis(new Date(lastConnection), time))}</>;
};

export default SessionTimer;

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
