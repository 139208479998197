import React from "react";

import { AgencyLayout } from "../../../components/Utils/Layout";
import ProjectsSection from "../../../components/Projects/ProjectsSection";

const ProjectPage = ({auth, logout}) => {
  return (
    <AgencyLayout content_title="Proyectos" auth={auth} logout={logout}>
      <ProjectsSection/>
    </AgencyLayout>
  );
};

export default ProjectPage;
