
// react
import React, { useContext, useMemo, useState } from "react";

// styles
import { Avatar, Modal, Upload, message, Button } from "antd";
import { CameraOutlined, InboxOutlined } from "@ant-design/icons";
import "./IconProfileChanger.css";

// auth
import { AuthContext } from "../../contexts/auth/AuthContext"

// misc
import { fetchDataToken } from "../../helpers/fetch";
import { ACCESS_TOKEN_NAME, API_BASE_URL } from "../../constants/apiContants";

const IconProfileChanger = () => {
  const { auth, setAuth } = useContext(AuthContext);
  const [hoverChange, setHoverChange] = useState(null);
  const { Dragger } = Upload;
  function destroyAll() {
    Modal.destroyAll();
  }

  const initials = useMemo(() => {
    if (auth?.user) {
      const letterFirstName = auth.user.name[0].toUpperCase();
      const letterLastName = auth.user.first_lastname[0].toUpperCase();
      return `${letterFirstName}${letterLastName}`;
    }
    // eslint-disable-next-line
  }, [auth]);

  const props = {
    name: "avatar",
    multiple: false,
    accept: "image/png, image/jpeg, image/jpg",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_NAME)}`,
      "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
    },
    action: `${API_BASE_URL}/user/avatar`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    customRequest: async (options) => {
      try {
        const formData = new FormData();
        formData.set("avatar", options.file);
        const response = await fetchDataToken("/user/avatar", formData, "POST");
        if (response.status === 200) {
          localStorage.setItem(ACCESS_TOKEN_NAME, response.data.token);
          setAuth({
            ...auth,
            user: response.data.user,
          });
          options.onSuccess(response.status === 200);
          destroyAll();
        }
      } catch (error) {
        options.onError(false);
        console.error(error);
      }
    },
  };

  const handleDeleteImage = async () => {
    try {
      const response = await fetchDataToken("/user/avatar", {}, "DELETE");
      if (response.status === 200) {
        localStorage.setItem(ACCESS_TOKEN_NAME, response.data.token);
        setAuth({
          ...auth,
          user: response.data.user,
        });
        message.success("file deleted successfully.");
        destroyAll();
      }
    } catch (error) {
      console.error(error);
      message.error("file deleted failed.");
    }
  };

  const handleUploadFile = (e) => {
    e.preventDefault();
    Modal.info({
      title: "Subir imagen",
      content: (
        <>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Has click o arrastra el archivo hasta esta zona</p>
            <p className="ant-upload-hint">Sube una imagen .jpg o .png</p>
          </Dragger>
          {auth.user.avatar ? (
            <Button type="text" onClick={handleDeleteImage} danger block>
              Eliminar foto
            </Button>
          ) : (
            ""
          )}
        </>
      ),
      onOk() {},
    });
  };

  return (
    <div style={{ textAlign: "center", margin: "25px" }}>
      {auth.user.avatar ? (
        <Avatar
          size={{ xs: 80, sm: 80, md: 80, lg: 100, xl: 120, xxl: 120 }}
          src={auth.user.avatar}
          onPointerEnter={() => setHoverChange(true)}
          onPointerLeave={() => setHoverChange(false)}
          onClick={handleUploadFile}
          id="icon-profile-changer"
        >
          <div
            style={{
              visibility: hoverChange ? "visible" : "hidden",
              position: "absolute",
              zIndex: "2",
              backgroundColor: "rgba(0,0,0,0.6)",
              width: "350%",
              height: "150%",
              inset: "0",
              transform: "translate(-35%, 45%)",
            }}
          >
            <CameraOutlined
              style={{ fontSize: "28px", position: "absolute", top: "0", left: "0", right: "0", opacity: "0.7" }}
            />
          </div>
        </Avatar>
      ) : (
        <Avatar
          size={{ xs: 80, sm: 80, md: 80, lg: 100, xl: 120, xxl: 120 }}
          style={{ color: "white", backgroundColor: auth?.user?.avatar_color || "#0188d1", position: "relative", cursor: "pointer" }}
          onPointerEnter={() => setHoverChange(true)}
          onPointerLeave={() => setHoverChange(false)}
          onClick={handleUploadFile}
          id="icon-profile-changer"
        >
          <span style={{ fontSize: "32px" }}>{initials}</span>
        </Avatar>
      )}
    </div>
  );
};

export default IconProfileChanger;
