import React, { useContext } from "react";
import { Switch, useRouteMatch, Redirect } from "react-router-dom";
import AdminDashboardPage from "./AdminDashboardPage";
import AdminPrivateRoute from "../../router/admin/AdminPrivateRoute";

// import TokensPage from "./Tokens/Tokens";
// import AgenciesSection from "./Agencies/AgenciesSection";
import { AuthContext } from "../../contexts/auth/AuthContext";

// misc
import Loading from "../../components/Utils/Loading/Loading";
import ClientPage from "./Clients/ClientPage";
import AgencyPage from "./Agencies/AgencyPage";
import ProjectPage from "./Projects/ProjectPage";
import UserPage from "./Users/UserPage";
import WebhookPage from "./Webhooks/WebhookPage";

const AdminRouter = () => {
  const { auth, logout } = useContext(AuthContext);
  const { url } = useRouteMatch();

  if (auth.logged === null) {
    return <Loading />;
  }
  if (!auth?.is_admin) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <Switch>
      <AdminPrivateRoute path={`${url}/clients`}>
        <ClientPage auth={auth} logout={logout} />
      </AdminPrivateRoute>
      <AdminPrivateRoute path={`${url}/agencies`}>
        <AgencyPage auth={auth} logout={logout} />
      </AdminPrivateRoute>
      <AdminPrivateRoute path={`${url}/projects`}>
        <ProjectPage auth={auth} logout={logout} />
      </AdminPrivateRoute>
      <AdminPrivateRoute path={`${url}/users`}>
        <UserPage auth={auth} logout={logout} />
      </AdminPrivateRoute>
      <AdminPrivateRoute path={`${url}/webhooks`}>
        <WebhookPage auth={auth} logout={logout} />
      </AdminPrivateRoute>
      {/* <AdminPrivateRoute path={`${url}/api-keys`} component={ApiKeysPage} /> */}
      <AdminPrivateRoute path={`${url}`}>
        <AdminDashboardPage auth={auth} logout={logout} />
      </AdminPrivateRoute>
    </Switch>
  );
};

export default AdminRouter;
