import React, { useEffect, useState } from "react";

// styles
import {
  Table,
  Button,
  Popconfirm,
  Divider,
  Row,
  Typography,
  Tooltip,
} from "antd";

import { newDate } from "../../../../helpers/date";
import UpdateCampaign from "./UpdateCampaign/UpdateCompaign";

import Filter from "../../../../components/Utils/Table/Filter/Filter";

import _ from "lodash";

const CampaignsList = ({
  campaigns,
  fetchCampaigns,
  deleteCampaign,
  updateCampaign,
  fetchChannels,
  channels,
}) => {
  const { Text } = Typography;
  const [setFilters] = useState(null);

  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);

  useEffect(() => {
    fetchChannels();
  }, [fetchChannels]);

  const handleFilter = () => {
    console.log("gooo filter");
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Referencia",
      dataIndex: "campaign_reference",
      key: "campaign_reference",
    },
    {
      title: "Canal",
      dataIndex: "channel",
      key: "channel",
      render: (data) => {
        return (
          data?.channel_reference ||
          _.find(channels, { id: data })?.channel_reference ||
          ""
        );
      },
    },
    {
      title: "Url generada",
      dataIndex: "url_generated",
      key: "url_generated",
      ellipsis: true,
      width: "25%",
      render: (data) => (
        <Tooltip
          mouseEnterDelay={1}
          title={
            <a href={data} rel="noreferrer" target="_blank">
              {data}
            </a>
          }
        >
          <Text ellipsis copyable={{ tooltips: ["Copiar", "Copiado"] }}>
            {data}
          </Text>
        </Tooltip>
      ),
      onCell: () => {
        return {
          onMouseEnter: (e) => {
            const element = e.target;
            element.style.cursor = "pointer";
          },
        };
      },
    },
    {
      title: "Fecha alta",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "10%",
      render: (data) => newDate(data).toFormat("dd'-'LL'-'yyyy T"),
    },
    {
      title: "Visitas",
      dataIndex: "visits",
      key: "visits",
      width: "5%",
      align: "right",
    },
    {
      title: "Leads",
      dataIndex: "leads",
      key: "leads",
      width: "5%",
      align: "right",
    },
    {
      title: "Editar",
      key: "editar",
      width: "10%",
      render: (_, record) => (
        <UpdateCampaign
          record={record}
          updateCampaign={updateCampaign}
          channels={channels}
        />
      ),
      align: "center",
    },
    {
      title: "Eliminar",
      key: "eliminar",
      align: "center",
      width: "10%",
      render: (_, record) => (
        <Popconfirm
          title="¿Estás seguro?"
          okText="Si"
          cancelText="No"
          onConfirm={() => deleteCampaign(record?.id)}
        >
          <Button type="primary">Eliminar</Button>
        </Popconfirm>
      ),
    },
  ];

  const fields = [{ label: "Nombre/Ref", type: "text", key: "usuario" }];

  return (
    <>
      <Row>
        <Row
          justify="space-between"
          style={{ minWidth: "100%", alignItems: "center" }}
        >
          <Filter
            setFilters={setFilters}
            fields={fields}
            handleFilter={handleFilter}
          />
        </Row>

        <Divider style={{ margin: "15px 0" }} />
      </Row>

      <Table
        columns={columns}
        dataSource={campaigns}
        pagination={false}
        size="small"
        bordered={true}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default CampaignsList;
