// react
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

// styles
import { Layout, Menu } from "antd";
import "./Sidebar.css";

import { IdcardOutlined } from "@ant-design/icons";

const SideBar = () => {
  const { Sider } = Layout;
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = (collapsed) => setCollapsed(collapsed);

  const menuItems = [
    {
      key: "/admin",
      label: (
        <>
          <IdcardOutlined style={{ fontSize: "18px" }} />
          <span>Dashboard</span>
          <Link to={`/admin`} />
        </>
      ),
    },
    {
      key: "/admin/agencies",
      label: (
        <>
          <IdcardOutlined style={{ fontSize: "18px" }} />
          <span>Agencias</span>
          <Link to={`/admin/agencies`} />
        </>
      ),
    },
    {
      key: "/admin/clients",
      label: (
        <>
          <IdcardOutlined style={{ fontSize: "18px" }} />
          <span>Clientes</span>
          <Link to={`/admin/clients`} />
        </>
      ),
    },
    {
      key: "/admin/projects",
      label: (
        <>
          <IdcardOutlined style={{ fontSize: "18px" }} />
          <span>Proyectos</span>
          <Link to={`/admin/projects`} />
        </>
      ),
    },
    // {
    //   key: "/admin/tokens",
    //   label: (
    //     <>
    //       <IdcardOutlined style={{ fontSize: "18px" }} />
    //       <span>Tokens</span>
    //       <Link to={`/admin/tokens`} />
    //     </>
    //   ),
    // },
    {
      key: "/admin/users",
      label: (
        <>
          <IdcardOutlined style={{ fontSize: "18px" }} />
          <span>Usuarios</span>
          <Link to={`/admin/users`} />
        </>
      ),
    },
    // {
    //   key: "/admin/keys",
    //   label: (
    //     <>
    //       <IdcardOutlined style={{ fontSize: "18px" }} />
    //       <span>API keys / secret</span>
    //       <Link to={`/admin/keys`} />
    //     </>
    //   ),
    // },
    {
      key: "/admin/webhooks",
      label: (
        <>
          <IdcardOutlined style={{ fontSize: "18px" }} />
          <span>Webhooks</span>
          <Link to={`/admin/webhooks`} />
        </>
      ),
    },
  ];

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      theme="light"
      style={{
        marginTop: "-1px",
        minHeight: "91.3vh",
        maxWidth: "auto",
      }}
    >
      <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
        style={{
          height: "100%",
          backgroundColor: "#fafafa",
        }}
        items={menuItems}
      ></Menu>
    </Sider>
  );
};

export default SideBar;
