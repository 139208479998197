// react
import { useCallback, useContext, useState } from "react";

// misc
import { errorNotification } from "../components/Utils/Notification/Notification";
import { ProjectsContext } from "../contexts/projects/ProjectsContext";
import { fetchDataToken } from "../helpers/fetch";

const usePages = () => {
  const { selectedProject } = useContext(ProjectsContext);
  const [pages, setPages] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const fetchPages = useCallback(
    async (params = {}) => {
      try {
        if (selectedProject?.id) {
          const response = await fetchDataToken(
            "/page/today",
            { project: selectedProject?.id, ...params },
            "GET"
          );
          if (response.status === 200) {
            setPages(response.data?.pages || []);
            setTotalPages(response.data?.count || 0);
          }
        }
      } catch (error) {
        if (error.response) {
          errorNotification("Error", error.response.data.msg);
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error.message);
        }
      }
    },
    [selectedProject?.id]
  );

  const updatePage = useCallback(
    async (id, data) => {
      try {
        if (selectedProject?.id) {
          const response = await fetchDataToken(`/page/${id}`, data, "PUT");
          if (response.status === 200) {
            setPages((prev) =>
              prev.map((p) => {
                if (p?.id === id) {
                  return response.data;
                }
                return p;
              })
            );
            setTotalPages(response.data?.count || 0);
          }
        }
      } catch (error) {
        if (error.response) {
          errorNotification("Error", error.response.data.msg);
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error.message);
        }
      }
    },
    [selectedProject?.id]
  );

  return { pages, fetchPages, updatePage, totalPages };
};

export default usePages;
