import React from "react";
import { Form, Input, Button, DatePicker, Select } from "antd";
import locale from "antd/es/locale/es_ES";

function Filter({ setFilters, fields, handleFilter }) {
  const [form] = Form.useForm();

  const getFields = (fields) => {
    const count = fields.length;

    const children = fields.map((field, i) => {
      if (i < count) {
        switch (field.type) {
          case "text":
            return (
              <div key={`${field.key}`} style={{ margin: "5px 5px 5px 0" }}>
                <b> {`${field.label}`}</b>
                <Form.Item name={`${field.key}`} style={{ margin: "0px" }}>
                  <Input placeholder={field.label} />
                </Form.Item>
              </div>
            );
          case "date_from":
            return (
              <div
                key={field.key}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "10px 10px 10px 0",
                }}
              >
                <b> {`${field.label}`}</b>
                <Form.Item name={field.key} style={{ margin: "0px" }}>
                  <DatePicker
                    name="date_from"
                    format="DD-MM-YYYY"
                    locale={locale}
                    placeholder="Fecha inicial"
                  />
                </Form.Item>
              </div>
            );
          case "date_to":
            return (
              <div
                key={field.key}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "10px 10px 10px 0",
                }}
              >
                <b> {`${field.label}`}</b>
                <Form.Item name={field.key} style={{ margin: "0px" }}>
                  <DatePicker
                    name="date_from"
                    format="DD-MM-YYYY"
                    locale={locale}
                    placeholder="Fecha final"
                  />
                </Form.Item>
              </div>
            );
          case "select":
            const { Option } = Select;
            const onSelect = (_value, data) => {
              form.setFieldsValue({
                [data.key]: data.key,
              });
            };

            return (
              <div
                key={field.key}
                style={{ margin: "25px 10px 0px 0px", minWidth: "200px" }}
              >
                <b> {`${field.label}`}</b>
                <Form.Item
                  name={field.key}
                  // label="Select[multiple]"
                >
                  <Select
                    placeholder={field.placeholder}
                    showArrow
                    onSelect={onSelect}
                  >
                    {field.data?.map((u) => {
                      return (
                        <Option
                          value={u[field.keyToOptions]}
                          key={u[field.keyToOptions]}
                        >{`${u.name} ${u.first_lastname}`}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            );
          default:
            break;
        }
      }

      return <div key={i}></div>;
    });

    return children;
  };

  const onChange = (value) => {
    if (value?.fecha) {
      const date_from = new Date(value.fecha[0]);
      const date_to = new Date(value.fecha[1]);
      setFilters((prev) => ({ ...prev, date_from, date_to }));
      return;
    }
    setFilters((prev) => ({ ...prev, ...value }));
  };

  return (
    <div
      style={{
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
        minWidth: "100%",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        padding: "20px",
      }}
    >
      {/* <DateFilter setFilters={setFilters} /> */}

      <Form
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onValuesChange={onChange}
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        {getFields(fields)}

        <Button
          style={{
            margin: "20px 5px 0px 5px",
            display: "inline-block",
          }}
          onClick={() => {
            form.resetFields();
            setFilters({ ...form.getFieldsValue() });
            handleFilter();
          }}
        >
          Limpiar
        </Button>

        <Button
          onClick={handleFilter}
          type="primary"
          style={{ marginTop: "20px" }}
        >
          Filtrar
        </Button>
      </Form>
    </div>
  );
}

export default Filter;
