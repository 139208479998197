import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Select } from "antd";
import "./AgencyForm.css";
import TextArea from "antd/lib/input/TextArea";
import { errorNotification } from "../../Utils/Notification/Notification";

const { Option } = Select;

const AgencyForm = ({
  visible,
  setVisible,
  addAgency,
  updateAgency,
  record,
  clients,
}) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalTitle, setmodalTitle] = useState("Crear una Agencia");

  const handleOk = async () => {
    try {
      setConfirmLoading(true);

      const data = await form.validateFields();
      const populate = "created_by_user";
      if (record) {
        delete data.secret_key;
        delete data.api_token;
        await updateAgency(record?.id, data, { populate });
      } else {
        await addAgency(data, { populate });
      }

      setVisible(false);
      setConfirmLoading(false);
      form.resetFields();
    } catch (error) {
      console.error(error);
      errorNotification("ERROR", error.message);
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (record) {
      setmodalTitle("Editar una Agencia");
    }
  }, [record]);

  useEffect(() => {
    if (record?.keys?.api && record?.keys?.secret) {
      form.setFieldsValue({
        secret_key: record?.keys?.secret,
        api_token: record?.keys?.api,
      });
    }
    if (record?.clients?.length) {
      const clients = record?.clients?.map((_client) => {
        return _client?.id || _client;
      });
      form.setFieldsValue({
        clients,
      });
    }
  }, [record?.keys?.api, record?.keys?.secret, form, record?.clients]);

  const onClientsChange = (value) => {
    form.setFieldsValue({ clients: value });
  };

  const buildFields = (_record) => {
    return (
      <>
        <Form.Item
          label="Nombre de Agencia"
          name="name"
          rules={[{ required: true, message: "Es necesario!" }]}
          id="name"
        >
          <Input
            placeholder="Nombre de Agencia"
            value={form.getFieldValue("name")}
          />
        </Form.Item>
        {_record ? (
          <>
            {
              <Form.Item
                label="Clientes"
                name="clients"
                id="clients"
                rules={[{ required: true, message: "Es necesario!" }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  name="clients"
                  id="clients"
                  onChange={onClientsChange}
                  placeholder="Selecciona los clientes"
                >
                  {clients.map((client) => {
                    return (
                      <Option
                        key={client?.id}
                        id={client?.id}
                        value={client?.id}
                      >
                        {client?.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            }
          </>
        ) : null}
        {_record ? (
          <>
            <Form.Item label="Secret Key" name="secret_key" id="secret_key">
              <Input
                placeholder="Secret Key"
                value={form.getFieldValue("secret_key")}
              />
            </Form.Item>
            <Form.Item label="API Token" name="api_token" id="api_token">
              <TextArea
                autoSize={true}
                placeholder="API Token"
                value={form.getFieldValue("api_token")}
              />
            </Form.Item>
          </>
        ) : null}
        <Form.Item label="Tema" name="theme" id="theme">
          <Input placeholder="default" value={form.getFieldValue("theme")} />
        </Form.Item>
      </>
    );
  };

  return (
    <Modal
      forceRender
      getContainer={false}
      title={modalTitle}
      visible={visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      okText="Guardar"
      cancelText="Cancelar"
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        onFinish={handleOk}
        onFinishFailed={() => {
          console.error("form failed");
        }}
        initialValues={record}
      >
        {buildFields(record)}
      </Form>
    </Modal>
  );
};

export default AgencyForm;
