import React, { useContext } from "react";
import { createContext } from "react";
import { useSocket } from "../../hooks/useSocket";
import { ProjectsContext } from "../projects/ProjectsContext";

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { selectedProject } = useContext(ProjectsContext);
  const { socket, socketOnline } = useSocket(selectedProject?.pixel_id);

  return (
    <SocketContext.Provider
      value={{
        socket,
        socketOnline,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
