import React, { useState } from "react";
import { Button } from "antd";
import AddCampaignForm from "./AddCampaignForm";

const AddCampaign = ({ addCampaign, channels }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Añadir Campaña
      </Button>

      <AddCampaignForm
        setVisible={setVisible}
        visible={visible}
        addCampaign={addCampaign}
        channels={channels}
      />
    </>
  );
};

export default AddCampaign;
