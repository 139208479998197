import React from "react";
import "./Popup.css";
import {
  HistoryOutlined,
  /* MessageOutlined, */ UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { successNotification } from "../../Notification/Notification"

import copy from "copy-to-clipboard";

// import DraggableModalChat from "../../Chat/DraggableModalChat";

const Popup = ({ record, visible, x, y, setPopup, RT }) => {
  const openModal = () => {
    setPopup((prev) => ({ ...prev, showModal: true, visible: false }));
  };

  const openToogle = (option) => {
    openModal();
    setPopup((prev) => ({ ...prev, option }));
  };

  const copyVisitorId = (visitor) => {
    copy(visitor, { format: "text/plain" });
    successNotification("ID copiado en el portapapeles")
  };

  // const initChat = () => {
  //   return <DraggableModalChat visit={record} visible={true}/>;
  // };

  return (
    <>
      {visible && (
        <ul className="popup" style={{ left: `${x}px`, top: `${y}px` }}>
          <li
            data-id={record.visitor?.id}
            onClick={() => copyVisitorId(record.visitor?.id)}
          >
            <UserOutlined />
            <span style={{ marginLeft: "5px" }}>
              {record.visitor.name
                ? `${record.visitor.name} - Copiar ID`
                : `#${record.visitor?.id?.slice(-6)} - Copiar ID`}
            </span>
          </li>

          {RT && (
            <>
              {/* <li onClick={initChat}>
                <MessageOutlined />
                <span style={{ marginLeft: "5px" }}>Iniciar chat</span>
              </li> */}

              <li onClick={() => openToogle("addLead")}>
                {record.visitor.name ? (
                  <>
                    <UserOutlined />
                    <span style={{ marginLeft: "5px" }}>Ver lead</span>
                  </>
                ) : (
                  <>
                    <UserAddOutlined />
                    <span style={{ marginLeft: "5px" }}>Añadir lead</span>
                  </>
                )}
              </li>
            </>
          )}
          <li onClick={() => openToogle("history")}>
            <HistoryOutlined />
            <span style={{ marginLeft: "5px" }}>Historial de navegación</span>
          </li>
        </ul>
      )}
    </>
  );
};

export default Popup;
