// react
import React, { useContext, useState } from "react";

// styles
import { Col, Form, Input, Row, Modal } from "antd";
import "./addUser.css";

// context
import { ProjectsContext } from "../../../../../contexts/projects/ProjectsContext";
import { UsersContext } from "../../../../../contexts/users/UsersContext";

// misc
import {
  infoNotification,
  errorNotification,
} from "../../../../Utils/Notification/Notification";

const ErrorMap = {
  '"email" must be a valid email, password must be at least 8 characters, password must be at least 8 characters':
    '"email" debe ser un correo electrónico válido, la contraseña debe tener al menos 8 caracteres, la contraseña debe tener al menos 8 caracteres',
};

const generate_random_color = () => {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
};

const AddUserForm = ({ visible, setVisible }) => {
  const { selectedProject } = useContext(ProjectsContext);
  const { addUser } = useContext(UsersContext);
  const [form] = Form.useForm();
  const [input, setInput] = useState({
    name: "",
    first_lastname: "",
    second_lastname: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    avatar_color: generate_random_color(),
  });
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    // const isFormEmpty = Object.values(input).every((x) => x.length);
    try {
      const data = {
        ...input,
        projects: [selectedProject.id],
      };
      setConfirmLoading(true);
      await form.validateFields();
      await addUser(data);
      setVisible(false);
      setConfirmLoading(false);
      form.resetFields();
      setInput({});
    } catch (error) {
      setConfirmLoading(false);
      if (error.errorFields) {
        let requiredFields = `Requerido: `;
        for (let i = 0; i < error.errorFields.length; i++) {
          requiredFields += `${error.errorFields[i]["name"][0]}, `;
          if (i === error.errorFields.length - 1) {
            requiredFields = requiredFields.slice(0, -2);
          }
        }
        infoNotification("Error", requiredFields);
      } else if (error.code >= 400 && error.code < 500) {
        infoNotification("Error", ErrorMap[error.message] || error.message);
      } else {
        errorNotification("ERROR 500", "Por favor comuniquese con nosotros.");
      }
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <Modal
      title="Crear un usuario"
      visible={visible}
      onOk={handleOk}
      okText="Crear usuario"
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      cancelText="Cancelar"
    >
      <Row type="flex" justify="center" align="middle">
        <Col>
          <Form
            form={form}
            labelCol={{ span: 12 }}
            onFinish={handleOk}
            onFinishFailed={() => {
              console.error("form failed");
            }}
          >
            <Form.Item
              label="Nombre"
              name="name"
              id="name"
              onChange={handleChange}
              rules={[{ required: true, message: "Es necesario!" }]}
              labelAlign="left"
            >
              <Input placeholder="Nombre" />
            </Form.Item>
            <Form.Item
              label="Primer apellido"
              name="first_lastname"
              id="first_lastname"
              onChange={handleChange}
              rules={[{ required: true, message: "Es necesario!" }]}
              labelAlign="left"
            >
              <Input
                placeholder="Primer apellido"
                value={input.first_lastname}
              />
            </Form.Item>
            <Form.Item
              label="Segundo apellido"
              name="second_lastname"
              id="second_lastname"
              onChange={handleChange}
              rules={[{ required: true, message: "Es necesario!" }]}
              labelAlign="left"
            >
              <Input
                placeholder="Segundo apellido"
                value={input.second_lastname}
              />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              id="email"
              onChange={handleChange}
              rules={[{ required: true, message: "Es necesario!" }]}
              labelAlign="left"
            >
              <Input placeholder="Email" value={input.email} />
            </Form.Item>
            <Form.Item
              label="Teléfono"
              name="phone"
              id="phone"
              onChange={handleChange}
              rules={[{ required: true, message: "Es necesario!" }]}
              labelAlign="left"
            >
              <Input placeholder="Telefono" value={input.phone} />
            </Form.Item>
            <Form.Item
              label="Contraseña"
              name="password"
              id="password"
              onChange={handleChange}
              rules={[{ required: true, message: "Es necesario!" }]}
              labelAlign="left"
            >
              <Input.Password placeholder="Contraseña" value={input.password} />
            </Form.Item>
            <Form.Item
              label="Confirma tu contraseña"
              name="confirmPassword"
              id="confirmPassword"
              onChange={handleChange}
              rules={[{ required: true, message: "Es necesario!" }]}
              labelAlign="left"
            >
              <Input.Password
                placeholder="Contraseña"
                value={input.confirmPassword}
              />
            </Form.Item>
            <Form.Item
              label="Color de perfil"
              name="avatar_color"
              id="avatar_color"
              onChange={handleChange}
              labelAlign="left"
            >
              <Input type="color" value={input.avatar_color} />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddUserForm;
