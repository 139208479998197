const _ = require("lodash");

export const visitsActions = {
  loadVisits: "loadVisits",
  addVisit: "addVisit",
  updateVisit: "updateVisit",
  updateItemsOfVisit: "updateItemsOfVisits",
  deleteVisit: "deleteVisit",
  cleanVisits: "cleanVisits",
  managerJoinsTheChat: "managerJoinsTheChat",
  managerLeavesTheChat: "managerLeavesTheChat",
};

const get_visit_id = (visit) => {
  return visit?.visit?.id || visit?.id || visit?.visit;
};

const get_payload_id = (action) => {
  const visit = action?.payload;
  return visit?.visit?.id || visit?.id || visit?.visit;
};

const sort_visit_by_created_date = (visits = []) => {
  return _.orderBy(visits, (v) => new Date() - new Date(v.createdAt), ["asc"]);
};

export const visitReducer = (state, action) => {
  switch (action.type) {
    case visitsActions.addVisit:
      return {
        visits: sort_visit_by_created_date([...state.visits, action.payload]),
        visitsCount: state.visitsCount + 1,
      };

    case visitsActions.updateVisit:
      // console.log("updateVisit");
      let visitUpdated = false;
      let updatedVisits = state.visits.map((visit) => {
        if (get_visit_id(visit) === get_payload_id(action)) {
          visitUpdated = true;
          return action.payload;
        }
        return visit;
      });
      if (!visitUpdated) {
        updatedVisits = [...state.visits, action.payload];
      }
      return {
        visits: sort_visit_by_created_date(updatedVisits),
        visitsCount: updatedVisits.length,
      };

    case visitsActions.deleteVisit:
      // console.log("deleteVisit: ", action);
      return {
        visits: sort_visit_by_created_date(
          state.visits.filter((visit) => {
            return get_visit_id(visit) !== get_payload_id(action);
          })
        ),
        visitsCount: state.visitsCount - 1,
      };

    case visitsActions.updateItemsOfVisit:
      return {
        visits: sort_visit_by_created_date(
          state.visits.map((visit) => {
            if (get_visit_id(visit) === get_payload_id(action)) {
              const visitor_updated = _.assign(visit, action.payload);
              return { ...visit, visitor_updated };
            }
            return visit;
          })
        ),
      };

    case visitsActions.managerJoinsTheChat:
      return {
        visits: state.visits.map((visit) => {
          if (get_visit_id(visit) === get_payload_id(action)) {
            return { ...visit, chatting: action.payload };
          }
          return visit;
        }),
      };

    case visitsActions.managerLeavesTheChat:
      return {
        visits: state.visits.map((visit) => {
          if (get_visit_id(visit) === get_payload_id(action)) {
            return { ...visit, chatting: undefined };
          }
          return visit;
        }),
      };

    case visitsActions.loadVisits:
      const sorted_visits = sort_visit_by_created_date([...action.payload]);
      return {
        visits: sorted_visits,
        visitsCount: action.payload.length,
      };

    case visitsActions.cleanVisits:
      return { ...state, visits: [] };

    default:
      throw new Error();
  }
};
