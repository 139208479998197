import React from "react";

import { AgencyLayout } from "../../components/Utils/Layout";

const AgencyDashboardPage = ({auth, logout}) => {
  return (
    <AgencyLayout content_title="Dashboard" auth={auth} logout={logout}>
      <p>Agency Dashboard content</p>
    </AgencyLayout>
  );
};

export default AgencyDashboardPage;
