import React, {
  useCallback,
  useContext,
  useMemo /* , useEffect  */,
  useEffect,
} from "react";
import { Button } from "antd";
import { DraggableModalProvider } from "ant-design-draggable-modal";
import "ant-design-draggable-modal/dist/index.css";
import { AuthContext } from "../../../contexts/auth/AuthContext";
import "./style.css";
import { ChatContext } from "../../../contexts/chat/ChatContext";

const ButtonsModalChat = ({
  visit,
  text,
  tableChatStarted,
  setTableChatStarted,
  setVisibleModalChat,
  setvisitDataModalChat,
}) => {
  const { closeChat, initChat, setChatStarted, getChatStarted } =
    useContext(ChatContext);
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    if (text) {
      setTableChatStarted(true);
    }
  }, [setTableChatStarted, text]);

  const chat_id = useMemo(
    () => `chat_id_${visit.visitor?.id}_${auth.user.id}`,
    [visit.visitor, auth.user.id]
  );

  const startChat = useCallback(() => {
    setVisibleModalChat(true);
    setvisitDataModalChat(visit);
    if (!getChatStarted(chat_id)) {
      initChat(visit);
      setChatStarted({ started_chat: true, chat_id });
      setTableChatStarted(true);
    }
  }, [chat_id, getChatStarted, initChat, setChatStarted, setTableChatStarted, setVisibleModalChat, setvisitDataModalChat, visit]);

  const finishChat = useCallback(() => {
    setVisibleModalChat(false);
    setvisitDataModalChat(null);
    if (getChatStarted(chat_id)) {
      closeChat(visit, chat_id);
      setChatStarted({ started_chat: false, chat_id });
      setTableChatStarted(false);
    }
  }, [setVisibleModalChat, setvisitDataModalChat, getChatStarted, chat_id, closeChat, visit, setChatStarted, setTableChatStarted]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
        onClick={startChat}
        type="primary"
        disabled={!text && tableChatStarted}
        id={`btn-charlar-${chat_id}`}
      >
        {getChatStarted(chat_id) || text ? "Charlando conmigo" : "Charla"}
      </Button>
      {getChatStarted(chat_id) ? (
        <Button
          onClick={finishChat}
          type="primary"
          style={{ margin: "0 5px", padding: "0 5px" }}
          danger
        >
          Finalizar Chat
        </Button>
      ) : (
        ""
      )}
    </div>
  );
};

const ChatButtonModal = ({
  visit,
  text,
  tableChatStarted,
  setTableChatStarted,
  setVisibleModalChat,
  setvisitDataModalChat,
}) => (
  <DraggableModalProvider>
    <ButtonsModalChat
      visit={visit}
      text={text}
      tableChatStarted={tableChatStarted}
      setTableChatStarted={setTableChatStarted}
      setVisibleModalChat={setVisibleModalChat}
      setvisitDataModalChat={setvisitDataModalChat}
    />
  </DraggableModalProvider>
);

export default ChatButtonModal;
