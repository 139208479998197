// react
import React from "react";

import AgenciesList from "./AgenciesList";
import useAgencies from "../../hooks/useAgencies";
import AddAgency from "./form/AddAgency";

export default function AgenciesSection() {
  const {
    agencies,
    agenciesPagination,
    setAgenciesPagination,
    fetchAgencies,
    loading,
    addAgency,
    updateAgency,
    deleteAgency,
  } = useAgencies();

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontSize: "24px", margin: " 0 0 10px 5px" }}>
          <strong>Gestión</strong> de Agencias
        </span>
        <AddAgency addAgency={addAgency} />
      </div>

      <AgenciesList
        agencies={agencies}
        fetchAgencies={fetchAgencies}
        loading={loading}
        updateAgency={updateAgency}
        deleteAgency={deleteAgency}
        agenciesPagination={agenciesPagination}
        setAgenciesPagination={setAgenciesPagination}
      />
    </>
  );
}
