import React, { useState, useCallback, useEffect } from "react";
import { Button } from "antd";

import UserForm from "./UserForm";

import useAgencies from "../../../hooks/useAgencies";
import useClients from "../../../hooks/useClients";
import useProjects from "../../../hooks/useProjects";

const UpdateUser = ({ updateUser, record }) => {
  const [visible, setVisible] = useState(false);

  const { agencies, fetchAgencies } = useAgencies();
  const { clients, fetchClients } = useClients();
  const { projects, fetchProjects } = useProjects();

  const loadAgenciesDataOnlyOnce = useCallback(
    (params) => {
    //   console.log("loadAgenciesDataOnlyOnce - params", params);
      fetchAgencies({ ...params, populate: "created_by_user" });
    },
    [fetchAgencies]
  );

  const loadClientsDataOnlyOnce = useCallback(
    (params) => {
      const agency = record?.agency?.id || record?.agency || null;
      if (agency) {
        params = { ...params, agency };
      }
    //   console.log("loadClientsDataOnlyOnce - params", params);
      fetchClients({ ...params, populate: "created_by_user" });
    },
    [fetchClients, record?.agency]
  );

  const loadProjectsDataOnlyOnce = useCallback(
    (params) => {
      const agency = record?.agency?.id || record?.agency || null;
      const clients = record?.clients?.length
        ? record.clients.map((client) => {
            return client?.id || client;
          })
        : [];
      if (agency && clients) {
        params = { ...params, agency, clients };
      }
    //   console.log("loadProjectsDataOnlyOnce - params", params);
      fetchProjects({ ...params, populate: "created_by_user" });
    },
    [fetchProjects, record?.agency, record?.clients]
  );

  useEffect(() => {
    if (visible) {
      let params = {
        limit: 99999,
      };
      loadAgenciesDataOnlyOnce(params);
    }
  }, [loadAgenciesDataOnlyOnce, record?.agency, visible]);

  useEffect(() => {
    if (visible && record?.clients?.length) {
      let params = {
        limit: 99999,
        agency: record?.agency,
      };
      loadClientsDataOnlyOnce(params);
    }
  }, [loadClientsDataOnlyOnce, record?.agency, record?.clients, visible]);

  useEffect(() => {
    if (visible && record?.agency && record?.clients?.length) {
      let params = {
        limit: 99999,
        agency: record?.agency?.id || record?.agency,
        clients: record?.clients,
      };
      loadProjectsDataOnlyOnce(params);
    }
  }, [loadProjectsDataOnlyOnce, record?.agency, record?.clients, visible]);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Editar
      </Button>

      <UserForm
        setVisible={setVisible}
        visible={visible}
        updateUser={updateUser}
        record={record}
        agencies={agencies}
        clients={clients}
        projects={projects}
      />
    </>
  );
};

export default UpdateUser;
