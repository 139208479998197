import React from "react";
import { Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function Loading({ fullHeight = true }) {
  const antIcon = <LoadingOutlined style={{ fontSize: 46 + "px" }} spin />;

  return (
    <Row type="flex" justify="center" align="middle" style={{ minHeight: fullHeight ? "100vh" : "100%" }}>
      <Spin className="loading" indicator={antIcon} />
    </Row>
  );
}

export default Loading;
