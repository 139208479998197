import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

import queryString from "query-string";

import Navbar from "../Navbar/Navbar";
import SideBar from "./Sidebar/SideBar";

import { Layout } from "antd";

import Project from "./Project";

import { AuthContext } from "../../contexts/auth/AuthContext";
import { ProjectsContext } from "../../contexts/projects/ProjectsContext";

import { leadcars } from "../../themes";

const ProjectPage = () => {
  const { setExternal, setExternalReady, externalReady, setTheme, auth } =
    useContext(AuthContext);
  const { selectedProject } = useContext(ProjectsContext);
  const { search } = useLocation();
  const queryUrlSearch = queryString.parse(search);
  const { Content } = Layout;

//   console.log("selectedProject", selectedProject);

  useEffect(() => {
    if (!externalReady && selectedProject) {
      const theme =
        queryUrlSearch?.theme ||
        selectedProject?.client?.theme ||
        selectedProject?.agency?.theme ||
        "default";
      setExternal(
        queryUrlSearch?.external === "true" || false
      );
      setTheme(theme);
      setExternalReady(true);
    }
    if (externalReady && auth?.theme !== "default") {
      if (auth?.theme !== "" && auth?.theme?.length) {
        const style = document.createElement("style");
        style.id = "custom-theme";
        // TODO: TEMA LEADCARS EN DURO
        style.textContent = leadcars;
        document.head.appendChild(style);
      }
    }
  }, [
    setExternal,
    setExternalReady,
    externalReady,
    setTheme,
    queryUrlSearch,
    selectedProject,
    auth?.theme,
  ]);

  useEffect(() => {
    return () => {
      if (
        externalReady &&
        auth?.theme !== "default" &&
        auth?.theme !== "" &&
        auth?.theme?.length
      ) {
        setTheme("default");
        const styleEl = document.getElementById("custom-theme");
        styleEl.remove();
        setExternalReady(false);
      }
    };
  }, [setTheme, externalReady, setExternalReady, auth?.theme]);

  return (
    <>
      <Navbar title="Webseller" />
      <Layout>
        <SideBar />
        <Content style={{ padding: "30px", background: "white" }}>
          <div className="site-layout-content" style={{ height: "100%" }}>
            <Project />
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default ProjectPage;
