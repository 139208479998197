import React, { memo, useMemo, useState } from "react";
import { Modal } from "antd";
import "./ShowModal.css";
import Proyectos from "./proyectos";

const ShowModal = memo(({ show, setPopup, record, popup }) => {
  const handleCancel = () => {
    setPopup((prev) => ({ ...prev, showModal: false }));
  };
  const [title, setTitle] = useState("");

  const renderSwitch = useMemo(() => {
    switch (popup.option) {
      case "proyectos":
        setTitle("Proyectos asociados al cliente");
        return <Proyectos record={record} />;
      default:
        return;
    }
    //eslint-disable-next-line
  }, [popup, record]);

  return (
    <>
      <Modal
        className="modal-rowpopup"
        visible={show}
        title={title}
        onCancel={handleCancel}
        cancelText="Cancelar"
        onOk={handleCancel}
        maskStyle={{
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1000,
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        }}
        destroyOnClose={true}
        maskClosable={false}
      >
        {renderSwitch}
      </Modal>
    </>
  );
});

export default ShowModal;
