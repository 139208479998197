import React, { useContext } from "react";
import { AuthContext } from "../../../../contexts/auth/AuthContext";
import { Tooltip, Table, Row, Divider, Button, Popconfirm, Tag } from "antd";

import { newDate } from "../../../../helpers/date";
import {
  sorterByDate,
  sorterByNumbers,
  sorterByLetters,
} from "../../../../helpers/sorter";
import AvatarProfile from "../../../Profile/AvatarProfile";

const TableLeads = ({
  title,
  leads,
  pagination,
  handleTableChange,
  deleteLead,
}) => {
  const { auth } = useContext(AuthContext);

  const columns = [
    {
      title: "Nombre",
      // key: "name",
      dataIndex: "name",
      render: (data) => (
        <Tooltip placement="top" title="name">
          <span>{data}</span>
        </Tooltip>
      ),
      sorter: (a, b) => sorterByLetters(a.name, b.name),
      showSorterTooltip: false,
    },
    {
      title: "Apellidos",
      // key: "first_lastname",
      dataIndex: "first_lastname",
      render: (text, record) => (
        <span>
          {text} {record.segundo_apellido}
        </span>
      ),
      sorter: (a, b) => sorterByLetters(a.first_lastname, b.first_lastname),
      showSorterTooltip: false,
      width: "10%",
    },

    {
      title: "Email",
      // key: "email",
      dataIndex: "email",
      with: "250px",
      render: (data) => (
        <Tooltip placement="top" title={data}>
          {data}
        </Tooltip>
      ),
      sorter: (a, b) => sorterByLetters(a.email, b.email),
      showSorterTooltip: false,
      width: "15%",
    },
    {
      title: "Teléfono",
      // key: "email",
      dataIndex: "phone",
      render: (data) => (
        <Tooltip placement="top" title={data}>
          {data}
        </Tooltip>
      ),
      sorter: (a, b) => sorterByLetters(a.phone, b.phone),
      showSorterTooltip: false,
    },
    {
      title: "Es cliente?",
      // key: "is_client",
      dataIndex: "is_client",
      render: (data) => (data ? "Si" : "No"),
      sorter: (a, b) => sorterByNumbers(a.is_client, b.is_client),
      showSorterTooltip: false,
    },
    // {
    //   title: "Tags",
    //   // key: "scoring",
    //   dataIndex: "tags",
    //   align: "right",
    //   // sorter: (a, b) => sorterByNumbers(a.tags, b.tags),
    //   showSorterTooltip: false,
    //   render: (data, record) => (
    //     <>
    //       {Object.values(record?.tags || {})?.map((t, i) => {
    //         if (i < 3) {
    //           return (
    //             <Tag color="green">
    //               {t.count || 1}/{t.referencia}
    //             </Tag>
    //           );
    //         }
    //         return <></>;
    //       })}
    //     </>
    //   ),
    // },
    {
      title: "Fecha lead",
      key: "lead_date",
      dataIndex: "lead_date",
      align: "right",
      width: "15%",
      render: (data) => (
        <Tooltip
          placement="topRight"
          title={`El usuario se convirtió en lead ${newDate(data)
            .setLocale("es-ES")
            .toFormat("dd' de 'LLLL' de 'yyyy ' a las ' T")}`}
        >
          <div style={{ textAlign: "end" }}>
            <span>{newDate(data).toFormat("dd'-'LL'-'yyyy T")}</span>
          </div>
        </Tooltip>
      ),
      sorter: (a, b) => sorterByDate(a.lead_date, b.lead_date),
      showSorterTooltip: false,
    },
    {
      title: "Gestor",
      dataIndex: "user_catcher",
      // align: "center",
      width: "80px",
      render: (data) => <AvatarProfile user={data} size={30} />,
      showSorterTooltip: false,
      sorter: (a, b) => {
        const user1 = `${a.user_catcher?.name} ${a.user_catcher?.first_lastname}`;
        const user2 = `${b.user_catcher?.name} ${b.user_catcher?.first_lastname}`;
        return sorterByLetters(user1, user2);
      },
    },
    {
      title: "Campaña",
      dataIndex: "campaign",
      align: "left",
      render: (data) => data?.name || "Sin campaña",
      showSorterTooltip: false,
      sorter: (a, b) => {
        const campaign1 = a.campaign?.name;
        const campaign2 = b.campaign?.name;
        return sorterByLetters(campaign1, campaign2);
      },
    },
  ];
  if (auth?.user?.user_type === "ADMIN") {
    columns.push({
      title: "Eliminar",
      key: "delete",
      dataIndex: "delete",
      // ellipsis: true,
      showSorterTooltip: true,
      align: "center",
      // sorter: true,
      render: (_, record) => (
        <Popconfirm
          title="¿Estás seguro?"
          okText="Si"
          cancelText="No"
          onConfirm={() => deleteLead(record?.id)}
        >
          <Button type="primary">Eliminar</Button>
        </Popconfirm>
      ),
      fixed: "right",
    });
  }

  return (
    <>
      <Row>
        {title}
        <Divider style={{ margin: "15px 0" }} />
      </Row>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={leads}
        pagination={pagination}
        onChange={handleTableChange}
        bordered={true}
        scroll={{ x: 1100 }}
      />
    </>
  );
};

export default TableLeads;
