// react
import React from "react";

// styles
import { Tooltip } from "antd";
// import {
//   CalendarOutlined,
//   DeleteOutlined,
//   EuroOutlined,
//   MailOutlined,
//   PhoneOutlined,
//   TrophyOutlined,
//   UserOutlined,
//   UserSwitchOutlined,
// } from "@ant-design/icons";


function TableHead({title, align}) {
  return (
    <Tooltip
    title= {title}
    style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
  >
    {/* <UserOutlined style={{ fontSize: "20px", marginRight: "5px" }} /> */}
    {title}
  </Tooltip>
  );
}

export default TableHead;
