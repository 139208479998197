// react
import React, { useContext, useEffect, useState, useCallback } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// routes
import PrivateRoute from "../router/PrivateRoute";
import PublicRoute from "../router/PublicRoute";
import AdminRouter from "../pages/admin/AdminRouter";
import AgencyRouter from "../pages/agency/AgencyRouter";
import ClientRouter from "../pages/client/ClientRouter";

// auth
import { AuthContext } from "../contexts/auth/AuthContext";
import LoginPage from "../components/Auth/Login/LoginPage";
import IntegrationPage from "../components/Auth/External/IntegrationPage";
// import RegisterPage from "../components/Auth/Register/RegisterPage";

// pages
import HomePage from "../components/Home/HomePage";
import ProjectsPage from "../pages/agent/Projects/ProjectsPage";
import NotFoundPage from "../components/Auth/NotFoundPage";
import ProjectPage from "../components/Project/ProjectPage";
import ProfilePage from "../components/Profile/ProfilePage";

// context
import { ProjectsProvider } from "../contexts/projects/ProjectsContext";
import { SocketProvider } from "../contexts/sockets/SocketContext";
import { AgencyProvider } from "../contexts/agency/AgencyContext";
import { VisitProvider } from "../contexts/visits/VisitsContext";

const GuidersRouter = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const loadDataOnlyOnce = useCallback(() => {
    isAuthenticated();
  }, [isAuthenticated]);

  useEffect(() => {
    loadDataOnlyOnce();
  }, [loadDataOnlyOnce]);

  return (
    <Router>
      <Switch>
        <PublicRoute exact path="/" component={HomePage} />
        <PublicRoute path="/login" component={LoginPage} />
        <PublicRoute path="/external-integration" component={IntegrationPage} />
        <Route path="/admin" component={AdminRouter} />
        <Route path="/agency" component={AgencyRouter} />
        <Route path="/client" component={ClientRouter} />
        <ProjectsProvider>
          <SocketProvider>
            <AgencyProvider>
              <VisitProvider>
                <Switch>
                  {/* <PrivateRoute path="/register" component={RegisterPage} /> */}
                  <PrivateRoute path="/profile" component={ProfilePage} />
                  <PrivateRoute
                    path="/projects/:slug"
                    component={ProjectPage}
                  />
                  <PrivateRoute path="/projects" component={ProjectsPage} />
                  <PrivateRoute path="*" component={NotFoundPage} />
                </Switch>
              </VisitProvider>
            </AgencyProvider>
          </SocketProvider>
        </ProjectsProvider>
        <PublicRoute path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default GuidersRouter;
