// react
import React, { useState } from "react";
import { Button } from "antd";

import TagTypeForm from "./TagTypeForm";

const AddTagType = ({ addTagType }) => {

  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Añadir Tipo Etiqueta
      </Button>

      <TagTypeForm setVisible={setVisible} visible={visible} addTagType={addTagType} />

    </>
  );
};

export default AddTagType;
