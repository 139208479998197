// react
import React, { useContext, useState } from "react";

// styles
import { Modal, Button, Divider } from "antd";
import { CodeOutlined, ToolFilled } from "@ant-design/icons";
import ColorPicker from "../../Utils/ColorPicker/ColorPicker";

// context
import { ProjectsContext } from "../../../contexts/projects/ProjectsContext";
import { AuthContext } from "../../../contexts/auth/AuthContext";

// misc
import { PIXEL_DOMAIN } from "../../../constants/apiContants";
import _ from "lodash";

const PixelProject = () => {
  const { selectedProject, resetPixelTokenProject } =
    useContext(ProjectsContext);
  const { auth } = useContext(AuthContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [primaryColor, setPrimaryColor] = useState("#e9564d");
  const [secundaryColor, setSecundaryColor] = useState("#abbf20");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleReloadToken = async () => {
    await resetPixelTokenProject(selectedProject?.id);
  };

  return (
    <>
      <Button onClick={showModal} type="text" block>
        <CodeOutlined style={{ fontSize: "18px" }} />
        <span style={{ fontSize: "17px" }}>Pixel</span>
      </Button>
      <Modal
        title="Pixel"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <code style={{ fontSize: "12px" }}>
          {`<script src="${PIXEL_DOMAIN}"></script>`}
          <br />
          {`<script>gp.init({ pixel_id: '${selectedProject?.pixel_id}', token: '${selectedProject?.pixel_token}', theme: {primary: '${primaryColor?.hex}', secondary: '${secundaryColor.hex}'} })</script>`}
        </code>
        {_.intersection(
          ["admin", "client_admin", "agency_admin"],
          [auth.user.role]
        ).length ? (
          <div>
            <Button onClick={handleReloadToken} type="text" block>
              <ToolFilled style={{ fontSize: "18px" }} />
              <span style={{ fontSize: "17px" }}>Regen pixel token</span>
            </Button>
          </div>
        ) : null}

        <div>
          Color Primario:{" "}
          <ColorPicker color={primaryColor} setColor={setPrimaryColor} />
        </div>
        <div>
          Color Segundario:{" "}
          <ColorPicker color={secundaryColor} setColor={setSecundaryColor} />
        </div>
      </Modal>
      <Divider style={{ margin: "10px 0px" }} />
    </>
  );
};

export default PixelProject;
