import _ from "lodash";

export const chatActions = {
  addMessage: "addMessage",
  activeChat: "activeChat",
  inactiveChat: "inactiveChat",
  setMessages: "setMessages",
  setChatStarted: "setChatStarted",
};

export const chatReducer = (state, action) => {
  const chatExists = _.some(state.chats, ["chat_id", action.data.chat_id]);

  switch (action.type) {
    case chatActions.activeChat:
      return {
        activeChats: [
          { ...state.activeChats },
          {
            chat_id: action.data.chat_id,
            manager: action.data.manager,
            visitor: action.data.visitor,
          },
        ],
        chats: [...state.chats],
      };

    case chatActions.inactiveChat:
      return {
        activeChats: _.filter(
          state.activeChats,
          (chat) => chat.chat_id !== action.data.chat_id
        ),
        chats: [...state.chats],
      };

    case chatActions.addMessage:
      if (chatExists) {
        const chats = state.chats.map((chat) => {
          return chat.chat_id !== action.data.chat_id
            ? chat
            : Object.assign({}, chat, {
                messages: [...chat.messages, action.data],
              });
        });

        return { ...state.activeChats, chats };
      } else {
        const chats = [
          ...state.chats,
          { chat_id: action.data.chat_id, messages: [action.data] },
        ];
        return { activeChats: [...state.activeChats], chats };
      }

    case chatActions.setMessages:
      if (chatExists) {
        const chats = state.chats.map((chat) => {
          return chat.chat_id !== action.data.chat_id
            ? chat
            : Object.assign({}, chat, { messages: action.data.messages });
        });

        return { ...state.activeChats, chats };
      } else {
        const chats = [
          ...state.chats,
          { chat_id: action.data.chat_id, messages: action.data.messages },
        ];
        return { activeChats: [...state.activeChats], chats };
      }

    case chatActions.setChatStarted:
      if (chatExists) {
        const chats = state.chats.map((chat) => {
          return chat.chat_id !== action.data.chat_id
            ? chat
            : Object.assign({}, chat, {
                started_chat: action.data.started_chat,
              });
        });

        return { chats };
      } else {
        const chats = [
          ...state.chats,
          {
            chat_id: action.data.chat_id,
            messages: [],
            started_chat: action.data.started_chat,
          },
        ];
        return { chats };
      }

    default:
      return state;
  }
};
