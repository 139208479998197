// react
import React, { useContext, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

// styles
import { Layout, Menu } from "antd";
import "./Sidebar.css";

import {
  BellOutlined,
  ClockCircleOutlined,
  FileSearchOutlined,
  LineChartOutlined,
  // FunnelPlotOutlined,
  MailOutlined,
  PartitionOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

import _ from "lodash";
import { AuthContext } from "../../../contexts/auth/AuthContext";

const SideBar = () => {
  const { auth } = useContext(AuthContext);
  const { Sider } = Layout;
  const { SubMenu } = Menu;
  const location = useLocation();
  const { slug } = useParams();

  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = (collapsed) => setCollapsed(collapsed);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      theme="light"
      style={{
        marginTop: "-1px",
        minHeight: "91.3vh",
        maxWidth: "auto",
      }}
    >
      <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
        style={{
          height: "100%",
          backgroundColor: "#fafafa",
        }}
      >
        <Menu.Item key={`/projects/${slug}`}>
          <LineChartOutlined style={{ fontSize: "18px" }} />
          <span>Dashboard</span>
          <Link to={`/projects/${slug}`} />
        </Menu.Item>
        <Menu.Item key={`/projects/${slug}/rt`}>
          <ClockCircleOutlined style={{ fontSize: "18px" }} />
          <span>¿Qué está pasando?</span>
          <Link to={`/projects/${slug}/rt`} />
        </Menu.Item>

        <Menu.Item key={`/projects/${slug}/visits`}>
          <UserOutlined style={{ fontSize: "18px" }} />
          <span>Visitas obtenidas</span>
          <Link to={`/projects/${slug}/visits`} />
        </Menu.Item>

        <Menu.Item key={`/projects/${slug}/leads`}>
          <MailOutlined style={{ fontSize: "18px" }} />
          <span>Leads captados</span>
          <Link to={`/projects/${slug}/leads`} />
        </Menu.Item>

        {_.intersection(
          ["admin", "client_admin", "agency_admin"],
          [auth.user.role]
        ).length && (
          <>
            <Menu.Item key={`/projects/${slug}/sellers`}>
              <UserSwitchOutlined style={{ fontSize: "18px" }} />
              <span>Actividad gestores</span>
              <Link to={`/projects/${slug}/sellers`} />
            </Menu.Item>

            {/* <Menu.Item key="4">
              <FunnelPlotOutlined style={{ fontSize: "18px" }} />
              <span>Funnels conversión</span>
            </Menu.Item> */}

            <SubMenu
              key="settings"
              icon={<SettingOutlined style={{ fontSize: "18px" }} />}
              title="Configuración"
            >
              <Menu.Item key={`/projects/${slug}/tagstype`}>
                <PartitionOutlined style={{ fontSize: "18px" }} />
                <span>Tipos Tag</span>
                <Link to={`/projects/${slug}/tagstype`} />
              </Menu.Item>

              <Menu.Item key={`/projects/${slug}/tags`}>
                <PartitionOutlined style={{ fontSize: "18px" }} />
                <span>Tags</span>
                <Link to={`/projects/${slug}/tags`} />
              </Menu.Item>

              <Menu.Item key={`/projects/${slug}/channels`}>
                <PartitionOutlined style={{ fontSize: "18px" }} />
                <span>Canales</span>
                <Link to={`/projects/${slug}/channels`} />
              </Menu.Item>

              <Menu.Item key={`/projects/${slug}/campaigns`}>
                <BellOutlined style={{ fontSize: "18px" }} />
                <span>Campañas</span>
                <Link to={`/projects/${slug}/campaigns`} />
              </Menu.Item>

              <Menu.Item key={`/projects/${slug}/pages`}>
                <FileSearchOutlined style={{ fontSize: "18px" }} />
                <span>Páginas</span>
                <Link to={`/projects/${slug}/pages`} />
              </Menu.Item>

              <Menu.Item key={`/projects/${slug}/users`}>
                <UsergroupAddOutlined style={{ fontSize: "18px" }} />
                <span>Usuarios gestores</span>
                <Link to={`/projects/${slug}/users`} />
              </Menu.Item>
            </SubMenu>
          </>
        )}
      </Menu>
    </Sider>
  );
};

export default SideBar;
