import React, { useContext } from "react";
import { Switch, useRouteMatch, Redirect } from "react-router-dom";
import ClientDashboardPage from "./ClientDashboardPage";
import ClientPrivateRoute from "../../router/client/ClientPrivateRoute";

// import TokensPage from "./Tokens/Tokens";
// import ApiKeysPage from "./ApiKeys/ApiKeysPage";
import { AuthContext } from "../../contexts/auth/AuthContext";

// misc
import Loading from "../../components/Utils/Loading/Loading";

import ProjectPage from "./Projects/ProjectPage";
import UserPage from "./Users/UserPage";

const AgencyRouter = () => {
  const { auth, logout } = useContext(AuthContext);
  const { url } = useRouteMatch();
  const role = auth?.user?.role || "client_agent";

  if (auth.logged === null) {
    return <Loading />;
  }
  if (!auth?.is_admin && role !== "client_admin") {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <Switch>
      <ClientPrivateRoute path={`${url}/projects`}>
        <ProjectPage auth={auth} logout={logout} />
      </ClientPrivateRoute>
      <ClientPrivateRoute path={`${url}/users`}>
        <UserPage auth={auth} logout={logout} />
      </ClientPrivateRoute>
      <ClientPrivateRoute path={`${url}`}>
        <ClientDashboardPage auth={auth} logout={logout} />
      </ClientPrivateRoute>
    </Switch>
  );
};

export default AgencyRouter;
