import React from "react";
import AddUsers from "./AddUser/AddUsers";
import UsersList from "./UsersList";

const Users = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
      <span style={{ fontSize: "24px", margin: " 0 0 10px 5px" }}>
        <strong>Gestión</strong> de usuarios
      </span>
        <AddUsers />
      </div>
      <UsersList />
    </>
  );
};

export default Users;
