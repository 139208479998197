import React, {
  useState,
  useCallback,
  useContext,
  useMemo /* , useEffect  */,
  useEffect,
} from "react";
import { Button, Tabs } from "antd";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import "ant-design-draggable-modal/dist/index.css";
import ChatBody from "./ChatBody";
import ChatFooter from "./ChatFooter";
import ChatInfoVisit from "./ChatInfoVisit";
import { AuthContext } from "../../../contexts/auth/AuthContext";
import "./style.css";
import AddLead from "../../Utils/Table/RowPopup/AddLead";
import ChatBodyHistory from "./ChatBodyHistory";
import { ChatContext } from "../../../contexts/chat/ChatContext";

const ModalChat = ({ visit, visibleChat, setVisibleModalChat, setTableChatStarted }) => {
  const { TabPane } = Tabs;
  const { closeChat, setChatStarted, getChatStarted } = useContext(ChatContext);
  const { auth } = useContext(AuthContext);
  const [currentTab, setCurrentTab] = useState("1");

  useEffect(() => {
  setVisibleModalChat(visibleChat);
  }, [visibleChat, setVisibleModalChat]);

  const chat_id = useMemo(
    () => `chat_id_${visit.visitor?.id}_${auth.user.id}`,
    [visit.visitor, auth.user.id]
  );

  const onOk = useCallback(() => {
    setVisibleModalChat(true);
    if (visibleChat) {
      setTableChatStarted(true);
    }
  }, [setTableChatStarted, setVisibleModalChat, visibleChat]);

  const finishChat = useCallback(() => {
    closeChat(visit, chat_id);
    setChatStarted({started_chat: false, chat_id});
    setTableChatStarted(false);
    setVisibleModalChat(false);
  }, [closeChat, visit, chat_id, setChatStarted, setTableChatStarted, setVisibleModalChat]);

  const onCancel = useCallback(() => {
    setVisibleModalChat(false);
  }, [setVisibleModalChat]);

  const changeTab = (activeKey) => {
    setCurrentTab(activeKey);
  };

  return (
    <DraggableModal
      zIndex={10}
      visible={visibleChat}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button key="finish" onClick={finishChat} danger type="primary">
          Finalizar Chat
        </Button>,
        <Button key="minimize" onClick={onCancel} type="primary">
          Minimizar Chat
        </Button>,
      ]}
      title={
        <>
          Charlando con{" "}
          <b>
            {visit.visitor?.name
              ? visit.visitor.name
              : `#${visit.visitor?.id?.slice(-6)}`}
          </b>
        </>
      }
      initialWidth={900}
      initialHeight={600}
      destroyOnClose={true}
      mask={true}
      className="modal-chat"
    >
      <div id="pixel-chat-full-body">
        <div id="pixel-chat-left-side">
          <Tabs
            defaultActiveKey="1"
            type="card"
            size="small"
            activeKey={currentTab}
            onChange={changeTab}
          >
            <TabPane tab="Ahora" key="1">
              <ChatBody chat_id={chat_id} />
              <ChatFooter
                chat_id={chat_id}
                visit={visit}
                setChatStarted={setChatStarted}
                startedChat={getChatStarted(chat_id)}
              />
            </TabPane>
            <TabPane tab="Historial" key="2">
              <ChatBodyHistory chat_id={chat_id} />
            </TabPane>
            <TabPane
              tab={visit.visitor?.name ? "Ver lead" : "Añadir lead"}
              key="3"
            >
              <AddLead record={visit} setCurrentTab={setCurrentTab} />
            </TabPane>
          </Tabs>
        </div>
        <div id="pixel-chat-right-side">
          <ChatInfoVisit visit={visit} />
        </div>
      </div>
    </DraggableModal>
  );
};

const DraggableModalChat = ({
  visit,
  visibleChat,
  setVisibleModalChat,
  setTableChatStarted,
}) => (
  <DraggableModalProvider>
    <ModalChat
      visit={visit}
      visibleChat={visibleChat}
      setVisibleModalChat={setVisibleModalChat}
      setTableChatStarted={setTableChatStarted}
    />
  </DraggableModalProvider>
);

export default DraggableModalChat;
