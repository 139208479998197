// react
import React from "react";

import ProjectsList from "./ProjectsList";
import useProjects from "../../hooks/useProjects";
import AddProject from "./form/AddProject";

export default function ProjectsSection() {
  const {
    projects,
    fetchProjects,
    loading,
    addProject,
    updateProject,
    deleteProject,
    paginationProjects,
  } = useProjects();

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontSize: "24px", margin: " 0 0 10px 5px" }}>
          <strong>Gestión</strong> de proyectos
        </span>
        <AddProject addProject={addProject} />
      </div>

      <ProjectsList
        projects={projects}
        fetchProjects={fetchProjects}
        loading={loading}
        updateProject={updateProject}
        deleteProject={deleteProject}
        paginationProjects={paginationProjects}
      />
    </>
  );
}
