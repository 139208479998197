// react
import { useCallback, useContext, useState } from "react";

// auth
import { AuthContext } from "../contexts/auth/AuthContext";

// misc
import { errorNotification } from "../components/Utils/Notification/Notification";
import { fetchDataToken } from "../helpers/fetch";

const useProjects = () => {
  const [projects, setProjects] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  const fetchProjects = useCallback(
    async (data) => {
      try {
        const response = await fetchDataToken("/project", data);
        // console.log("response", response);
        if (response.status === 200) {
          setProjects(response.data.results);
        }
      } catch (error) {
        if (error.response) {
          errorNotification("Error", error.response.data.msg);
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error.message);
        }
      }
    },
    [setProjects]
  );

  const addProject = useCallback(
    async (data, params = {}) => {
      const isProjectExists = projects.find(
        (project) => project.name === data.name
      );
      if (isProjectExists) {
        errorNotification("Error", "El proyecto ya existe");
        return false;
      }

      const response = await fetchDataToken("/project", data, "POST", params);
    //   console.log("addProject - response", response);
      if (response.status === 201) {
        setProjects((prevProjects) => [...prevProjects, response.data]);
      }
    },
    [projects]
  );

  const updateProject = useCallback(async (id, data, params = {}) => {
    try {
      const response = await fetchDataToken(
        `/project/${id}`,
        data,
        "PUT",
        params
      );

    //   console.log("updateProject - response: ", response);

      if (response.status === 200) {
        setProjects((prev) => {
          return prev.map((project) => {
            if (project?.id === response?.data?.id) {
              return response.data;
            }
            return project;
          });
        });
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, []);

  const deleteProject = useCallback(async (id) => {
    try {
      const response = await fetchDataToken(`/project/${id}`, {}, "DELETE");
      if (response.status === 204) {
        setTimeout(() => {
          setProjects((prevProjects) => {
            return prevProjects.filter((project) => project.id !== id);
          });
        }, 200);
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, []);

  const selectProject = (data) => {
    if (projects && data !== selectedProject?.name) {
      setSelectedProject(
        ...projects.filter((project) => project.name === data)
      );
    }
  };

  const deselectProject = () => {
    if (selectedProject !== null) {
      setSelectedProject(null);
    }
  };

  const resetPixelTokenProject = useCallback(async (id) => {
    try {
      const response = await fetchDataToken(
        `/project/${id}/regen-pixel-token`,
        {},
        "GET"
      );

    //   console.log("resetPixelTokenProject - response: ", response);

      if (response.status === 200) {
        setProjects((prev) => {
          return prev.map((project) => {
            if (project?.id === id) {
            //   console.log('project', project)
              return { ...project, pixel_token: response?.data };
            }
            return project;
          });
        });
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, []);

  return {
    projects,
    addProject,
    deleteProject,
    selectProject,
    deselectProject,
    selectedProject,
    fetchProjects,
    updateProject,
    resetPixelTokenProject,
  };
};

export default useProjects;
