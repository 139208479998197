import React, { useState, createContext, useCallback } from "react";
import { fetchData, fetchDataToken } from "../../helpers/fetch";
import {
  ACCESS_TOKEN_NAME,
  CURRENT_USER_ID,
} from "../../constants/apiContants";

import { refreshAccessToken } from "../../helpers/refresh-token-fetch";

export const AuthContext = createContext();

const initialState = {
  checking: true,
  logged: null,
  user: {},
  isExternal: false,
  theme: "default",
  is_admin: null,
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(initialState);
  const [externalReady, setExternalReady] = useState(false);

  const login = async (data) => {
    const response = await fetchData("/auth/login", data, "POST");

    if (response?.status === 200) {
      const tokens = response?.data?.tokens || {};
      localStorage.setItem(ACCESS_TOKEN_NAME, JSON.stringify(tokens));
      const user = response?.data?.user;
      localStorage.setItem(CURRENT_USER_ID, JSON.stringify(user?.id));
      setAuth({
        checking: false,
        logged: true,
        user,
        is_admin: user?.role === "admin" || false,
      });
      return response;
    }
    return response;
  };

  const register = async (data) => {
    const response = await fetchDataToken("/auth/register", data, "POST");
    if (response.status === 201) {
      const tokens = response?.data?.tokens || {};
      localStorage.setItem(ACCESS_TOKEN_NAME, JSON.stringify(tokens));
      const user = response?.data?.user;
      localStorage.setItem(CURRENT_USER_ID, JSON.stringify(user?.id));
      setAuth({
        checking: false,
        logged: true,
        user,
        is_admin: user?.role === "admin" || false,
      });
      return response;
    }
    return response;
  };

  const logout = () => {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem(CURRENT_USER_ID);
    setAuth({
      checking: false,
      logged: false,
    });
  };

  const setExternal = (isExternal) => {
    setAuth((prevState) => ({
      ...prevState,
      isExternal: isExternal || false,
    }));
  };

  const setTheme = (theme = "default") => {
    setAuth((prevState) => ({
      ...prevState,
      theme: theme,
    }));
  };

  const integrationLogin = async (data) => {
    const response = await fetchData(
      "/auth/external-integration",
      data,
      "POST"
    );
    if (response.status === 200) {
      const tokens = response?.data?.tokens || {};
      localStorage.setItem(ACCESS_TOKEN_NAME, JSON.stringify(tokens));
      localStorage.setItem(
        CURRENT_USER_ID,
        JSON.stringify(response?.data?.user?.id)
      );
      setAuth({
        checking: false,
        logged: true,
        user: response?.data?.user,
        is_admin: response?.data?.user?.role === "admin" || false,
      });
      return response;
    }
    return response;
  };

  const isAuthenticated = useCallback(async () => {
    try {
      let tokens = localStorage.getItem(ACCESS_TOKEN_NAME) || "{}";
      const access_token = JSON.parse(tokens || "{}")?.access?.token || null;

      if (!tokens || !access_token) {
        setAuth({
          checking: false,
          logged: false,
        });
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem(CURRENT_USER_ID);
        return false;
      }

      const response = await refreshAccessToken(true);

      if (response?.status === 200) {
        tokens = response?.data || {};
        localStorage.setItem(ACCESS_TOKEN_NAME, JSON.stringify(tokens));
        const user_id = JSON.parse(localStorage.getItem(CURRENT_USER_ID));
        const response_user = await fetchDataToken(
          `/user/${user_id}`,
          {},
          "GET"
        );
        const user = response_user?.data;
        setAuth((prevState) => ({
          ...prevState,
          checking: false,
          logged: true,
          user,
          is_admin: user?.role === "admin" || false,
        }));
        return true;
      } else {
        setAuth({
          checking: false,
          logged: false,
        });
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem(CURRENT_USER_ID);
        return false;
      }
    } catch (error) {
      if (error.response) {
        console.error(error.response.data);
        if (error.response.status === 401) {
          localStorage.removeItem(ACCESS_TOKEN_NAME);
          localStorage.removeItem(CURRENT_USER_ID);
        }
        setAuth({
          checking: false,
          logged: false,
        });
      } else if (error.request) {
        console.error(error.request);
        setAuth({
          checking: false,
          logged: false,
        });
        return false;
      } else {
        console.error("Error", error.message);
        setAuth({
          checking: false,
          logged: false,
        });
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem(CURRENT_USER_ID);
        return false;
      }
      console.error(error);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        register,
        logout,
        integrationLogin,
        isAuthenticated,
        setAuth,
        setExternal,
        externalReady,
        setExternalReady,
        setTheme,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
