// react
import React, { useState } from "react";
import { Link } from "react-router-dom";

// styles
import { Button, Drawer, PageHeader } from "antd";
import {
  PoweroffOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";

// styles
import "./Navbar.css";

const Navbar = ({ title, auth, logout }) => {
  const [visible, setVisible] = useState(false);

  const handleLogout = async () => {
    await logout();
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        ghost={false}
        title={
          <Link to="/agency" style={{ color: "black" }}>
            {title}
          </Link>
        }
        style={{
          borderBottom: "1px solid #e4e4e4",
        }}
        extra={[
          auth?.logged && auth?.user?.email,
          <Button
            key="key_btn_options"
            onClick={showDrawer}
            type="text"
            style={{ height: "100%" }}
          >
            <SettingOutlined style={{ fontSize: "28px" }} />
          </Button>,
          <Drawer
            key="key_drawer"
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ margin: "0px 0px 0px 10px", fontSize: "18px" }}>
                  Perfil
                </span>
              </div>
            }
            onClose={onClose}
            visible={visible}
            placement="right"
            maskStyle={{ opacity: "0", backgroundColor: "rgba(0,0,0,0)" }}
          >
            <Link key="key_btn_projects" to="/projects">
              <Button type="text" block>
                <UserOutlined style={{ fontSize: "18px" }} />
                <span style={{ fontSize: "17px" }}>Proyectos</span>
              </Button>
            </Link>

            <Button
              key="key_btn_logout"
              onClick={handleLogout}
              type="text"
              block
            >
              <PoweroffOutlined style={{ fontSize: "18px" }} />
              <span style={{ fontSize: "17px" }}>Salir</span>
            </Button>
          </Drawer>,
        ]}
      />
    </div>
  );
};

export default Navbar;
