import React from "react";

import { AdminLayout } from "../../components/Utils/Layout";


const AdminDashboardPage = ({auth, logout}) => {
  return (
    <AdminLayout content_title="Dashboard" auth={auth} logout={logout}>
      <p>Admin Dashboard content</p>
    </AdminLayout>
  );
};

export default AdminDashboardPage;
