import axios from "axios";
import {
  ACCESS_TOKEN_NAME,
  API_BASE_URL,
  CURRENT_USER_ID,
} from "../constants/apiContants";
import _ from "lodash";
import { ErrorResponse } from "./errorResponse";

export const refreshAccessToken = async (full_response = false) => {
  const url = `${API_BASE_URL}${"/auth/refresh-tokens"}`;
  try {
    const tokens = localStorage.getItem(ACCESS_TOKEN_NAME);
    const access_token = JSON.parse(tokens || "{}")?.access?.token || null;
    if (!tokens || !access_token) {
      throw ErrorResponse("ERROR", 401, "REFRESH_TOKEN_INVALID");
    }

    // const dateToValidRefresh = JSON.parse(tokens)?.refresh?.expires || null;
    // const dateToValidAccess = JSON.parse(tokens)?.access?.expires || null;

    // if (isValidExpires(dateToValidAccess)) {
    //   return full_response
    //     ? { data: JSON.parse(tokens), status: 200 }
    //     : JSON.parse(tokens)?.access?.token;
    // }

    // if (!isValidExpires(dateToValidRefresh)) {
    //   localStorage.removeItem(ACCESS_TOKEN_NAME);
    //   localStorage.removeItem(CURRENT_USER_ID);
    // }

    const response = await axios.post(url, {
      refreshToken: `Bearer ${access_token}`,
    });

    if (response?.status === 200) {
      localStorage.setItem(
        ACCESS_TOKEN_NAME,
        JSON.stringify(response?.data || "{}")
      );
    } else {
      localStorage.removeItem(ACCESS_TOKEN_NAME);
      localStorage.removeItem(CURRENT_USER_ID);
      throw ErrorResponse("ERROR", response.status, "REFRESH_TOKEN_INVALID");
    }

    return full_response ? response : _.get(response, "data.access.token");
  } catch (error) {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem(CURRENT_USER_ID);
    throw ErrorResponse(
      error,
      error.response?.data?.code,
      error.response?.data?.message
    );
  }
};

const isValidExpires = (dateToValid = null) => {
  let valid = false;
  try {
    const now = Date.now();
    const dateToValidate = Number(new Date(dateToValid || "").getTime());
    return dateToValidate > now;
  } catch (error) {
    return valid;
  }
};
