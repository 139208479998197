// react
import React from "react";

// styles
import { ClockCircleOutlined, TrophyOutlined } from "@ant-design/icons";

// timer
import SessionTimer from "../../Utils/Timer/SessionTimer"
import Timer from "../../Utils/Timer/Timer"

const InfoRow = ({ icon, text, value }) => {
  return (
    <li>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          {icon}
          <span style={{ marginLeft: "5px" }}>{text}</span>
        </div>
        <b>{value}</b>
      </div>
    </li>
  );
};

const ChatInfoVisit = ({ visit }) => {
  return (
    <div className="pixel-chat-info-visits">
      <div className="pixel-chat-info-list">
        <ul>
          <InfoRow
            icon={<ClockCircleOutlined />}
            text="Tiempo en la web"
            
            value={<SessionTimer lastConnection={visit.last_connection_date} time_session={visit.time_session} />}
          />
          <InfoRow icon={<ClockCircleOutlined />} text="Tiempo conversación" value={<Timer />} />
          <InfoRow icon={<TrophyOutlined />} text="Scoring ahora" value={visit.scoring} />
          {/* <InfoRow icon={<FileSearchOutlined />} text="Páginas vistas" value={visit?.historial_navegacion?.length} /> */}
        </ul>
      </div>
    </div>
  );
};

export default ChatInfoVisit;
