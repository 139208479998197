// react
import { useCallback, useContext, useState } from "react";

// misc
import { errorNotification } from "../components/Utils/Notification/Notification"
import { ProjectsContext } from "../contexts/projects/ProjectsContext";
import { fetchDataToken } from "../helpers/fetch";

const useChannels = () => {
  
  const { selectedProject } = useContext(ProjectsContext);
  const [channels, setChannels] = useState([]);

  const fetchChannels = useCallback(async () => {
    try {
      if (selectedProject?.id) {
        const response = await fetchDataToken("/channel", { project: selectedProject?.id }, "GET");
        if (response.status === 200) {
          setChannels(response.data.results);
        }
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, [selectedProject?.id]);

  const addChannel = useCallback(
    async (data) => {
      try {
        if (selectedProject?.id) {
          const response = await fetchDataToken(
            `/channel/`,
            { ...data, project: selectedProject?.id },
            "POST"
          );
          if (response.status === 201) {
            setChannels((prev) => [...prev, response.data]);
          }
        }
      } catch (error) {
        if (error.response) {
          errorNotification("Error", error.response.data.msg);
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error.message);
        }
      }
    },
    [selectedProject?.id]
  );

  const updateChannel = useCallback(
    async (id, data) => {
      try {
        if (selectedProject?.id) {
          const response = await fetchDataToken(
            `/channel/${id}`,
            { project: selectedProject?.id, ...data },
            "PUT"
          );
          if (response.status === 200) {
            setChannels((prev) =>
              prev.map((c) => {
                if (c.id === id) {
                  return response.data;
                }
                return c;
              })
            );
          }
        }
      } catch (error) {
        if (error.response) {
          errorNotification("Error", error.response.data.msg);
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error.message);
        }
      }
    },
    [selectedProject?.id]
  );

  const deleteChannel = useCallback(async (id) => {
    try {
      const response = await fetchDataToken(`/channel/${id}`, {}, "DELETE");
      if (response.status === 204) {
        setChannels((prev) => prev.filter((c) => c.id !== id));
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, []);

  return { channels, fetchChannels, deleteChannel, addChannel, updateChannel };
};

export default useChannels;
