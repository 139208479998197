// react
import React, { useContext, useState } from "react";

// styles
import { Button, Col, Form, Input, Row } from "antd";

// misc
import {
  errorNotification,
  infoNotification,
  successNotification,
} from "../../Notification/Notification";
import { fetchDataToken } from "../../../../helpers/fetch";
import { VisitContext } from "../../../../contexts/visits/VisitsContext";
import { visitsActions } from "../../../../contexts/visits/visitReducer";
import useVisitants from "../../../../hooks/useVisitants";

// auth
import { AuthContext } from "../../../../contexts/auth/AuthContext";

const AddLead = ({ record, setCurrentTab = {}, handleCancel }) => {
  const { auth } = useContext(AuthContext);
  const { dispatch } = useContext(VisitContext);
  const [form] = Form.useForm();
  const { fetchTodaysLeadCount } = useVisitants();

  const [input, setInput] = useState({
    name: record.visitor.name,
    first_lastname: record.visitor.first_lastname,
    second_lastname: record.visitor.second_lastname,
    email: record.visitor.email,
    phone: record.visitor.phone,
  });
  const handleChange = (e) => {
    const { id, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmitClick = async (e) => {
    e.preventDefault();
    if (
      input.name.length &&
      input.first_lastname.length &&
      input.email.length
    ) {
      const data = {
        ...input,
        visitor: record.visitor?.id || record.visitor,
        visit: record?.id,
      };
      try {
        // console.log('RECORDS', record)
        const response = await fetchDataToken("/visitor", data, "PUT");
        if (response.status === 200) {
        //   console.log('response.data', response.data);
          successNotification("El lead se ha añadido!");
          dispatch({
            type: visitsActions.updateItemsOfVisit,
            payload: {
              id: data.visit?.id || data.visit,
              name: data.name,
              first_lastname: data.first_lastname,
              second_lastname: data.second_lastname,
              email: data.email,
              phone: data.phone,
              visitor: response.data.visit?.visitor || response.data.visitor || data.visitor,
              user_catcher: response.data.visit?.visitor?.user_catcher,
            },
          });

          if (typeof setCurrentTab === "function") {
            setCurrentTab("1");
          } else {
            handleCancel();
          }
          fetchTodaysLeadCount();
        }
      } catch (error) {
        if (error.response) {
          errorNotification("Error", error.response.data.msg);
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error.message);
        }
      }
    } else {
      infoNotification("Atención", "Alguno de los datos deben ser rellenado");
    }
  };

  return (
    <Row type="flex" justify="center" align="middle">
      <Col>
        <Form form={form} labelCol={{ span: 10 }}>
          <Form.Item
            label="Nombre"
            name="name"
            id="name"
            onChange={handleChange}
            labelAlign="left"
            rules={[
              {
                required: true,
                message: "El name es necesario",
              },
            ]}
          >
            <Input
              placeholder="Nombre"
              value={input.name}
              defaultValue={input.name}
            />
          </Form.Item>
          <Form.Item
            label="Primer apellido"
            name="first_lastname"
            id="first_lastname"
            onChange={handleChange}
            labelAlign="left"
            rules={[
              {
                required: true,
                message: "El apellido es necesario",
              },
            ]}
          >
            <Input
              placeholder="Primer apellido"
              value={input.first_lastname}
              defaultValue={input.first_lastname}
            />
          </Form.Item>
          <Form.Item
            label="Segundo apellido"
            name="second_lastname"
            id="second_lastname"
            onChange={handleChange}
            labelAlign="left"
          >
            <Input
              placeholder="Segundo apellido"
              value={input.second_lastname}
              defaultValue={input.second_lastname}
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            id="email"
            onChange={handleChange}
            labelAlign="left"
            rules={[
              {
                required: true,
                message: "El email es necesario",
              },
            ]}
          >
            <Input
              placeholder="Email"
              value={input.email}
              defaultValue={input.email}
            />
          </Form.Item>
          <Form.Item
            label="Teléfono"
            name="phone"
            id="phone"
            onChange={handleChange}
            labelAlign="left"
          >
            <Input
              placeholder="Teléfono"
              value={input.phone}
              defaultValue={input.phone}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleSubmitClick}
              style={{ width: "100%" }}
            >
              Guardar Lead
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default AddLead;
