// react
import React, { useContext, useEffect, useState } from "react";

// styles
import { Avatar, Tooltip } from "antd";

// context
import { ProjectsContext } from "../../../contexts/projects/ProjectsContext";
import { VisitContext } from "../../../contexts/visits/VisitsContext";

const VisitsActive = () => {
  const { selectedProject } = useContext(ProjectsContext);
  const { fetchVisitsActive, visitsActive, visitsInactive } =
    useContext(VisitContext);
  const [inactives, setInactives] = useState(0);
  const [actives, setActives] = useState(0);

  useEffect(() => {
    fetchVisitsActive(selectedProject?.id);
  }, [fetchVisitsActive, selectedProject]);

  useEffect(() => {
    setActives(Object.keys(visitsActive).length);
    setInactives(Object.keys(visitsInactive).length);
  }, [visitsActive, visitsInactive]);

  return (
    <div className="counter-visit-container">
      <div>
        <Tooltip title="Usuarios activos ahora">
          <Avatar
            style={{ color: "white", backgroundColor: "#AACB5A" }}
            size={25}
          >
            <span style={{ userSelect: "none" }}>{actives}</span>
          </Avatar>
        </Tooltip>
      </div>
      <div>
        <Tooltip title="Usuarios inactivos ahora">
          <Avatar
            style={{ color: "white", backgroundColor: "#DA4343" }}
            size={25}
          >
            <span style={{ userSelect: "none" }}>{inactives}</span>
          </Avatar>
        </Tooltip>
      </div>
    </div>
  );
};

export default VisitsActive;
