// react
import { useCallback, useEffect, useState } from "react";
import io from "socket.io-client";

// misc
import { ACCESS_TOKEN_NAME, API_SOCKET_URL } from "../constants/apiContants";

export const useSocket = (workspace) => {
  const [socket, setSocket] = useState(null);
  const [socketOnline, setSocketOnline] = useState(null);

  const conectarSocket = useCallback((workspace) => {
    const token = (JSON.parse(localStorage.getItem(ACCESS_TOKEN_NAME)))?.access?.token || null;
    // console.log('token', token)
    const socketTemp = io(`${API_SOCKET_URL}/${workspace}`, {
      auth: {
        token: `Bearer ${token}`,
      },
      query: {
        role: "manager",
        pixel_id: workspace,
      },
    });
    setSocket(socketTemp);
  }, []);

  const desconectarSocket = useCallback(() => {
    socket?.disconnect();
    setSocket(null);
  }, [socket]);

  useEffect(() => {
    if (workspace && !socket) {
      conectarSocket(workspace);
    }
    return () => {
      if (socket) {
        desconectarSocket();
      }
    };
  }, [conectarSocket, workspace, socket, desconectarSocket]);

  useEffect(() => {
    socket?.on("connect", () => setSocketOnline(true));
    socket?.on("disconnect", () => setSocketOnline(false));
    socket?.on("connect_error", (err) =>
      console.error(`connect_error due to ${err.message}`)
    );
    socket?.on("error", (err) => console.error(`error due to ${err.message}`));
    return () => {
      socket?.removeAllListeners("connect");
      socket?.removeAllListeners("disconnect");
    };
  }, [socket]);

  return {
    socket,
    setSocket,
    conectarSocket,
    desconectarSocket,
    socketOnline,
  };
};
