import { useEffect, useState } from "react";
import { formatFromMillisToTimeSession } from "../../../helpers/date"

const Timer = () => {
  const [time, setTime] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((prevTime) => prevTime + 1000);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return formatFromMillisToTimeSession(time);
};
export default Timer;
