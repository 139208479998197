import React, { useState } from "react";
import { Button } from "antd";
import UpdatePageForm from "./UpdatePageForm";

const UpdatePage = ({ updatePage, record }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Editar
      </Button>

      <UpdatePageForm setVisible={setVisible} visible={visible} updatePage={updatePage} record={record} />
    </>
  );
};

export default UpdatePage;
