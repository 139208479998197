import React, { useState } from "react";
import { Button } from "antd";

import TagTypeForm from "./TagTypeForm";

const UpdateTagType = ({ updateTagType, record }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Editar
      </Button>

      <TagTypeForm setVisible={setVisible} visible={visible} updateTagType={updateTagType} record={record} />
    </>
  );
};

export default UpdateTagType;
