import React, { useEffect, useState } from "react";
import _ from "lodash";
import { formatFromMillis } from "../../../helpers/date";
import useVisitants from "../../../hooks/useVisitants";

import StatisticWidget from "../../Emilus-theme/components/StatisticWidget";
import { Col, Row } from "antd";

const WhatHappeningCards = ({ visitState, visitsDB }) => {
  const [data, setData] = useState([]);
  const [users, setUsers] = useState(0);
  const [visitedPages, setVisitedPages] = useState(0);
  const [sessionTime, setSessionTime] = useState(0);
  const { fetchTodaysLeadCount, todaysLeadCount } = useVisitants();

  useEffect(() => {
    let id;
    // console.log("visitsDB", visitsDB);

    // Usuarios
    const countUniqUsersDB = _.uniqBy(visitsDB, "visitor").length;
    setUsers(visitState?.visits.length + countUniqUsersDB);

    // Páginas visitadas
    let pages = 0;
    visitState?.visits.map((visit) => (pages += visit.pages));
    (visitsDB?.visits || visitsDB)?.map((visit) => (pages += visit.pages));
    setVisitedPages(pages);

    // Tiempo de sesión
    const visits = (visitsDB?.visits || visitsDB)?.length;
    let time = 0;
    (visitsDB?.visits || visitsDB)?.map(
      (visit) => (time += visit?.time_session)
    );
    if (time && visits) {
      id = setTimeout(() => {
        setSessionTime(time / visits);
      }, 1000);
    }

    return () => {
      clearTimeout(id);
    }; // eslint-disable-next-line
  }, [visitState, visitsDB]);
  useEffect(() => {
    fetchTodaysLeadCount();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData([
      { title: "Usuarios", key: "users", value: users },
      {
        title: "Leads captados",
        key: "todays_lead_count",
        value: todaysLeadCount,
      },
      // { title: "Leads generados", key: "leadsGenerados", value: "-" },
      { title: "Páginas visitadas", key: "visited_pages", value: visitedPages },
      {
        title: "Tiempo sesión",
        key: "time_session",
        value: sessionTime !== 0 ? formatFromMillis(sessionTime) : "00:00",
      },
    ]);
    return () => {
      setData([]);
    };
  }, [users, visitedPages, sessionTime, todaysLeadCount]);

  return (
    <>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        {data?.map((_data) => {
          return (
            <Col key={_data?.key}>
              <StatisticWidget title={_data?.title} value={(_data?.value ?? 0).toString()} />
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default WhatHappeningCards;
