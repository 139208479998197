import React from "react";

import { ClientLayout } from "../../../components/Utils/Layout";
import UsersSection from "../../../components/Users/UsersSection";

const UserPage = ({auth, logout}) => {
  return (
    <ClientLayout content_title="Usuarios" auth={auth} logout={logout}>
      <UsersSection/>
    </ClientLayout>
  );
};

export default UserPage;
