import React from "react";
import useCampaigns from "../../../../hooks/useCampaigns";
import useChannels from "../../../../hooks/useChannels";
import AddCampaign from "./AddCampaign/AddCampaign";
import CampaignsList from "./CampaignsList";

export default function Campaigns() {
  const {
    addCampaign,
    fetchCampaigns,
    campaigns,
    deleteCampaign,
    updateCampaign,
  } = useCampaigns();
  const { fetchChannels, channels } = useChannels();

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontSize: "24px", margin: " 0 0 10px 5px" }}>
          <strong>Gestión</strong> de campañas
        </span>

        <AddCampaign addCampaign={addCampaign} channels={channels} />
      </div>

      <CampaignsList
        fetchCampaigns={fetchCampaigns}
        campaigns={campaigns}
        deleteCampaign={deleteCampaign}
        updateCampaign={updateCampaign}
        fetchChannels={fetchChannels}
        channels={channels}
      />
    </>
  );
}
