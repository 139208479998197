import React, { useContext, useEffect, useMemo, useState } from "react";

import { deviceType } from "../../../../helpers/deviceType";
import { isValidUrl } from "../../../../helpers/url";
import { newDate } from "../../../../helpers/date";
// import { ACCESS_TOKEN_NAME } from "../../../../../constants/apiContants";

import {
  /* Button, */ Button,
    Tag,
    Row,
    Tooltip,
    Typography,
    Table,
} from "antd";
import {
    BellOutlined,
    ClockCircleOutlined,
    FieldTimeOutlined,
    FileSearchOutlined,
    FlagOutlined,
    GlobalOutlined,
    LaptopOutlined,
    MessageOutlined,
    QuestionCircleOutlined,
    TrophyOutlined,
    UndoOutlined,
    UserOutlined,
    VerticalAlignBottomOutlined,
} from "@ant-design/icons";

// import Table from "../../../../Table/Table";
import SessionTimer from "../../../../components/Utils/Timer/SessionTimer";
// import ChatModal from "../../../../Chat/ChatModal";

import { SocketContext } from "../../../../contexts/sockets/SocketContext";
import { ChatContext } from "../../../../contexts/chat/ChatContext";
import DraggableModalChat from "../../Chat/DraggableModalChat";
import ChatButtonModal from "../../Chat/ChatButtonModal";
import { AuthContext } from "../../../../contexts/auth/AuthContext";
import Popup from "../../../Utils/Table/RowPopup/Popup";
import ShowModal from "../../../Utils/Table/RowPopup/ShowModal";
import AvatarProfile from "../../../Profile/AvatarProfile";
// import { sorterByLetters } from "../../../../../helpers/sorter";

const TableRealTime = ({ title, visits }) => {
    const { auth } = useContext(AuthContext);
    // TODO: La idea es que cada message entrante debe avisar
    // que el usuario necesita ayuda.
    const { Text } = Typography;
    const { socket } = useContext(SocketContext);
    const { addMessage } = useContext(ChatContext);
    const [chatStarted, setChatStarted] = useState(false);
    const [popup, setPopup] = useState({
        visible: false,
        x: 0,
        y: 0,
        showModal: false,
        option: "",
    });
    const [visibleModalChat, setVisibleModalChat] = useState(false);
    const [visitDataModalChat, setvisitDataModalChat] = useState(null);

    const onRow = (record) => {
        return {
            onContextMenu: (event) => {
                event.preventDefault();
                if (!popup.visible) {
                    if (!popup.showModal) {
                        document.addEventListener(`click`, function onClickOutside() {
                            setPopup((prev) => ({ ...prev, visible: false }));
                            document.removeEventListener(`click`, onClickOutside);
                        });
                    }
                }
                setPopup({
                    record,
                    visible: true,
                    x: event.pageX,
                    y: event.pageY,
                    showModal: false,
                });
            },
        };
    };

    useEffect(() => {
        socket?.on("message", (messageData) => {
            addMessage(messageData);
            if (messageData.user_sender?.visitor) {
                const chatModal = document.getElementsByClassName("modal-chat")[0];
                const idBtnCharla = document.getElementById(
                    "btn-charlar-" + messageData.chat_id
                );
                idBtnCharla?.classList.add("btn-charlar-animated");
                chatModal?.classList.add("outline-animated");
                setTimeout(() => {
                    chatModal?.classList.remove("outline-animated");
                    idBtnCharla?.classList.remove("btn-charlar-animated");
                }, 3000);
            }
        });
        return () => {
            socket?.removeEventListener("message");
        }; // eslint-disable-next-line
    }, [socket]);

    const columns = useMemo(
        () => [
            {
                title: (
                    <Tooltip
                        placement="top"
                        title="Number"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        Nº
                    </Tooltip>
                ),
                key: "index",
                ellipsis: true,
                showSorterTooltip: true,
                align: "center",
                width: "3%",
                render: (data, record, index) => index + 1,
                fixed: "left",
            },
            {
                title: (
                    <Tooltip
                        placement="top"
                        title="Tipo de dispositivo"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {/* <LaptopOutlined style={{ fontSize: "20px" }} /> */}
                        DISPOSITIVO
                    </Tooltip>
                ),
                key: "device_type",
                dataIndex: "device_type",
                ellipsis: true,
                showSorterTooltip: true,
                align: "center",
                width: "3%",
                render: (data, record) => (
                    <Tooltip placement="top" title="">
                        {deviceType(record.bot, data)}
                    </Tooltip>
                ),
                fixed: "left",
            },
            {
                title: (
                    <Tooltip
                        placement="top"
                        title="Usuario"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {/* <UserOutlined style={{ fontSize: "20px" }} /> */}
                        USUARIO
                    </Tooltip>
                ),
                key: "visitor",
                dataIndex: "visitor",
                ellipsis: true,
                align: "center",
                showSorterTooltip: true,
                width: "5%",
                render: (data, record) => (
                    <Tooltip
                        placement="top"
                        title={
                            data.name
                                ? data.name
                                : `El id único del usuario es: ${data?.id?.slice(-6)}`
                        }
                    >
                        {data.name ? (
                            record.active ? (
                                <Text type="success">{data.name}</Text>
                            ) : (
                                <Text type="danger">{data.name}</Text>
                            )
                        ) : record.active ? (
                            <Text type="success">{`#${data?.id?.slice(-6)}`}</Text>
                        ) : (
                            <Text type="danger">{`#${data?.id?.slice(-6)}`}</Text>
                        )}
                    </Tooltip>
                ),
                fixed: "left",
            },
            {
                title: (
                    <Tooltip
                        placement="top"
                        title="Gestor"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {/* <UserOutlined style={{ fontSize: "20px" }} /> */}
                        GESTOR
                    </Tooltip>
                ),
                key: "usuario_captador",
                dataIndex: "visitor.user_catcher",
                ellipsis: true,
                align: "left",
                showSorterTooltip: true,
                width: "60px",
                render: (_, record) =>
                    record.visitor.user_catcher?.name ? (
                        <AvatarProfile user={record.visitor.user_catcher} />
                    ) : (
                        "-"
                    ),
                fixed: "left",
            },

            {
                title: (
                    <Tooltip
                        placement="top"
                        title="Procedencia"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {/* <VerticalAlignBottomOutlined style={{ fontSize: "20px" }} /> */}
                        PROCEDENCIA
                    </Tooltip>
                ),
                key: "url_origin",
                dataIndex: "url_origin",
                ellipsis: true,
                align: "left",
                width: "110px",
                showSorterTooltip: true,
                render: (data) => (
                    <Tooltip placement="top" title={data ? isValidUrl(data) : "Búsqueda"}>
                        {data ? isValidUrl(data) : "Búsqueda"}
                    </Tooltip>
                ),
            },
            {
                title: (
                    <Tooltip
                        placement="top"
                        title="Localización"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {/* <FlagOutlined style={{ fontSize: "20px" }} /> */}
                        ORIGEN
                    </Tooltip>
                ),
                key: "geolocation",
                dataIndex: "geolocation",
                ellipsis: true,
                align: "left",
                showSorterTooltip: true,
                render: (data, record) => (
                    <Tooltip
                        placement="topLeft"
                        title={
                            record.city && record.country
                                ? `${record.city}, ${record.country}`
                                : "No disponible"
                        }
                    >
                        {record.city && record.country ? (
                            <div>
                                {/* <img
                  src={`https://www.countryflags.io/${record.geolocalizacion.country_code.toLowerCase()}/flat/16.png`}
                  alt={record.country}
                /> */}
                                <span style={{ marginLeft: "1px" }}>
                                    {record.geolocation.country_code}
                                </span>
                            </div>
                        ) : (
                            <QuestionCircleOutlined style={{ fontSize: "20px" }} />
                        )}
                    </Tooltip>
                ),
                // sorter: (a, b) => {
                //   const localizationA = `${a.country}, ${a.city}`;
                //   const localizationB = `${b.country}, ${b.city}`;
                //   return localizationA.localeCompare(localizationB);
                // },
                width: "4%",
            },
            {
                title: (
                    <Tooltip
                        placement="top"
                        title="Navegador"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {/* <GlobalOutlined style={{ fontSize: "20px" }} /> */}
                        NAVEGADOR
                    </Tooltip>
                ),
                key: "browser",
                dataIndex: "browser",
                ellipsis: true,
                align: "left",
                showSorterTooltip: true,
                width: "7%",
                render: (data) =>
                    data ? data : <QuestionCircleOutlined style={{ fontSize: "20px" }} />,
            },
            {
                title: (
                    <Tooltip
                        placement="top"
                        title="Hora de llegada"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {/* <ClockCircleOutlined style={{ fontSize: "20px" }} /> */}
                        HORA
                    </Tooltip>
                ),
                key: "createdAt",
                dataIndex: "createdAt",
                ellipsis: true,
                align: "left",
                showSorterTooltip: true,
                width: "5%",
                render: (data) => (
                    <Tooltip
                        placement="topRight"
                        title={`El usuario ha visitado a las ${newDate(data).toFormat(
                            "T"
                        )}`}
                    >
                        <div style={{ textAlign: "end" }}>
                            <span>{newDate(data).toFormat("T")}</span>
                        </div>
                    </Tooltip>
                ),
                // sorter: (a, b) => new Date(b.fecha) - new Date(a.fecha),
            },
            {
                title: (
                    <Tooltip
                        placement="top"
                        title="Tiempo de sesión"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {/* <FieldTimeOutlined style={{ fontSize: "20px" }} /> */}
                        TIEMPO DE SESIÓN
                    </Tooltip>
                ),
                key: "last_connection_date",
                dataIndex: "last_connection_date",
                ellipsis: true,
                showSorterTooltip: true,
                align: "right",
                width: "8%",
                render: (data, record) => {
                    return (
                        <Tooltip
                            placement="top"
                            title={
                                <span>
                                    Tiempo activo del usuario:
                                    <SessionTimer
                                        lastConnection={data}
                                        time_session={record.time_session}
                                    />
                                </span>
                            }
                        >
                            <SessionTimer
                                lastConnection={data}
                                time_session={record.time_session}
                            />
                        </Tooltip>
                    );
                },
                // sorter: (a, b) => a.last_connection_date - b.last_connection_date,
            },
            {
                title: (
                    <Tooltip
                        placement="top"
                        title="El usuario ha venido"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {/* <UndoOutlined style={{ fontSize: "20px" }} /> */}
                        RECURRENCIA
                    </Tooltip>
                ),
                key: "recurrence",
                dataIndex: "recurrence",
                ellipsis: true,
                showSorterTooltip: true,
                align: "left",
                render: (data) => <>{data > 1 ? `${data} veces` : `${data} vez`}</>,
                // sorter: (a, b) => a.recurrencia - b.recurrencia,
                width: "70px",
            },
            {
                title: (
                    <Tooltip
                        placement="top"
                        title="Páginas vistas"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {/* <FileSearchOutlined style={{ fontSize: "20px" }} /> */}
                        PAGINAS VISTAS
                    </Tooltip>
                ),
                key: "pages",
                dataIndex: "pages",
                ellipsis: true,
                showSorterTooltip: true,
                align: "left",
                render: (data) => (data > 1 ? `${data} páginas` : `${data} página`),
                // sorter: (a, b) => a.historial_navegacion.length - b.historial_navegacion.length,
                width: "6%",
            },
            {
                title: (
                    <Tooltip
                        placement="top"
                        title="Ahora en.."
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {/* <FileSearchOutlined style={{ fontSize: "20px" }} /> */}
                        AHORA EN...
                    </Tooltip>
                ),
                key: "url_current",
                dataIndex: "url_current",
                ellipsis: true,
                align: "left",
                width: "110px",
                showSorterTooltip: true,
                render: (data) => {
                    const currentUrl = new URL(data);
                    const urlValidate = currentUrl.href.replace(currentUrl.origin, "")

                    return (
                        <Tooltip
                            placement="topLeft"
                            title={
                                currentUrl.href
                            }
                        >
                            <a target="_blank" rel="noreferrer" href={data}>
                                {urlValidate === "/" ? "Home" : urlValidate}
                            </a>
                        </Tooltip>
                    )
                },
            },
            {
                title: (
                    <Tooltip
                        placement="top"
                        title="Tags"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {/* <TrophyOutlined style={{ fontSize: "20px" }} /> */}
                        TAGS
                    </Tooltip>
                ),
                key: "tags",
                dataIndex: "tags",
                width: "60px",
                showSorterTooltip: true,
                align: "right",
                render: (data, record) => (
                    <div className="table-tags-order">
                        {Object.values(record?.tags || {})?.map((t, i) => {
                            if (i <= 3) {
                                let mycolor = "";

                                if (t.count === 2) {
                                    mycolor = "green";
                                }

                                if (t.count >= 3) {
                                    mycolor = "red";
                                }

                                return (
                                    <Tooltip
                                        placement="topLeft"
                                        title={t.reference ? `${t.reference}` : "N/R"}
                                        key={`${t.reference}-${t.count || 1}`}
                                    >
                                        <Tag key={`${t.reference}-${t.count || 1}`} color={mycolor}>
                                            {t.count || 1} {t.reference}
                                        </Tag>
                                    </Tooltip>
                                );
                            }
                            return <></>;
                        })}
                    </div>
                ),
            },
            {
                title: (
                    <Tooltip
                        placement="top"
                        title="Campaña"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <BellOutlined style={{ fontSize: "20px" }} />
                    </Tooltip>
                ),
                key: "campaign",
                dataIndex: "campaign",
                ellipsis: true,
                showSorterTooltip: false,
                align: "left",
                width: "45px",
                render: (data) => {
                    return (
                        <Tooltip title={data?.name || " Sin campaña"}>
                            {data?.name ? (
                                <BellOutlined style={{ fontSize: "20px" }} />
                            ) : (
                                <BellOutlined
                                    style={{ fontSize: "20px", color: "lightgray" }}
                                />
                            )}
                        </Tooltip>
                    );
                },
            },
            {
                title: (
                    <Tooltip
                        placement="top"
                        title="Chat Proactivo"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <MessageOutlined style={{ fontSize: "20px" }} />
                    </Tooltip>
                ),
                key: "chatting",
                dataIndex: "chatting",
                // ellipsis: true,
                width: "220px",
                showSorterTooltip: true,
                align: "center",
                // sorter: true,
                render: (_, record) =>
                    record?.chatting ? (
                        record.chatting.manager.id !== auth.user.id ? (
                            <Button disabled>
                                Charlando con {record.chatting?.manager?.name}
                            </Button>
                        ) : (
                            <ChatButtonModal
                                visit={record}
                                text="Charlando conmigo"
                                tableChatStarted={true}
                                setTableChatStarted={setChatStarted}
                                setVisibleModalChat={setVisibleModalChat}
                                setvisitDataModalChat={setvisitDataModalChat}
                            />
                        )
                    ) : (
                        <ChatButtonModal
                            visit={record}
                            tableChatStarted={chatStarted}
                            setTableChatStarted={setChatStarted}
                            setVisibleModalChat={setVisibleModalChat}
                            setvisitDataModalChat={setvisitDataModalChat}
                        />
                    ),
                fixed: "right",
            },
            // {
            //   title: (
            //     <Tooltip
            //       placement="top"
            //       title="Mirror"
            //       style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
            //     >
            //       <MessageOutlined style={{ fontSize: "20px" }} />
            //     </Tooltip>
            //   ),
            //   key: "mirror",
            //   dataIndex: "mirror",
            //   ellipsis: true,
            //   width: "auto",
            //   showSorterTooltip: true,
            //   align: "center",
            //   sorter: true,
            //   render: (data, record) => {
            //     // El modo mirror se habilitará luego de la entrega
            //     let params = {
            //       gvid: record.visitante.id,
            //       gtkn: localStorage.getItem(ACCESS_TOKEN_NAME) || "",
            //     };
            //     return (
            //       <Button type="primary" onClick={() => openNewTab(record?.url_actual, params)}>
            //         Mirror
            //       </Button>
            //     );
            //   },
            // },
        ],
        [chatStarted, auth]
    );

    return (
        <div style={{ margin: "0" }}>
            <Row style={{ margin: "15px 0" }}>
                {title}
                {/* <Divider style={{ margin: "15px 0" }} /> */}
            </Row>
            <Table
                columns={columns}
                dataSource={visits?.visits || visits}
                rowKey={(record) => record?.id}
                pagination={false}
                // loading={loading}
                onRow={onRow}
                scroll={{ x: 1500 }}
                bordered={true}
                locale={{
                    triggerAsc: "Ordenar ascendentemente",
                    triggerDesc: "Ordenar descendenmente",
                    cancelSort: "No ordenar",
                }}
                size="small"
            />
            <Popup
                record={popup.record}
                visible={popup.visible}
                x={popup.x}
                y={popup.y}
                setPopup={setPopup}
                RT={true}
            />
            <ShowModal
                show={popup.showModal}
                setPopup={setPopup}
                popup={popup}
                record={popup.record}
            />
            {visibleModalChat && visitDataModalChat ? (
                <DraggableModalChat
                    visit={visitDataModalChat}
                    visibleChat={visibleModalChat}
                    setVisibleModalChat={setVisibleModalChat}
                    setTableChatStarted={setChatStarted}
                />
            ) : null}
        </div>
    );
};

export default TableRealTime;
