// react
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

// auth
import { AuthContext } from "../contexts/auth/AuthContext";

import _ from "lodash";

const ProtectedRoute = ({ component, ...rest }) => {
  const { auth } = useContext(AuthContext);
  const isAuthorized = _.intersection(
    ["admin", "client_admin", "agency_admin"],
    auth?.user?.role
  )
    ? true
    : false;

  if (!isAuthorized) {
    return <Redirect to={{ pathname: "/projects" }} />;
  }

  return <Route {...rest} component={component} />;
};

export default ProtectedRoute;
