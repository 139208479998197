// react
import React from "react";

import useTagsType from "../../../../../hooks/tags/useTagsType";
import TagsTypeList from "./TagsTypeList";
import AddTagType from "./AddTagType";

export default function TagsTypeSection() {
  
  const { tagsType, addTagType, deleteTagType, getTagsType, updateTagType } = useTagsType();

  return (
    <>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
      <span style={{ fontSize: "24px", margin: " 0 0 10px 5px" }}>
        <strong>Gestión</strong> de tipos de tag
      </span>
        <AddTagType addTagType={addTagType} />
      </div>

      <TagsTypeList 
      tagsType = {tagsType}
      getTagsType = {getTagsType}
      updateTagType = {updateTagType}
      deleteTagType = {deleteTagType}
      />

    </>
  );
}
