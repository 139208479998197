// react
import { useCallback, useState } from "react";
import { fetchDataToken } from "../helpers/fetch";

// misc
import {
  errorNotification,
  successNotification,
} from "../components/Utils/Notification/Notification";

// auth

export default function useAgencies() {
  const [agencies, setAgencies] = useState([]);
  const [agenciesPagination, setAgenciesPagination] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchAgencies = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await fetchDataToken("/agency/", data, "GET");
    //   console.log("fetchAgencies - response", response);
      if (response.status === 200) {
        const {
          results,
          limit: pageSize,
          page: current,
          totalResults: total,
        } = response.data;
        setAgenciesPagination({ pageSize, current, total });
        setAgencies(results || []);
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
      setLoading(false);
    }
  }, []);

  const addAgency = useCallback(async (data, params = {}) => {
    try {
      const response = await fetchDataToken(`/agency`, data, "POST", params);

      if (response.status === 201) {
        // console.log("response", response);
        setAgenciesPagination((prev) => ({ ...prev, total: prev.total + 1 }));
        setAgencies((prev) => [...prev, response.data]);
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, []);

  const updateAgency = useCallback(async (id, data, params = {}) => {
    try {
      const response = await fetchDataToken(
        `/agency/${id}`,
        data,
        "PUT",
        params
      );

    //   console.log("updateAgency - response: ", response);

      if (response.status === 200) {
        setAgencies((prev) => {
          return prev.map((c) => {
            if (c.id === id) {
              return response.data;
            }
            return c;
          });
        });
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, []);

  const deleteAgency = useCallback(async (id) => {
    try {
      const response = await fetchDataToken(`/agency/${id}`, {}, "DELETE");

      if (response.status === 204) {
        setAgenciesPagination((prev) => ({ ...prev, total: prev.total - 1 }));
        setAgencies((prev) => prev.filter((c) => c.id !== id));
        successNotification(
          "Agencia eliminada",
          "Se ha eliminado correctamente la agencia"
        );
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, []);

  return {
    agencies,
    fetchAgencies,
    loading,
    addAgency,
    updateAgency,
    deleteAgency,
    agenciesPagination,
    setAgenciesPagination,
  };
}
