// react
import { useCallback, useState } from "react";
import { fetchDataToken } from "../helpers/fetch";

// misc
import {
  errorNotification,
  successNotification,
} from "../components/Utils/Notification/Notification";

// auth

export default function useClients() {
  const [clients, setClients] = useState([]);
  const [paginationClients, setPaginationClients] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchClients = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await fetchDataToken("/client/", data, "GET");
    //   console.log("fetchClients - response", response);
      if (response.status === 200) {
        const { results, limit, totalPages, totalResults } = response?.data;
        setClients(results || []);
        setPaginationClients({ limit, totalPages, totalResults });
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
      setLoading(false);
    }
  }, []);

  const addClient = useCallback(async (data, params = {}) => {
    try {
      const response = await fetchDataToken(`/client/`, data, "POST", params);

      if (response.status === 201) {
        setClients((prev) => [response.data, ...prev]);
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, []);

  const updateClient = useCallback(async (id, data, params = {}) => {
    try {
      const response = await fetchDataToken(
        `/client/${id}`,
        data,
        "PUT",
        params
      );

    //   console.log("updateClient - response: ", response);

      if (response.status === 200) {
        setClients((prev) => {
          return prev.map((client) => {
            if (client.id === response?.data?.id) {
              return response.data;
            }
            return client;
          });
        });
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, []);

  const deleteClient = useCallback(async (id) => {
    try {
      const response = await fetchDataToken(`/client/${id}`, {}, "DELETE");

      if (response.status === 204) {
        setClients((prev) => prev.filter((c) => c.id !== id));
        successNotification(
          "Cliente eliminado",
          "Se ha eliminado correctamente el cliente"
        );
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, []);

  return {
    clients,
    fetchClients,
    loading,
    addClient,
    updateClient,
    deleteClient,
    paginationClients,
  };
}
