// react
import React, { useContext, useEffect } from "react";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

// context
import ChatProvider from "../../contexts/chat/ChatContext";
import { ProjectsContext } from "../../contexts/projects/ProjectsContext";
import { UsersContext } from "../../contexts/users/UsersContext";
import { AgencyContext } from "../../contexts/agency/AgencyContext";

// misc
import Users from "./Settings/Users/Users";
import Visits from "./Visits/Visits";
import WhatHappening from "./WhatHappening/WhatHappening";
import ProtectedRoute from "../../router/ProtectedRoute";
import LoadingWithRender from "../../components/Utils/Loading/LoadingWithRender";
import WorkMyLeads from "./WorkMyLeads/WorkMyLeads";
import Campaigns from "./Settings/Campaigns/Campaigns";
import ChannelsSection from "./Settings/Channels/ChannelsSection";
import AnalyzeSellers from "./AnalizeSellers/AnalyzeSellers";
import TagsSection from "./Settings/Tags/TagSection";
import TagsTypeSection from "./Settings/Tags/TagsType/TagsTypeSection";

import Pages from "./Settings/Pages/Pages";
import Dashboard from "./Dashboard/Dashboard";

const Project = () => {
  let history = useHistory();
  const { setUsers } = useContext(UsersContext);
  const {
    projects,
    fetchProjects,
    selectProject,
    selectedProject,
    deselectProject,
  } = useContext(ProjectsContext);
  const { fetchUsersOnline, cleanUsersOnline } = useContext(AgencyContext);

  let { slug } = useParams();
  const { url } = useRouteMatch();

  useEffect(() => {
    if (!projects) {
      (async () => {
        await fetchProjects({ populate: "created_by_user,agency,client" });
        selectProject(slug);
      })();
    } else {
      selectProject(slug);
    }

    return () => {
      deselectProject();
      setUsers(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, slug]);

  useEffect(() => {
    if (selectedProject?.id) {
      fetchUsersOnline({ projects: [selectedProject?.id], is_online: true });
    }

    return () => {
      cleanUsersOnline();
    };
  }, [selectedProject?.id, fetchUsersOnline, cleanUsersOnline]);

  useEffect(() => {
    if (projects) {
      if (!selectedProject) {
        if (projects.find((project) => project.name === slug)) {
          selectProject(slug);
        } else {
          history.push("/projects");
        }
      } else {
        const project = {
          client: selectedProject.client,
          name: selectedProject.name,
          id: selectedProject.id,
          pixel_id: selectedProject.pixel_id,
        };
        localStorage.setItem("project", JSON.stringify(project));
      }
    }
    //eslint-disable-next-line
  }, [history, slug, selectedProject, selectProject]);

  return (
    <Switch>
      <ProtectedRoute path={`${url}/tagstype`}>
        <TagsTypeSection />
      </ProtectedRoute>

      <ProtectedRoute path={`${url}/tags`}>
        <TagsSection />
      </ProtectedRoute>

      <ProtectedRoute path={`${url}/channels`}>
        <ChannelsSection />
      </ProtectedRoute>

      <ProtectedRoute path={`${url}/campaigns`}>
        <Campaigns />
      </ProtectedRoute>

      <ProtectedRoute path={`${url}/users`}>
        <Users />
      </ProtectedRoute>

      <ProtectedRoute path={`${url}/sellers`}>
        <AnalyzeSellers />
      </ProtectedRoute>

      <ProtectedRoute path={`${url}/pages`}>
        <Pages />
      </ProtectedRoute>

      <Route path={`${url}/leads`}>
        <WorkMyLeads />
      </Route>

      <Route path={`${url}/visits`}>
        <Visits />
      </Route>

      <Route path={`${url}/rt`}>
        <ChatProvider>
          <LoadingWithRender>
            <WhatHappening />
          </LoadingWithRender>
        </ChatProvider>
      </Route>

      <Dashboard />
    </Switch>
  );
};

export default Project;
