// react
import React from "react";

// styles
import { Tooltip} from "antd";
// import {
//   CalendarOutlined,
//   DeleteOutlined,
//   EuroOutlined,
//   MailOutlined,
//   PhoneOutlined,
//   TrophyOutlined,
//   UserOutlined,
//   UserSwitchOutlined,
// } from "@ant-design/icons";


function TableCell({data, title_tooltip}) {

  let title = "";
  if(title_tooltip){
    title = title_tooltip;
  }

  return (

    <Tooltip title={title}>
    <span>{data}</span>
    </Tooltip>
  );
}

export default TableCell;
