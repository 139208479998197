import React, { useState, useCallback, useEffect } from "react";
import { Button } from "antd";

import useClients from "../../../hooks/useClients";

import AgencyForm from "./AgencyForm";

const UpdateAgency = ({ updateAgency, record }) => {
  const [visible, setVisible] = useState(false);
  const { clients, fetchClients } = useClients();

  const loadClientsDataOnlyOnce = useCallback(
    (params) => {
      fetchClients({
        ...params,
        // populate: "created_by_user,projects",
      });
    },
    [fetchClients]
  );

  useEffect(() => {
    if (visible) {
      loadClientsDataOnlyOnce({ limit: 99999, agencies: [record.id] });
    }
  }, [loadClientsDataOnlyOnce, record.id, visible]);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Editar
      </Button>

      <AgencyForm
        setVisible={setVisible}
        visible={visible}
        updateAgency={updateAgency}
        record={record}
        clients={clients}
      />
    </>
  );
};

export default UpdateAgency;
