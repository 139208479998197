import React, { useEffect, useState } from "react";
import usePages from "../../../../hooks/usePages";
import PagesList from "./PagesList";

const Pages = () => {
  const { fetchPages, pages, updatePage, totalPages } = usePages();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} paginas`,
    showSizeChanger: true,
    position: ['bottomCenter']
  });

  const handleTableChange = (onChangePagination) => {
    if (
      onChangePagination.current !== pagination.current ||
      onChangePagination.pageSize !== pagination.pageSize
    ) {
      setPagination((prev) => ({ ...prev, ...onChangePagination }));
      const params = {
        page: onChangePagination.current,
        pageSize: onChangePagination.pageSize,
      };
      fetchPages(params);
    }
  };
  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  useEffect(() => {
    if (pages) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: totalPages,
      }));
    }
  }, [pages, totalPages]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontSize: "24px" }}>Gestionar páginas</span>
      </div>
      <PagesList
        fetchPages={fetchPages}
        pages={pages}
        updatePage={updatePage}
        pagination={pagination}
        handleTableChange={handleTableChange}
      />
    </>
  );
};

export default Pages;
