import React from "react";
import "./Popup.css";
import { ProjectOutlined } from "@ant-design/icons";
// import DraggableModalChat from "../../Chat/DraggableModalChat";

const Popup = ({ record, visible, x, y, setPopup }) => {
  const openModal = () => {
    setPopup((prev) => ({ ...prev, showModal: true, visible: false }));
  };

  const openToogle = (option) => {
    openModal();
    setPopup((prev) => ({ ...prev, option }));
  };

  return (
    <>
      {visible && (
        <ul className="popup" style={{ left: `${x}px`, top: `${y}px` }}>
          <li onClick={() => openToogle("proyectos")}>
            <ProjectOutlined />
            <span style={{ marginLeft: "5px" }}>Ver proyectos</span>
          </li>
        </ul>
      )}
    </>
  );
};

export default Popup;
