// react
import React from "react";

import ClientsList from "./ClientsList";
import useClients from "../../hooks/useClients";
import AddClient from "./form/AddClient"

export default function ClientsSection() {
  const { clients, clientsPagination, setClientsPagination, fetchClients, loading, addClient, updateClient, deleteClient} = useClients()

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontSize: "24px", margin: " 0 0 10px 5px" }}>
          <strong>Gestión</strong> de clientes
        </span>
        <AddClient addClient={addClient} />
      </div>

      <ClientsList
        clients={clients}
        fetchClients={fetchClients}
        loading={loading}
        updateClient={updateClient}
        deleteClient={deleteClient}
        paginationClients={clientsPagination}
        setClientsPagination={setClientsPagination}
      />
    </>
  );
}
