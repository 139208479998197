import React, { useState, useContext, useEffect } from "react";
import { Form, Input, Modal, Select } from "antd";

import "./UserForm.css";
import { ROLES_MAP } from "../../../constants/apiContants";

// auth
import { AuthContext } from "../../../contexts/auth/AuthContext";
// misc
import {
  errorNotification,
  successNotification,
} from "../../../components/Utils/Notification/Notification";
import _ from "lodash";

const { Option } = Select;

const UserForm = ({
  visible,
  setVisible,
  addUser,
  updateUser,
  record,
  agencies = [],
  clients = [],
  projects = [],
  loadClientsDataOnlyOnce = null,
  loadProjectsDataOnlyOnce = null,
}) => {
  const { register, auth } = useContext(AuthContext);

  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalTitle, setmodalTitle] = useState("Crear un Usuario");
  const default_role = _.find(ROLES_MAP, { default: true });
  const [rol, setRol] = useState(default_role.value);

  useEffect(() => {
    form.setFieldsValue({ role: default_role.value });
  }, [form, default_role]);

  useEffect(() => {
    if (record) {
      setmodalTitle("Editar un Usuario");
      const agency = record?.agency?.id || record?.agency || null;
      const clients = record?.clients?.length
        ? record.clients.map((client) => {
            return client?.id || client;
          })
        : [];
      const projects = record?.projects?.length
        ? record.projects.map((project) => {
            return project?.id || project;
          })
        : [];
      form.setFieldsValue({ ...record, agency, clients, projects });
    }
  }, [form, record]);

  const handleOk = async () => {
    try {
      setConfirmLoading(true);

      const data = await form.validateFields();
    //   console.log("data", data);
      let response = null;

      const populate = "created_by_user";
      if (record) {
        response = await updateUser(record?.id, data, { populate });
      } else {
        if (data.password !== data.confirm_password) {
          errorNotification("Error", "Contraseñas no coinciden");
          setConfirmLoading(false);
          return false;
        }
        delete data.confirm_password;
        response = await addUser(data, { populate });
      }

    //   console.log("response", response);

      setVisible(false);
      setConfirmLoading(false);
      form.resetFields();
      // setInput({});
    } catch (error) {
      console.error("catch", error?.message);
      errorNotification("Error", error?.message || "Verifique el formulario");
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onRoleChange = (value) => {
    setRol(value);
    form.setFieldsValue({ role: value });
  };

  const BuildSelectRoles = () => {
    return (
      <Select
        // initialValue={default_role.value}
        name="role"
        id="role"
        value={default_role.value}
        onChange={onRoleChange}
        placeholder="Selecciona el rol"
      >
        {ROLES_MAP.map((role) => {
          return (
            <Option key={role.value} id={role.value} value={role.value}>
              {role.text}
            </Option>
          );
        })}
      </Select>
    );
  };

  const onAgencyChange = (value) => {
    form.setFieldsValue({ agency: value });

    form.setFieldsValue({ clients: [] });
    if (loadClientsDataOnlyOnce) {
      loadClientsDataOnlyOnce({ limit: 99999, agency: value });
    }
  };

  const onClientsChange = (value) => {
    form.setFieldsValue({ clients: value });

    form.setFieldsValue({ projects: [] });
    if (loadProjectsDataOnlyOnce) {
      const { agency } = form.getFieldsValue();
      loadProjectsDataOnlyOnce({ limit: 99999, agency, clients: value });
    }
  };

  const onProjectsChange = (value) => {
    console.log("value", value);
    form.setFieldsValue({ projects: value });
  };

  const BuildUserDetailAgencyClient = () => {
    if (rol === "client_agent" || rol === "client_admin") {
      return (
        <>
          <Form.Item
            label="Agencia"
            name="agency"
            id="agency"
            rules={[{ required: true, message: "Es necesario!" }]}
          >
            <Select
              name="agency_input"
              id="agency_input"
              onChange={onAgencyChange}
              placeholder="Selecciona la agencia"
            >
              {agencies.map((agency) => {
                return (
                  <Option key={agency.id} id={agency.id} value={agency.id}>
                    {agency?.name || agency?.id}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Clientes"
            name="clients"
            id="clients"
            rules={[{ required: true, message: "Es necesario!" }]}
          >
            <Select
              mode="multiple"
              allowClear
              name="clients_input"
              id="clients_input"
              onChange={onClientsChange}
              placeholder="Selecciona los clientes"
            >
              {clients.map((client) => {
                return (
                  <Option key={client?.id} id={client.id} value={client.id}>
                    {client?.name || client?.id}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </>
      );
    } else {
      return (
        <>
          <Form.Item
            label="Agencia"
            name="agency"
            id="agency"
            rules={[{ required: true, message: "Es necesario!" }]}
          >
            <Select
              name="agency"
              id="agency"
              onChange={onAgencyChange}
              placeholder="Selecciona la agencia"
            >
              {agencies.map((agency) => {
                return (
                  <Option key={agency.id} id={agency.id} value={agency.id}>
                    {agency.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </>
      );
    }
  };

  return (
    <Modal
      title={modalTitle}
      forceRender
      visible={visible}
      onOk={handleOk}
      okText="Guardar"
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      cancelText="Cancelar"
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        onFinish={handleOk}
        onFinishFailed={() => {
          console.error("form failed");
        }}
        initialValues={record}
      >
        <Form.Item
          label="Nombre"
          name="name"
          rules={[{ required: true, message: "Es necesario!" }]}
          id="name"
          // onChange={handleChange}
        >
          <Input placeholder="Nombre" value={form.getFieldValue("name")} />
        </Form.Item>
        <Form.Item
          label="Primer Apellido"
          name="first_lastname"
          id="first_lastname"
          // onChange={handleChange}
          rules={[{ required: true, message: "Es necesario!" }]}
        >
          <Input
            placeholder="Primer Apellido"
            value={form.getFieldsValue("first_lastname")}
          />
        </Form.Item>
        <Form.Item
          label="Segundo Apellido"
          name="second_lastname"
          id="second_lastname"
        >
          <Input
            placeholder="Segundo Apellido"
            value={form.getFieldsValue("second_lastname")}
          />
        </Form.Item>
        <Form.Item label="Teléfono" name="phone" id="phone">
          <Input placeholder="Teléfono" value={form.getFieldsValue("phone")} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          id="email"
          rules={[{ required: true, message: "Es necesario!" }]}
        >
          <Input placeholder="Email" value={form.getFieldsValue("email")} />
        </Form.Item>

        <Form.Item
          label="Rol"
          name="role"
          id="role"
          rules={[{ required: true, message: "Es necesario!" }]}
        >
          {BuildSelectRoles()}
        </Form.Item>

        {rol !== "admin" ? <>{BuildUserDetailAgencyClient()}</> : null}

        <Form.Item label="Proyectos Vinculados" name="projects" id="projects">
          <Select
            mode="multiple"
            allowClear
            name="projects_input"
            id="projects_input"
            onChange={onProjectsChange}
            placeholder="Selecciona los proyectos"
          >
            {projects?.map((project) => {
              const projectId = project?.id || project;
              return (
                <Option key={projectId} id={projectId} value={projectId}>
                  {project?.name || projectId}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        {!record ? (
          <>
            <Form.Item
              label="Contraseña"
              name="password"
              id="password"
              // onChange={handleChange}
              rules={[{ required: true, message: "Es necesario!" }]}
            >
              <Input
                placeholder="Contraseña"
                value={form.getFieldsValue("password")}
              />
            </Form.Item>

            <Form.Item
              label="Repita Contraseña"
              name="confirm_password"
              id="confirm_password"
              // onChange={handleChange}
              rules={[{ required: true, message: "Es necesario!" }]}
            >
              <Input
                placeholder="Repita Contraseña"
                value={form.getFieldsValue("confirm_password")}
              />
            </Form.Item>
          </>
        ) : null}
      </Form>
    </Modal>
  );
};

export default UserForm;
