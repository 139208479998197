// react
import React, { useState } from "react";
import { Button } from "antd";

import TagForm from "./TagForm";

const AddTag = ({ addTag, tagsType }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Añadir Etiqueta
      </Button>

      <TagForm setVisible={setVisible} visible={visible} addTag={addTag} tagsType={tagsType} />
    </>
  );
};

export default AddTag;
