import React, { useState, useContext, useEffect } from "react";
import { Form, Input, Modal, Select } from "antd";
import "./ClientForm.css";

// misc
import {
  errorNotification,
  successNotification,
} from "../../../components/Utils/Notification/Notification";

const { Option } = Select;

const ClientForm = ({
  visible,
  setVisible,
  addClient,
  updateClient,
  record,
  users = [],
  agencies = [],
  projects = [],
  loadUsersDataOnlyOnce = null,
}) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalTitle, setmodalTitle] = useState("Crear un Cliente");

  useEffect(() => {
    if (record) {
      setmodalTitle("Editar un Cliente");
      form.setFieldsValue(record);
    }
  }, [form, record]);

  const handleOk = async () => {
    try {
      setConfirmLoading(true);

      const data = await form.validateFields();
      let response = null;

      const populate = "created_by_user,agency";
      if (record) {
        response = await updateClient(record?.id, data, { populate });
      } else {
        response = await addClient(data, { populate });
      }

      setVisible(false);
      setConfirmLoading(false);
      form.resetFields();
      // setInput({});
    } catch (error) {
      console.error(error);
      errorNotification("ERROR", error.message);
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onAgencyChange = (value) => {
    form.setFieldsValue({ agency: value });

    form.setFieldsValue({ contact_user: null });
    if (loadUsersDataOnlyOnce) {
      loadUsersDataOnlyOnce({ limit: 99999, agency: value });
    }
  };

  const onUserChange = (value) => {
    form.setFieldsValue({ contact_user: value });
  };

  const onProjectsChange = (value) => {
    form.setFieldsValue({ projects: value });
  };

  return (
    <Modal
      title={modalTitle}
      forceRender
      getContainer={false}
      visible={visible}
      onOk={handleOk}
      okText="Guardar"
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      cancelText="Cancelar"
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        onFinish={handleOk}
        onFinishFailed={() => {
          console.error("form failed");
        }}
        initialValues={record}
      >
        <Form.Item
          label="Nombre Comercial"
          name="name"
          rules={[{ required: true, message: "Es necesario!" }]}
          id="name"
          // onChange={handleChange}
        >
          <Input
            placeholder="Nombre Comercial"
            value={form.getFieldValue("name")}
          />
        </Form.Item>
        <Form.Item
          label="Razón social"
          name="company_name"
          id="company_name"
          // onChange={handleChange}
          rules={[{ required: true, message: "Es necesario!" }]}
        >
          <Input
            placeholder="Razón social"
            value={form.getFieldsValue("company_name")}
          />
        </Form.Item>
        <Form.Item
          label="CIF"
          name="cif"
          id="cif"
          // onChange={handleChange}
          rules={[{ required: true, message: "Es necesario!" }]}
        >
          <Input placeholder="CIF" value={form.getFieldsValue("cif")} />
        </Form.Item>
        <Form.Item
          label="Teléfono"
          name="phone"
          id="phone"
          // onChange={handleChange}
          rules={[{ required: true, message: "Es necesario!" }]}
        >
          <Input placeholder="Teléfono" value={form.getFieldsValue("phone")} />
        </Form.Item>

        <Form.Item
          label="Agencia"
          name="agency"
          id="agency"
          rules={[{ required: true, message: "Es necesario!" }]}
        >
          <Select
            name="agency"
            id="agency"
            onChange={onAgencyChange}
            placeholder="Selecciona la agencia"
          >
            {agencies.map((agency) => {
              return (
                <Option key={agency.id} id={agency.id} value={agency.id}>
                  {agency.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Usuario de contacto"
          name="contact_user"
          id="contact_user"
        >
          <Select
            name="contact_user"
            id="contact_user"
            onChange={onUserChange}
            placeholder="Selecciona el usuario de contacto"
          >
            {users?.map((user) => {
              return (
                <Option key={user.id} id={user.id} value={user.id}>
                  {user.name} {user.first_lastname}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        {record && (
          <Form.Item label="Proyectos vinculados" name="projects" id="projects">
            <Select
              mode="multiple"
              allowClear
              name="projects"
              id="projects"
              onChange={onProjectsChange}
              placeholder="Selecciona los proyectos vinculados"
            >
              {projects?.map((project) => {
                return (
                  <Option
                    key={project?.id}
                    id={project?.id}
                    value={project?.id}
                  >
                    {project?.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default ClientForm;
