import React from "react";
import InputColor from "react-input-color";

const ColorPicker = ({ color, setColor }) => {
  // const [color, setColor] = React.useState({});

  return (
    <div>
      <InputColor initialValue={color.hex || "#e9564d"} onChange={setColor} placement="right" />
    </div>
  );
};

export default ColorPicker;
