import React from "react";

import { AgencyLayout } from "../../../components/Utils/Layout";
import UsersSection from "../../../components/Users/UsersSection";

const UserPage = ({auth, logout}) => {
  return (
    <AgencyLayout content_title="Usuarios" auth={auth} logout={logout}>
      <UsersSection/>
    </AgencyLayout>
  );
};

export default UserPage;
