// react
import React, { useContext, memo } from "react";
import { Link, useParams } from "react-router-dom";

// auth
import { AuthContext } from "../../contexts/auth/AuthContext";

// misc
import SelectProject from "./selectProject/SelectProject";
import UserOptions from "./userOptions/UserOptions";

// styles
import { PageHeader } from "antd";
import "./Navbar.css";

const Navbar = memo(({ title }) => {
  let { slug } = useParams();
  const { auth } = useContext(AuthContext);

  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        ghost={false}
        title={
          !auth?.isExternal ? (
            <Link to="/" style={{ color: "black" }}>
              {title.toUpperCase()}
            </Link>
          ) : (
            <Link to="/" style={{ color: "black" }}>
              {title.toUpperCase()}
            </Link>
            // slug && (
            //   <div key="2" className="projects-top-right" proyecto="nombre">
            //     <SelectProject />
            //   </div>
            // )
          )
        }
        style={{
          borderBottom: "1px solid #e4e4e4",
        }}
        extra={[
          //   slug && !auth.isExternal && (
          //     <div key="2" className="projects-top-right" proyecto="nombre">
          //       <SelectProject />
          //     </div>
          //   ),

          auth?.logged && slug ? (
            <div key="2" className="projects-top-right" proyecto="nombre">
              <SelectProject />
            </div>
          ) : null,

          auth?.logged ? <UserOptions key="1" /> : null,
        ]}
      />
    </div>
  );
});

export default Navbar;
