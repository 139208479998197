/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-expressions */
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export class LogoutService {
  constructor(_logout) {
    this._logout = _logout;
  }
  logout() {
    this._logout();
  }
}

export class WarningNotificationService {
  constructor(sec, cancel, logout) {
    this.sec = sec;
    this.cancel = cancel;
    this.logout = logout;
  }
  show() {
    let s = this.sec;
    const cancel = this.cancel;
    const logout = this.logout;
    const modal = Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Aviso de desconexión automática",
      content: <>En {s}seg se cerrará la sesión por inactividad</>,
      onOk() {
        logout();
      },
      okText: "Desconectar",
      onCancel() {
        cancel();
        clearInterval(interval);
      },
      cancelText: "Mantener sesión activa",
    });
    const interval = setInterval(() => {
      s -= 1;
      modal.update({ content: <>En {s}seg se cerrará la sesión por inactividad</> });
      if (s === 0) {
        modal.destroy();
        logout();
      }
    }, 1000);
  }
}

export class InactivityService {
  constructor({logout, secondsToShowWarning, secondsToDisconnect}) {
    this._timeout;

    this._boundListener = this._startTimer.bind(this);
    this.secToWarning = secondsToShowWarning;
    this.secToDisconnect = secondsToDisconnect;

    this.Logout = new LogoutService(logout);
    this.Warning = new WarningNotificationService(
      this.secToDisconnect,
      this._clearTimer.bind(this),
      this._logout.bind(this)
    );
  }

  subscribe() {
    this._addListeners();
  }

  unsubscribe() {
    clearTimeout(this._timeout);
    this._removeListeners();
  }

  _addListeners() {
    window.addEventListener("mousemove", this._boundListener);
    window.addEventListener("mousedown", this._boundListener);
    window.addEventListener("click", this._boundListener);
    window.addEventListener("keypress", this._boundListener);
    window.addEventListener("scroll", this._boundListener);
  }

  _logout() {
    this._removeListeners();
    this.Logout.logout();
  }

  _clearTimer() {
    clearTimeout(this._timeout);
  }

  _startTimer() {
    clearTimeout(this._timeout);
    this._timeout = setTimeout(this._startWarning.bind(this), this.secToWarning * 1000);
  }

  _startWarning() {
    this.Warning.show();
  }

  _removeListeners() {
    window.removeEventListener("mousemove", this._boundListener);
    window.removeEventListener("mousedown", this._boundListener);
    window.removeEventListener("click", this._boundListener);
    window.removeEventListener("keypress", this._boundListener);
    window.removeEventListener("scroll", this._boundListener);
  }
}
