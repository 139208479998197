import React from "react";

import { AdminLayout } from "../../../components/Utils/Layout";
import AgenciesSection from "../../../components/Agencies/AgenciesSection";

const AgencyPage = ({auth, logout}) => {
  return (
    <AdminLayout content_title="Agencies" auth={auth} logout={logout}>
      <AgenciesSection/>
    </AdminLayout>
  );
};

export default AgencyPage;
