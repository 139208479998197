// react
import React, { useContext, useEffect, useState } from "react";

// styles
import { Col, Form, Input, Row, Modal } from "antd";
import "./updateUser.css";

// context
import { UsersContext } from "../../../contexts/users/UsersContext";

// misc
import { infoNotification } from "../../Utils/Notification/Notification";

const UpdateUserForm = ({ visible, setVisible, data }) => {
  const { updateUser } = useContext(UsersContext);
  const [form] = Form.useForm();

  const [input, setInput] = useState({
    name: data.name,
    first_lastname: data.first_lastname,
    second_lastname: data.second_lastname,
    email: data.email,
    phone: data.phone,
    password: "",
    confirmPassword: "",
  });
  useEffect(() => {
    setInput({
      name: data.name,
      first_lastname: data.first_lastname,
      second_lastname: data.second_lastname,
      email: data.email,
      phone: data.phone,
      password: "",
      confirmPassword: "",
    });
  }, [data]);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    const fieldsAreComplete =
      input.name?.length &&
      input.first_lastname?.length &&
      input.second_lastname?.length &&
      input.email?.length &&
      input.phone?.length;
    if (fieldsAreComplete) {
      try {
        const updateData = {
          ...input,
        };
        delete updateData?.confirmPassword;
        setConfirmLoading(true);
        await form.validateFields();
        await updateUser(data.id, updateData);
        setVisible(false);
        setConfirmLoading(false);
        form.resetFields();
        setInput({});
      } catch (error) {
        console.error(error);
        setConfirmLoading(false);
        form.resetFields();
      }
    } else {
      infoNotification("Falta rellenar algunos campos");
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <Modal
      title="Editar usuario"
      visible={visible}
      onOk={handleOk}
      okText="Guardar"
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      cancelText="Cancelar"
    >
      <Row type="flex" justify="center" align="middle">
        <Col>
          <Form
            form={form}
            labelCol={{ span: 12 }}
            onFinish={handleOk}
            onFinishFailed={() => {
              console.error("form failed");
            }}
          >
            <Form.Item
              label="Nombre"
              name="name"
              id="name"
              onChange={handleChange}
              labelAlign="left"
            >
              <Input
                placeholder="Nombre"
                value={input.name}
                defaultValue={data.name}
              />
            </Form.Item>
            <Form.Item
              label="Primer apellido"
              name="first_lastname"
              id="first_lastname"
              onChange={handleChange}
              labelAlign="left"
            >
              <Input
                placeholder="Primer apellido"
                value={input.first_lastname}
                defaultValue={data.first_lastname}
              />
            </Form.Item>
            <Form.Item
              label="Segundo apellido"
              name="second_lastname"
              id="second_lastname"
              onChange={handleChange}
              labelAlign="left"
            >
              <Input
                placeholder="Segundo apellido"
                value={input.second_lastname}
                defaultValue={data.second_lastname}
              />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              id="email"
              onChange={handleChange}
              labelAlign="left"
            >
              <Input
                placeholder="Email"
                value={input.email}
                defaultValue={data.email}
              />
            </Form.Item>
            <Form.Item
              label="Teléfono"
              name="phone"
              id="phone"
              onChange={handleChange}
              labelAlign="left"
            >
              <Input
                placeholder="Telefono"
                value={input.phone}
                defaultValue={data.phone}
              />
            </Form.Item>
            <Form.Item
              label="Contraseña"
              name="password"
              id="password"
              onChange={handleChange}
              labelAlign="left"
            >
              <Input.Password placeholder="Contraseña" value={input.password} />
            </Form.Item>
            <Form.Item
              label="Confirma tu contraseña"
              name="confirmPassword"
              id="confirmPassword"
              onChange={handleChange}
              labelAlign="left"
            >
              <Input.Password
                placeholder="Contraseña"
                value={input.confirmPassword}
              />
            </Form.Item>
            {!data?.avatar && (
              <Form.Item
                label="Color de perfil"
                name="avatar_color"
                id="avatar_color"
                onChange={handleChange}
                labelAlign="left"
              >
                <Input type="color" value={input.avatar_color} />
              </Form.Item>
            )}
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default UpdateUserForm;
