import React, { useState } from "react";
import { Form, Input, Modal } from "antd";
import "../TagForm.css";

const TagTypeForm = ({
  visible,
  setVisible,
  addTagType,
  updateTagType,
  record,
}) => {
  const [form] = Form.useForm();
  const [input, setInput] = useState({ name: "", reference: "" });
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    try {
      setConfirmLoading(true);

      const data = await form.validateFields();

      if (record) {
        // console.log('record', record)
        await updateTagType((record?.id || record?._id), data);
      } else {
        await addTagType(input);
      }

      setVisible(false);
      setConfirmLoading(false);
      form.resetFields();
      setInput({});
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInput((prevState) => ({ ...prevState, [id]: value }));
  };

  return (
    <Modal
      title="Crear/Editar una Etiqueta"
      visible={visible}
      onOk={handleOk}
      okText="Guardar"
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      cancelText="Cancelar"
    >
      <Form
        form={form}
        labelCol={{ span: 12 }}
        onFinish={handleOk}
        onFinishFailed={() => {
          console.error("form failed");
        }}
        initialValues={record}
      >
        <Form.Item
          label="Nombre"
          name="name"
          id="nombre"
          onChange={handleChange}
          rules={[{ required: true, message: "Es necesario!" }]}
          labelAlign="left"
        >
          <Input placeholder="Nombre" value={input.name} />
        </Form.Item>

        <Form.Item
          label="Referencia"
          name="reference"
          id="referencia"
          onChange={handleChange}
          rules={[{ required: true, message: "Es necesario!" }]}
          labelAlign="left"
        >
          <Input placeholder="Referencia" value={input.reference} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TagTypeForm;
