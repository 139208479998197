import React from "react";

const getUserType = (sender) => {
  const userData = sender.user ? sender.user : sender.visitor;
  let user = {};
  user.name = `${userData?.name} ${userData?.first_lastname || ""} ${
    userData?.second_lastname?.charAt(0) || ""
  }`;
  if (!userData?.name) {
    user.name = `#${(sender?.id || sender).slice(-6)}`;
  }
  user.type = userData?.user_type || "CLIENT";
  return user;
};

const ChatMessage = ({
  type,
  message,
  date,
  showTimestamp,
  timestamp,
  sender,
}) => {
  if (type === "messageSent" || type === "messageReceived") {
    const user = getUserType(sender);
    return (
      <>
        {showTimestamp && (
          <div className="chat-messageRow chat-messages-info-date">
            {timestamp}
          </div>
        )}
        <div className={`chat-messageRow chat-${type}`}>
          <div className="chat-messageSent-container">
            <span className="chat-bubble-wrapper">
              {type === "messageReceived" && `${user.name}:`} {message}
            </span>
            <span className={`chat-${type}-date-info`}>{date}</span>
          </div>
        </div>
      </>
    );
  } else if (type === "informationMessage") {
    return (
      <div className="chat-messageRow chat-informationMessage">
        <span className="chat-bubble-wrapper">{message}</span>
      </div>
    );
  }
};

export default ChatMessage;
