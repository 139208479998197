import React, { useState } from "react";
import { Form, Input, Modal, Select } from "antd";
import "./addCampaign.css";

const AddCampaignForm = ({ visible, setVisible, addCampaign, channels }) => {
  const [form] = Form.useForm();
  const [input, setInput] = useState({
    name: "",
    campaign_reference: "",
    url: "",
    channel: "",
  });
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    try {
      setConfirmLoading(true);
      await form.validateFields();
      await addCampaign(input);
      setVisible(false);
      setConfirmLoading(false);
      form.resetFields();
      setInput({});
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const selectOnChange = (value, key) => {
    setInput((prev) => ({ ...prev, [key]: value }));
    form.setFieldsValue({
      [key]: value,
    });
  };

  return (
    <Modal
      title="Crear una campaña"
      visible={visible}
      onOk={handleOk}
      okText="Guardar"
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      cancelText="Cancelar"
    >
      <Form
        form={form}
        labelCol={{ span: 12 }}
        onFinish={handleOk}
        onFinishFailed={() => {
          console.error("form failed");
        }}
      >
        <Form.Item
          label="Nombre"
          name="name"
          id="name"
          onChange={handleChange}
          rules={[{ required: true, message: "Es necesario!" }]}
          labelAlign="left"
        >
          <Input placeholder="Nombre" value={input.name} />
        </Form.Item>
        <Form.Item
          label="Referencia"
          name="campaign_reference"
          id="campaign_reference"
          onChange={handleChange}
          rules={[{ required: true, message: "Es necesario!" }]}
          labelAlign="left"
        >
          <Input placeholder="Referencia" value={input.campaign_reference} />
        </Form.Item>
        <Form.Item
          label="URL"
          name="url"
          id="url"
          onChange={handleChange}
          rules={[{ required: true, message: "Es necesario!" }]}
          labelAlign="left"
        >
          <Input placeholder="https://www.example.com" value={input.url} />
        </Form.Item>
        <Form.Item
          label="Canal"
          name="channel"
          id="channel"
          onChange={handleChange}
          rules={[{ required: true, message: "Es necesario!" }]}
          labelAlign="left"
        >
          <Select onChange={(value) => selectOnChange(value, "channel")}>
            {channels?.map((c) => (
              <Select.Option value={c.id} key={c.id}>
                {c.channel_reference}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCampaignForm;
