import React, { useContext } from "react";
import { Spin } from "antd";
import { SocketContext } from "../../../contexts/sockets/SocketContext";

const LoadingWithRender = ({ children }) => {
    const { socketOnline } = useContext(SocketContext);
    if (!socketOnline) {
      return <Spin tip="Conectando..."> {children}</Spin>;
    }

    return <>{children}</>;
};

export default LoadingWithRender;
