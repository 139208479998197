// react
import React, { useEffect, useState, useCallback } from "react";

// styles
import { Table, Divider, Row, Popconfirm, Button, List } from "antd";

// helpers
import { newDate } from "../../helpers/date";
import { sorterByLetters } from "../../helpers/sorter";
import Filter from "../Utils/Table/Filter/Filter";

// components
import TableHead from "../Utils/Table/TableHead";
import TableCell from "../Utils/Table/TableCell.js";
import Popup from "../Table/RowPopup/Popup";
import ShowModal from "../Table/RowPopup/ShowModal";
import UpdateProject from "./form/UpdateProject";

const ProjectsList = ({
  projects,
  fetchProjects,
  loading,
  paginationProjects,
  updateProject,
  deleteProject,
}) => {
  const [popup, setPopup] = useState({
    visible: false,
    x: 0,
    y: 0,
    showModal: false,
    option: "",
  });

  const onRow = (record) => {
    return {
      onContextMenu: (event) => {
        event.preventDefault();
        if (!popup.visible) {
          if (!popup.showModal) {
            document.addEventListener(`click`, function onClickOutside() {
              setPopup((prev) => ({ ...prev, visible: false }));
              document.removeEventListener(`click`, onClickOutside);
            });
          }
        }
        setPopup({
          record,
          visible: true,
          x: event.pageX,
          y: event.pageY,
          showModal: false,
        });
      },
    };
  };

  const [filters, setFilters] = useState(null);

  const loadProjectsDataOnlyOnce = useCallback(() => {
    fetchProjects({ populate: "created_by_user,agency,client" });
  }, [fetchProjects]);

  useEffect(() => {
    loadProjectsDataOnlyOnce();
  }, [loadProjectsDataOnlyOnce]);

  const columns = [
    {
      title: "Fecha alta",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => newDate(data).toFormat("dd'-'LL'-'yyyy T"),
      width: "160px",
    },
    {
      title: <TableHead title="Nombre" align="left" data="name" />,
      dataIndex: "name",
      render: (data) => <TableCell data={data} />,
      sorter: (a, b) => sorterByLetters(a.name, b.name),
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Agencia" align="left" />,
      dataIndex: "agency",
      render: (data) => <TableCell data={data?.name} />,
      sorter: (a, b) => sorterByLetters(a.name, b.name),
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Cliente" align="left" />,
      dataIndex: "client",
      render: (data) => <TableCell data={data?.name} />,
      sorter: (a, b) => sorterByLetters(a.name, b.name),
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Usuarios vinculados" align="left" />,
      dataIndex: "users",
      render: (data) => <TableCell data={data?.length || 0} />,
      sorter: (a, b) => sorterByLetters(a.length, b.length),
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Dominios" align="left" />,
      dataIndex: "domains",
      render: (data) => {
        const domains = data?.length >= 3 ? data.slice(0, 3) : data;
        return (
          <List
            size="small"
            dataSource={domains}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        );
      },
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Creador" align="left" />,
      dataIndex: "created_by_user",
      render: (data) => <TableCell data={data?.email} />,
      sorter: (a, b) => sorterByLetters(a?.email, b?.email),
      showSorterTooltip: false,
    },
    {
      title: "Editar",
      key: "editar",
      align: "center",
      width: "10%",
      render: (_, record) => (
        <UpdateProject record={record} updateProject={updateProject} />
      ),
    },
    {
      title: "Eliminar",
      key: "eliminar",
      align: "center",
      width: "10%",
      render: (_, record) => (
        <Popconfirm
          title={
            <>
              Al eliminar se borrarán todos los datos relacionados con el
              projecte <br /> ¿Estás seguro?
            </>
          }
          okText="Si"
          cancelText="No"
          onConfirm={() => deleteProject(record?.id)}
        >
          <Button type="primary">Eliminar</Button>
        </Popconfirm>
      ),
    },
  ];

  const handleFilter = () => {
    const params = {
      ...filters,
      page: paginationProjects?.page,
      pageSize: paginationProjects?.limit,
    };
    fetchProjects(params);
  };

  const fields = [{ label: "Nombre/Ref", type: "text", key: "name" }];

  return (
    <>
      <Row>
        <Row
          justify="space-between"
          style={{ minWidth: "100%", alignItems: "center" }}
        >
          <Filter
            setFilters={setFilters}
            fields={fields}
            handleFilter={handleFilter}
          />
        </Row>

        <Divider style={{ margin: "15px 0" }} />
      </Row>

      <Table
        columns={columns}
        dataSource={projects}
        paginationProjects={paginationProjects}
        size="small"
        bordered={true}
        rowKey={(record) => record?.id}
        loading={loading}
        onRow={onRow}
      />
      <Popup
        record={popup.record}
        visible={popup.visible}
        x={popup.x}
        y={popup.y}
        setPopup={setPopup}
        RT={false}
      />
      <ShowModal
        show={popup.showModal}
        setPopup={setPopup}
        popup={popup}
        record={popup.record}
      />
    </>
  );
};

export default ProjectsList;
