import React, { useState } from "react";
import { Button } from "antd";
import AddUserForm from "./AddUserForm";

const AddUsers = () => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Añadir usuario
      </Button>

      <AddUserForm setVisible={setVisible} visible={visible} />
    </>
  );
};

export default AddUsers;
