
// react
import React, { useContext } from "react";

// styles
import { Divider } from "antd";

// auth
import { AuthContext } from "../../contexts/auth/AuthContext"

// misc
import UpdateUsers from "./updateProfile/UpdateUsers";

const DataProfile = () => {
  const { auth } = useContext(AuthContext);
  return (
    <div>
      <h2>Información básica</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontSize: "16px", color: "#6b778c" }}>Nombre</span>
        <span style={{ fontSize: "16px" }}>{auth.user.name}</span>
      </div>
      <Divider style={{ margin: "10px" }} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontSize: "16px", color: "#6b778c" }}>Primer apellido</span>
        <span style={{ fontSize: "16px" }}>{auth.user.first_lastname}</span>
      </div>
      <Divider style={{ margin: "10px" }} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontSize: "16px", color: "#6b778c" }}>Segundo apellido</span>
        <span style={{ fontSize: "16px" }}>{auth.user.second_lastname}</span>
      </div>
      <Divider style={{ margin: "10px", justifyContent: "space-between" }} />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontSize: "16px", color: "#6b778c" }}>Email</span>
        <span style={{ fontSize: "16px" }}>{auth.user.email}</span>
      </div>
      <Divider style={{ margin: "10px", justifyContent: "space-between" }} />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontSize: "16px", color: "#6b778c" }}>Cambiar Datos</span>
        <span style={{ fontSize: "16px" }}>
          <UpdateUsers data={auth.user} />
        </span>
      </div>
    </div>
  );
};

export default DataProfile;
