import React, { useState, useEffect, useCallback } from "react";
import { Button } from "antd";

import ClientForm from "./ClientForm";

import useUsers from "../../../hooks/useUsers";
import useAgencies from "../../../hooks/useAgencies";
import useProjects from "../../../hooks/useProjects";

const UpdateClient = ({ updateClient, record }) => {
  const [visible, setVisible] = useState(false);

  const { agencies, fetchAgencies } = useAgencies();
  const { users, fetchUsers } = useUsers();
  const { projects, fetchProjects } = useProjects();

  const showModal = () => {
    setVisible(true);
  };

  const loadAgenciesDataOnlyOnce = useCallback(
    (params) => {
      fetchAgencies({ ...params, populate: "created_by_user" });
    },
    [fetchAgencies]
  );

  const loadUsersDataOnlyOnce = useCallback(
    (params) => {
      fetchUsers({ ...params, populate: "created_by_user" });
    },
    [fetchUsers]
  );

  const loadProjectsDataOnlyOnce = useCallback(
    (params) => {
      fetchProjects({ ...params, populate: "created_by_user" });
    },
    [fetchProjects]
  );

  useEffect(() => {
    if (visible) {
      let params = {
        limit: 99999,
      };
      loadAgenciesDataOnlyOnce(params);
    }
  }, [loadAgenciesDataOnlyOnce, visible]);

  useEffect(() => {
    if (visible && record?.contact_user?.length) {
      let params = {
        limit: 99999,
        agency: record?.agency?.id,
      };
      loadUsersDataOnlyOnce(params);
    }
  }, [
    loadUsersDataOnlyOnce,
    record?.agency?.id,
    record?.contact_user?.length,
    visible,
  ]);

  useEffect(() => {
    if (visible && record?.projects?.length) {
      let params = {
        limit: 99999,
        client: record?.id,
        agency: record?.agency?.id,
      };
      loadProjectsDataOnlyOnce(params);
    }
  }, [
    loadProjectsDataOnlyOnce,
    record?.agency?.id,
    record?.id,
    record?.projects?.length,
    visible,
  ]);

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Editar
      </Button>

      <ClientForm
        setVisible={setVisible}
        visible={visible}
        updateClient={updateClient}
        record={record}
        agencies={agencies}
        users={users}
        loadUsersDataOnlyOnce={loadUsersDataOnlyOnce}
        projects={projects}
      />
    </>
  );
};

export default UpdateClient;
