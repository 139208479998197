// react
import React from "react";

import useTags from "../../../../hooks/tags/useTags";
import TagList from "./TagList";
import AddTag from "./AddTag";
import useTagVisits from "../../../../hooks/tags/useTagVisits";
import useTagsType from "../../../../hooks/tags/useTagsType";

export default function TagsSection() {
  
  const { tags, addTag, deleteTag, getTags, updateTag } = useTags();
  const { getTotalTagVisits } = useTagVisits();
  const { getTagsType, tagsType } = useTagsType();



  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontSize: "24px", margin: " 0 0 10px 5px" }}>
          <strong>Gestión</strong> de tags
        </span>
        <AddTag addTag={addTag} tagsType={tagsType} />
      </div>

      <TagList
        tags={tags}
        getTags={getTags}
        updateTag={updateTag}
        deleteTag={deleteTag}
        getTotalTagVisits={getTotalTagVisits}
        tagsType={tagsType}
        getTagsType={getTagsType}
      />
    </>
  );
}
