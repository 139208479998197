import React, { useState, useEffect } from "react";
import { Select, Form, Input, Modal } from "antd";
import "./ProjectForm.css";

// auth
import { AuthContext } from "../../../contexts/auth/AuthContext";
// misc
import {
  errorNotification,
  successNotification,
} from "../../../components/Utils/Notification/Notification";
import { PIXEL_DOMAIN } from "../../../constants/apiContants";

const { TextArea } = Input;
const { Option } = Select;

const ProjectForm = ({
  visible,
  setVisible,
  addProject,
  updateProject,
  record,
  agencies = [],
  clients = [],
  loadClientsDataOnlyOnce = null,
}) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalTitle, setmodalTitle] = useState("Crear un Proyecto");
  const [primaryColor, setPrimaryColor] = useState("#e9564d");
  const [secundaryColor, setSecundaryColor] = useState("#abbf20");

  useEffect(() => {
    if (record) {
      setmodalTitle("Editar un Proyecto");
      const agency = record?.agency?.id || record?.agency || null;
      const client = record?.client?.id || record?.client || null;
      const pixel = `<script src="${PIXEL_DOMAIN}"></script><script>gp.init({ pixel_id: '${record?.pixel_id}', token: '${record?.pixel_token}', theme: {primary: '${primaryColor?.hex}', secondary: '${secundaryColor.hex}'} })</script>`;
      form.setFieldsValue({ ...record, agency: agency, client: client, pixel });
    }
  }, [form, primaryColor?.hex, record, secundaryColor.hex]);

  const handleOk = async () => {
    try {
      setConfirmLoading(true);

      const data = await form.validateFields();
      let response = null;

      const params = {
        populate: "created_by_user,client,agency",
      };
      if (record) {
        delete data?.pixel;
        response = await updateProject(record?.id, data, params);
      } else {
        response = await addProject(data, params);
      }

      setVisible(false);
      setConfirmLoading(false);
      form.resetFields();
      // setInput({});
    } catch (error) {
      console.error(error);
      setConfirmLoading(false);
      errorNotification("Error", error.message);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onAgencyChange = (value) => {
    form.setFieldsValue({ agency: value });

    form.setFieldsValue({ client: null });
    if (loadClientsDataOnlyOnce) {
      loadClientsDataOnlyOnce({ limit: 99999, agency: value });
    }
  };

  const onClientChange = (value) => {
    form.setFieldsValue({ client: value });
  };

  return (
    <Modal
      title={modalTitle}
      forceRender
      visible={visible}
      onOk={handleOk}
      okText="Guardar"
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      cancelText="Cancelar"
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        onFinish={handleOk}
        onFinishFailed={() => {
          console.error("form failed");
        }}
        initialValues={record}
      >
        <Form.Item
          label="Nombre Comercial"
          name="name"
          rules={[{ required: true, message: "Es necesario!" }]}
          id="name"
        >
          <Input
            placeholder="Nombre Comercial"
            value={form.getFieldValue("name")}
          />
        </Form.Item>
        <Form.Item label="Agencia" name="agency" id="agency">
          <Select
            name="agency"
            id="agency"
            onChange={onAgencyChange}
            placeholder="Selecciona la agencia del proyecto"
          >
            {agencies?.map((agency) => {
              return (
                <Option key={agency?.id} id={agency?.id} value={agency?.id}>
                  {agency?.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Cliente" name="client" id="client">
          <Select
            name="client"
            id="client"
            onChange={onClientChange}
            placeholder="Selecciona el cliente del proyecto"
          >
            {clients?.map((client) => {
              return (
                <Option key={client?.id} id={client?.id} value={client?.id}>
                  {client?.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Dominios" name="domains" id="domains">
          <Select
            name="domains"
            id="domains"
            mode="tags"
            placeholder="https://www.ancoradual.com"
          >
            {record?.domains?.map((domain) => {
              return (
                <Option key={domain} id={domain} value={domain}>
                  {domain}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        {record ? (
          <>
            <Form.Item label="Pixel" name="pixel" id="pixel">
              <Input.TextArea
                readOnly={true}
                autoSize={true}
                name="pixel"
                id="pixel"
              ></Input.TextArea>
            </Form.Item>
          </>
        ) : null}
      </Form>
    </Modal>
  );
};

export default ProjectForm;
