// react
import React, { useState, useCallback, useEffect } from "react";
import { Button } from "antd";

import ClientForm from "./ClientForm";

import useUsers from "../../../hooks/useUsers";
import useAgencies from "../../../hooks/useAgencies";

const AddTag = ({ addClient }) => {
  const { agencies, fetchAgencies } = useAgencies();
  const { users, fetchUsers } = useUsers();

  const [visible, setVisible] = useState(false);

  const loadUsersDataOnlyOnce = useCallback(
    (params) => {
      fetchUsers({ ...params, populate: "created_by_user" });
    },
    [fetchUsers]
  );

  const loadAgenciesDataOnlyOnce = useCallback(
    (params) => {
      fetchAgencies({ ...params, populate: "created_by_user" });
    },
    [fetchAgencies]
  );

  useEffect(() => {
    if (visible) {
      loadAgenciesDataOnlyOnce({ limit: 99999 });
    }
  }, [loadAgenciesDataOnlyOnce, visible]);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <ClientForm
        setVisible={setVisible}
        visible={visible}
        addClient={addClient}
        users={users}
        agencies={agencies}
        loadUsersDataOnlyOnce={loadUsersDataOnlyOnce}
      />
      <Button type="primary" onClick={showModal}>
        Añadir Cliente
      </Button>
    </>
  );
};

export default AddTag;
