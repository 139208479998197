const queryString = require('query-string');

export const GetParams = (url = '') => {
  let newUrl = new URL(url)
  return queryString.parse(newUrl.search);
};

export const AddParams = (url = '', params = {}) => {
  let newUrl = url
  for (const [key, value] of Object.entries(params)) {
    let separator = (newUrl.indexOf('?')===-1) ? '?' : '&'
    newUrl += `${separator}${key}=${value}`
  }
  return newUrl;
};
