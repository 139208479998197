import React, { useContext, useEffect, useCallback } from "react";
import { VisitContext } from "../../../contexts/visits/VisitsContext";
import WhatHappeningCards from "./WhatHappeningCards";
import TableVisitsToday from "./Tables/TableVisitsToday";
import TableRealTime from "./Tables/TableRealTime";
import { ProjectsContext } from "../../../contexts/projects/ProjectsContext";
import { InactivityService } from "../../../helpers/inactivity";
import { AuthContext } from "../../../contexts/auth/AuthContext";

const WhatHappening = () => {
  const { logout } = useContext(AuthContext);
  const { selectedProject } = useContext(ProjectsContext);
  const {
    visitState,
    fetchVisitsTodayOffline,
    visitsMongoDBTodayOffline,
    fetchVisitsTodayOnline,
    visitsTodayPagination,
    setVisitsTodayPagination,
  } = useContext(VisitContext);

  const loadVisitsTodayData = useCallback(
    (params = {}) => {
      fetchVisitsTodayOffline({ ...params });
    },
    [fetchVisitsTodayOffline]
  );

  useEffect(() => {
    if (visitState && selectedProject?.pixel_id) {
      loadVisitsTodayData({
        pixel: selectedProject.pixel_id,
        online: false,
      });
    }
  }, [
    fetchVisitsTodayOffline,
    loadVisitsTodayData,
    selectedProject?.pixel_id,
    visitState,
  ]);

  useEffect(() => {
    if (selectedProject?.pixel_id) {
      fetchVisitsTodayOnline({
        pixel: selectedProject?.pixel_id,
        online: true,
      });
    }
  }, [selectedProject?.pixel_id, fetchVisitsTodayOnline]);

  useEffect(() => {
    const inactivity = new InactivityService({
      logout,
      secondsToShowWarning: 3540,
      secondsToDisconnect: 60,
    });
    inactivity.subscribe();

    return () => {
      inactivity.unsubscribe();
    };
  }, [logout]);

  return (
    <>
      <div>
        <WhatHappeningCards
          visitState={visitState}
          visitsDB={visitsMongoDBTodayOffline}
        />

        <TableRealTime
          title={
            <span style={{ fontSize: "24px", margin: "0 0 0 5px" }}>
              ¿Qué está pasando <b>ahora/?</b>
            </span>
          }
          visits={visitState}
        />

        <TableVisitsToday
          title={
            <span style={{ fontSize: "24px", margin: "50px 0 0 0" }}>
              ¿Qué ha pasado <b>hoy</b>?
            </span>
          }
          visitsTodayPagination={visitsTodayPagination}
          setVisitsTodayPagination={setVisitsTodayPagination}
          loadVisitsTodayData={loadVisitsTodayData}
          visits={visitsMongoDBTodayOffline}
        />
      </div>
    </>
  );
};

export default WhatHappening;
