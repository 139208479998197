// react
import React, { useEffect, useState } from "react";

// styles
import { Table, Button, Popconfirm, Divider, Row } from "antd";

// helpers
import { newDate } from "../../../../../helpers/date";
import { sorterByLetters } from "../../../../../helpers/sorter";
import Filter from "../../../../../components/Utils/Table/Filter/Filter";

// components
import UpdateTagType from "./UpdateTagType";
import TableHead from "../../../../Utils/Table/TableHead";
import TableCell from "../../../../Utils/Table/TableCell";

const TagsTypeList = ({
  tagsType,
  getTagsType,
  deleteTagType,
  updateTagType,
}) => {
  const [setFilters] = useState(null);

  useEffect(() => {
    getTagsType();
  }, [getTagsType]);

  const columns = [
    {
      title: "Fecha alta",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => newDate(data).toFormat("dd'-'LL'-'yyyy T"),
      width: "160px",
    },
    {
      title: <TableHead title="Nombre" align="left" />,
      dataIndex: "name",
      render: (data) => <TableCell data={data} />,
      sorter: (a, b) => sorterByLetters(a.name, b.name),
      showSorterTooltip: false,
    },

    {
      title: "Referencia",
      dataIndex: "reference",
      key: "reference",
      render: (record) => {
        return record || 0;
      },
    },

    {
      title: "Total Visitas",
      dataIndex: "count_visits",
      align: "right",
      render: (record) => record || 0,
    },

    {
      title: "Editar",
      key: "editar",
      align: "center",
      width: "10%",
      render: (record) => {
        return <UpdateTagType record={record} updateTagType={updateTagType} />;
      },
    },
    {
      title: "Eliminar",
      key: "eliminar",
      align: "center",
      width: "10%",
      render: (record) => (
        <Popconfirm
          title="¿Estás seguro?"
          okText="Si"
          cancelText="No"
          onConfirm={() => deleteTagType(record?._id || record?.id)}
        >
          <Button type="primary">Eliminar</Button>
        </Popconfirm>
      ),
    },
  ];

  const handleFilter = () => {
    console.log("gooo filter");

    // const params = {
    //   ...filters,
    //   project: selectedProject?.id,
    //   page: pagination.current,
    //   pageSize: pagination.pageSize,
    // };
    // fetchLeads(params);
  };

  const fields = [{ label: "Nombre/Ref", type: "text", key: "usuario" }];

  return (
    <>
      <Row>
        {/* <Row justify="space-between" style={{ minWidth: "100%", alignItems: "center" }}>
          <Filter setFilters={setFilters} fields={fields} handleFilter={handleFilter} />
        </Row> */}

        <Divider style={{ margin: "15px 0" }} />
      </Row>

      <Table
        columns={columns}
        dataSource={tagsType}
        pagination={false}
        size="small"
        bordered={true}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default TagsTypeList;
