import React from "react";

import { Layout } from "antd";
import Navbar from "./Navbar/Navbar";
import SideBar from "./Sidebar/SideBar";

const { Header, Content } = Layout;
// import ClientsSection from "./Clients/ClientsSection";

const AdminLayout = ({
  content_title = "content_title",
  auth,
  logout,
  children,
}) => {
  return (
    <>
      <Navbar title="Administración" auth={auth} logout={logout} />
      <Layout>
        <SideBar />
        <Layout>
          <Header className="ant-page-header">{content_title}</Header>
          <Content
            style={{ height: "100%", padding: "30px", background: "white" }}
          >
            <div className="site-layout-content" style={{ height: "100%" }}>
              {children}
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AdminLayout;
