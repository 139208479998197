import React, { useContext } from "react";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import { ProjectsContext } from "../../../contexts/projects/ProjectsContext";

const SelectProject = () => {
  const { projects, projectSelected } = useContext(ProjectsContext);
  const { slug } = useParams();
  const selectMenu = slug?.toUpperCase();

  const menuItems = projects?.map((project) => {
    return {
      key: project?.name,
      label: (
        <Link
          to={{
            pathname: `/projects/${project.name}`,
          }}
        >
          {project.name.toUpperCase()}
        </Link>
      ),
    };
  });

  const menu = <Menu items={menuItems}></Menu>;

  return (
    <>
      <div className="project-title">Proyectos:</div>
      <div className="project-dropdown">
        <Dropdown overlay={menu} trigger={["click"]}>
          <a
            href={`/projects/${projectSelected?.name}/`}
            style={{ color: "black" }}
          >
            <b> {selectMenu?.toUpperCase()}</b> <DownOutlined />
          </a>
        </Dropdown>
      </div>
    </>
  );
};

export default SelectProject;
