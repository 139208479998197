import axios from "axios";
import { API_BASE_URL } from "../constants/apiContants";
import _ from "lodash";
import { ErrorResponse } from "./errorResponse";
import { refreshAccessToken } from "./refresh-token-fetch";

export const fetchData = async (endpoint, data = {}, method = "GET") => {
  const url = `${API_BASE_URL}${endpoint}`;
  try {
    if (method === "GET") {
      const params = { params: data };
      const response = await axios.get(url, params);
      return _.pick(response, ["data", "status"]);
    } else if (method === "POST") {
      const response = await axios.post(url, data);
      return _.pick(response, ["data", "status"]);
    }
  } catch (error) {
    throw ErrorResponse(
      error,
      error.response?.data?.code,
      error.response?.data?.message
    );
  }
};

export const fetchDataToken = async (
  endpoint,
  data = {},
  method = "GET",
  params = {}
) => {
  const url = `${API_BASE_URL}${endpoint}`;
  const token = await refreshAccessToken();
  // TODO: FIXIAR ESTA LOGICA
  // console.log('token', token)
  try {
    if (method === "GET") {
      if (data) {
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: data,
        });
        return _.pick(response, ["data", "status"]);
      }
    } else if (method === "POST") {
      const response = await axios.post(url, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: params,
      });
      return _.pick(response, ["data", "status"]);
    } else if (method === "DELETE") {
      const response = await axios.delete(url, {
        data: data,
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      return _.pick(response, ["data", "status"]);
    } else if (method === "PUT") {
      const response = await axios.patch(url, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: params,
      });
      return _.pick(response, ["data", "status"]);
    } else if (method === "PATCH") {
      const response = await axios.patch(url, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: params,
      });
      return _.pick(response, ["data", "status"]);
    }
  } catch (error) {
    throw ErrorResponse(
      error,
      error.response?.data?.code,
      error.response?.data?.message
    );
  }
};
