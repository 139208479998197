// react
import React, { useEffect, useState, useCallback } from "react";

// styles
import { Table, Divider, Row, Popconfirm, Button } from "antd";

// helpers
import { newDate } from "../../helpers/date";
import { sorterByLetters } from "../../helpers/sorter";
import Filter from "../Utils/Table/Filter/Filter";

// components
// import UpdateTag from "./UpdateTag";
import TableHead from "../Utils/Table/TableHead";
import TableCell from "../Utils/Table/TableCell.js";
import Popup from "../Table/RowPopup/Popup";
import ShowModal from "../Table/RowPopup/ShowModal";
import UpdateClient from "./form/UpdateClient";
// import TableCell from "../../../Utils/Table/TableCell";
// import TagTotalVisits from "./Utils/TagTotalVisits";

const ClientsList = ({
  clients,
  fetchClients,
  loading,
  clientsPagination,
  setClientsPagination,
  updateClient,
  deleteClient,
}) => {
  const [popup, setPopup] = useState({
    visible: false,
    x: 0,
    y: 0,
    showModal: false,
    option: "",
  });

  const onRow = (record) => {
    return {
      onContextMenu: (event) => {
        event.preventDefault();
        if (!popup.visible) {
          if (!popup.showModal) {
            document.addEventListener(`click`, function onClickOutside() {
              setPopup((prev) => ({ ...prev, visible: false }));
              document.removeEventListener(`click`, onClickOutside);
            });
          }
        }
        setPopup({
          record,
          visible: true,
          x: event.pageX,
          y: event.pageY,
          showModal: false,
        });
      },
    };
  };

  const [filters, setFilters] = useState(null);

  const loadClientsDataOnlyOnce = useCallback(
    (params) => {
      fetchClients({ ...params, populate: "created_by_user,agency" });
    },
    [fetchClients]
  );

  useEffect(() => {
    loadClientsDataOnlyOnce({ page: 1 });
  }, [loadClientsDataOnlyOnce]);

  const columns = [
    {
      title: "Fecha alta",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => newDate(data).toFormat("dd'-'LL'-'yyyy T"),
      width: "160px",
    },
    {
      title: <TableHead title="Nombre" align="left" data="name" />,
      dataIndex: "name",
      render: (data) => <TableCell data={data} />,
      sorter: (a, b) => sorterByLetters(a.name, b.name),
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Razón social" align="left" />,
      dataIndex: "company_name",
      render: (data) => <TableCell data={data} />,
      sorter: (a, b) => sorterByLetters(a.company_name, b.company_name),
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Cif" align="left" />,
      dataIndex: "cif",
      render: (data) => <TableCell data={data} />,
      sorter: (a, b) => sorterByLetters(a.cif, b.cif),
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Teléfono" align="left" />,
      dataIndex: "phone",
      render: (data) => <TableCell data={data} />,
      sorter: (a, b) => sorterByLetters(a.phone, b.phone),
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Agencia" align="right" />,
      dataIndex: "agency",
      key: "agency",
      render: (data) => {
        return <TableCell data={data?.name || data} />;
      },
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Proyectos vinculados" align="right" />,
      dataIndex: "projects",
      key: "projects",
      render: (data) => <TableCell data={data.length || 0} />,
      sorter: (a, b) => sorterByLetters(a.length, b.length),
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Usuarios vinculados" align="right" />,
      dataIndex: "projects",
      key: "projects",
      render: (data) => <TableCell data={data?.users?.length || 0} />,
      sorter: (a, b) => sorterByLetters(a.users?.length, b.users?.length),
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Creador" align="left" />,
      dataIndex: "created_by_user",
      render: (data) => <TableCell data={data?.email} />,
      sorter: (a, b) => sorterByLetters(a?.email, b?.email),
      showSorterTooltip: false,
    },
    {
      title: "Editar",
      key: "editar",
      align: "center",
      width: "10%",
      render: (_, record) => (
        <UpdateClient record={record} updateClient={updateClient} />
      ),
    },
    {
      title: "Eliminar",
      key: "eliminar",
      align: "center",
      width: "10%",
      render: (_, record) => (
        <Popconfirm
          title={
            <>
              Al eliminar se borrarán todos los datos relacionados con el
              cliente <br /> ¿Estás seguro?
            </>
          }
          okText="Si"
          cancelText="No"
          onConfirm={() => deleteClient(record?.id)}
        >
          <Button type="primary">Eliminar</Button>
        </Popconfirm>
      ),
    },
  ];

  const pageChangeHandler = (pagination, filters, sorter) => {
    // console.log("pagination", pagination);
    // console.log("filters", filters);
    // console.log("sorter", sorter);
    setClientsPagination((prev) => ({ ...prev, current: pagination.current }));
    loadClientsDataOnlyOnce({
      ...filters,
      ...sorter,
      page: pagination.current,
    });
  };

  const handleFilter = () => {
    const params = {
      ...filters,
      page: clientsPagination?.current || 1,
      limit: clientsPagination?.pageSize || 10,
    };

    loadClientsDataOnlyOnce(params);
  };

  const fields = [{ label: "Nombre/Ref", type: "text", key: "name" }];

  return (
    <>
      <Row>
        <Row
          justify="space-between"
          style={{ minWidth: "100%", alignItems: "center" }}
        >
          <Filter
            setFilters={setFilters}
            fields={fields}
            handleFilter={handleFilter}
          />
        </Row>

        <Divider style={{ margin: "15px 0" }} />
      </Row>

      <Table
        columns={columns}
        dataSource={clients}
        pagination={clientsPagination}
        size="small"
        bordered={true}
        rowKey={(record) => record?.id}
        loading={loading}
        onRow={onRow}
        onChange={pageChangeHandler}
      />
      <Popup
        record={popup.record}
        visible={popup.visible}
        x={popup.x}
        y={popup.y}
        setPopup={setPopup}
        RT={false}
      />
      <ShowModal
        show={popup.showModal}
        setPopup={setPopup}
        popup={popup}
        record={popup.record}
      />
    </>
  );
};

export default ClientsList;
