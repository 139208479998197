import React, { useState } from "react";

import { deviceType } from "../../../../helpers/deviceType";
import { isValidUrl } from "../../../../helpers/url";
import {
  formatFromMillisToTimeSession,
  newDate,
} from "../../../../helpers/date";

import { Button, Tag, Row, Tooltip, Table } from "antd";
import {
  BellOutlined,
  ClockCircleOutlined,
  FieldTimeOutlined,
  FileSearchOutlined,
  FlagOutlined,
  GlobalOutlined,
  LaptopOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
  TrophyOutlined,
  UndoOutlined,
  UserOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import Popup from "../../../Utils/Table/RowPopup/Popup";
import ShowModal from "../../../Utils/Table/RowPopup/ShowModal";
import {
  sorterByDate,
  sorterByNumbers,
  sorterByLetters,
} from "../../../../helpers/sorter";
import AvatarProfile from "../../../Profile/AvatarProfile";

function TableVisitsToday({
  title,
  visits,
  visitsTodayPagination,
  setVisitsTodayPagination,
  loadVisitsTodayData,
}) {
  const [popup, setPopup] = useState({
    visible: false,
    x: 0,
    y: 0,
    showModal: false,
    option: "",
  });

  const onRow = (record) => {
    return {
      onContextMenu: (event) => {
        event.preventDefault();
        if (!popup.visible) {
          if (!popup.showModal) {
            document.addEventListener(`click`, function onClickOutside() {
              setPopup((prev) => ({ ...prev, visible: false }));
              document.removeEventListener(`click`, onClickOutside);
            });
          }
        }
        setPopup({
          record,
          visible: true,
          x: event.pageX,
          y: event.pageY,
          showModal: false,
        });
      },
    };
  };

  const pageChangeHandler = (pagination) => {
    // console.log("pagination", pagination);
    setVisitsTodayPagination((prev) => ({
      ...prev,
      current: pagination.current,
    }));
    loadVisitsTodayData({
      page: pagination.current,
    });
  };

  const columns = [
    {
      title: (
        <Tooltip
          placement="top"
          title="Tipo de dispositivo"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <LaptopOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      ),
      key: "device_type",
      dataIndex: "device_type",
      ellipsis: true,
      showSorterTooltip: false,
      align: "center",
      width: "3%",
      render: (data, record) => (
        <Tooltip placement="top" title="">
          {deviceType(record.bot, data)}
        </Tooltip>
      ),
      fixed: "left",
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Usuario"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <UserOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      ),
      key: "visitor",
      dataIndex: "visitor",
      ellipsis: true,
      align: "left",
      showSorterTooltip: false,
      width: "5%",
      render: (data) => (
        <Tooltip
          placement="top"
          title={
            data.name
              ? data.name
              : `El id único del usuario es: ${data?.id?.slice(-6)}`
          }
        >
          {data.name ? data.name : `#${data?.id?.slice(-6)} `}
        </Tooltip>
      ),
      sorter: (a, b) =>
        sorterByLetters(
          `#${a.visitor?.id?.slice(-6)}`,
          `#${b.visitor?.id?.slice(-6)}`
        ),
      fixed: "left",
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Usuario captador"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <UserOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      ),
      key: "usuario_captador",
      dataIndex: "visitor.user_catcher",
      ellipsis: true,
      align: "center",
      showSorterTooltip: true,
      width: "60px",
      render: (_, record) =>
        record.visitor.user_catcher?.name ? (
          <AvatarProfile user={record.visitor.user_catcher} />
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        const aShort = a.visitor.user_catcher?.name || "-";
        const bShort = b.visitor.user_catcher?.name || "-";
        return sorterByLetters(aShort, bShort);
      },
      fixed: "center",
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Procedencia"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <VerticalAlignBottomOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      ),
      key: "url_origin",
      dataIndex: "url_origin",
      ellipsis: true,
      align: "left",
      width: "auto",
      showSorterTooltip: false,
      sorter: (a, b) => sorterByLetters(a.url_origin, b.url_origin),
      render: (data) => (
        <Tooltip placement="top" title={data ? isValidUrl(data) : "Búsqueda"}>
          {data ? isValidUrl(data) : "Búsqueda"}
        </Tooltip>
      ),
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Localización"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FlagOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      ),
      key: "geolocation",
      dataIndex: "geolocation",
      ellipsis: true,
      align: "left",
      showSorterTooltip: false,
      render: (data, record) => (
        <Tooltip
          placement="topLeft"
          title={
            record.city && record.country
              ? `${record.city}, ${record.country}`
              : "No disponible"
          }
        >
          {record.city && record.country ? (
            <div>
              {/* <img
                src={`https://www.countryflags.io/${record.geolocation.country_code.toLowerCase()}/flat/16.png`}
                alt={record.country}
              /> */}
              <span style={{ marginLeft: "1px" }}>
                {record.geolocation.country_code}
              </span>
            </div>
          ) : (
            <QuestionCircleOutlined style={{ fontSize: "20px" }} />
          )}
        </Tooltip>
      ),
      sorter: (a, b) => {
        const localizationA = `${a.country}, ${a.city}`;
        const localizationB = `${b.country}, ${b.city}`;
        return sorterByLetters(localizationA, localizationB);
      },
      width: "4%",
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Navegador"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <GlobalOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      ),
      key: "browser",
      dataIndex: "browser",
      ellipsis: true,
      align: "left",
      showSorterTooltip: false,
      width: "7%",
      render: (data) =>
        data ? data : <QuestionCircleOutlined style={{ fontSize: "20px" }} />,
      sorter: (a, b) => sorterByLetters(a.browser, b.browser),
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Hora de llegada"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ClockCircleOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      ),
      key: "createdAt",
      dataIndex: "createdAt",
      ellipsis: true,
      showSorterTooltip: false,
      align: "center",
      width: "5%",
      render: (data) => (
        <Tooltip
          placement="topRight"
          title={`El usuario ha visitado a las ${newDate(data).toFormat("T")}`}
        >
          <div style={{ textAlign: "end" }}>
            <span>{newDate(data).toFormat("T")}</span>
          </div>
        </Tooltip>
      ),
      sorter: (a, b) => sorterByDate(a.createdAt, b.createdAt),
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Tiempo de sesión"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FieldTimeOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      ),
      key: "time_session",
      dataIndex: "time_session",
      ellipsis: true,
      showSorterTooltip: false,
      align: "right",
      width: "6%",
      render: (data) =>
        Number.isInteger(data) ? (
          <Tooltip
            placement="topRight"
            title={
              <span>
                {`Tiempo activo del usuario:
              ${formatFromMillisToTimeSession(data)}`}
              </span>
            }
          >
            {formatFromMillisToTimeSession(data)}
          </Tooltip>
        ) : (
          "No disponible"
        ),
      sorter: (a, b) => sorterByNumbers(a.time_session, b.time_session),
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Recurrencia"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <UndoOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      ),
      key: "recurrence",
      dataIndex: "recurrence",
      ellipsis: true,
      showSorterTooltip: false,
      align: "right",
      render: (data) => <>{data > 1 ? `${data} veces` : `${data} vez`}</>,
      sorter: (a, b) => sorterByNumbers(b.recurrence, a.recurrence),
      width: "5%",
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Páginas vistas"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FileSearchOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      ),
      key: "pages",
      dataIndex: "pages",
      ellipsis: true,
      showSorterTooltip: false,
      align: "right",
      render: (data) => (data > 1 ? `${data} páginas` : `${data} página`),
      // sorter: (a, b) => sorterByNumbers(a.pages, b.historial_navegacion),
      width: "6%",
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Página actual"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FileSearchOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      ),
      key: "url_current",
      dataIndex: "url_current",
      ellipsis: true,
      align: "left",
      width: "auto",
      showSorterTooltip: false,
      render: (data) => (
        <Tooltip
          placement="topLeft"
          title={
            new URL(data).pathname.length > 20 ? (
              <a target="_blank" rel="noreferrer" href={data}>
                {data}
              </a>
            ) : (
              ""
            )
          }
        >
          <a target="_blank" rel="noreferrer" href={data}>
            {new URL(data).pathname === "/" ? "Home" : new URL(data).pathname}
          </a>
        </Tooltip>
      ),
      sorter: (a, b) => sorterByLetters(a.url_current, b.url_current),
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Tags"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TrophyOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      ),
      key: "tags",
      dataIndex: "tags",
      ellipsis: true,
      showSorterTooltip: false,
      align: "right",
      // sorter: (a, b) => sorterByNumbers(a.tags, b.tags),
      render: (data, record) => (
        <>
          {Object.values(record?.tags || {})?.map((t, i) => {
            if (i <= 3) {
              return (
                <Tooltip
                  placement="topLeft"
                  key={`${t.reference}-${t.count || 1}`}
                  title={t.reference ? `${t.reference}` : "N/R"}
                >
                  <Tag key={`${t.reference}-${t.count || 1}`} color="green">
                    {t.count || 1} {t.reference}
                  </Tag>
                </Tooltip>
              );
            }
            return <></>;
          })}
        </>
      ),
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Campaña"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <BellOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      ),
      key: "campaign",
      dataIndex: "campaign",
      ellipsis: true,
      showSorterTooltip: false,
      align: "left",
      sorter: (a, b) => sorterByLetters(a.campaign.name, b.campaign.name),
      width: "45px",
      render: (data) => {
        return (
          <Tooltip title={data?.name || " Sin campaña"}>
            {data?.name ? (
              <BellOutlined style={{ fontSize: "20px" }} />
            ) : (
              <BellOutlined style={{ fontSize: "20px", color: "lightgray" }} />
            )}
          </Tooltip>
        );
      },
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Charla proactiva"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <MessageOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      ),
      key: "chatting",
      dataIndex: "chatting",
      ellipsis: true,
      width: "auto",
      showSorterTooltip: false,
      align: "center",
      sorter: false,
      render: () => (
        <Button type="primary" disabled={true}>
          Charla
        </Button>
      ),
      fixed: "right",
      // mirror: (
      //   <Button type="primary" disabled={true}>
      //     Mirror
      //   </Button>
      // ),
    },
  ];

  return (
    <div style={{ margin: "15px 0" }}>
      <Row style={{ margin: "15px 0" }}>
        {title}
        {/* <Divider style={{ margin: "15px 0" }} /> */}
      </Row>
      <Table
        columns={columns}
        dataSource={visits?.visits || visits}
        rowKey={(record) => record?.id}
        // loading={loading}
        onRow={onRow}
        pagination={visitsTodayPagination}
        onChange={pageChangeHandler}
        scroll={{ x: 1500 }}
        bordered={true}
        locale={{
          triggerAsc: "Ordenar ascendentemente",
          triggerDesc: "Ordenar descendenmente",
          cancelSort: "No ordenar",
        }}
        size="small"
      />
      <Popup
        record={popup.record}
        visible={popup.visible}
        x={popup.x}
        y={popup.y}
        setPopup={setPopup}
        RT={false}
      />
      <ShowModal
        show={popup.showModal}
        setPopup={setPopup}
        popup={popup}
        record={popup.record}
      />
    </div>
  );
}

export default TableVisitsToday;
