// react
import React, { useState, useEffect, useCallback } from "react";
import { Button } from "antd";

import UserForm from "./UserForm";

import useAgencies from "../../../hooks/useAgencies";
import useClients from "../../../hooks/useClients";
import useProjects from "../../../hooks/useProjects";

const AddUser = ({ addUser }) => {
  const [visible, setVisible] = useState(false);

  const { agencies, fetchAgencies } = useAgencies();
  const { clients, fetchClients } = useClients();
  const { projects, fetchProjects } = useProjects();

  const loadAgenciesDataOnlyOnce = useCallback(
    (params) => {
      fetchAgencies({ ...params, populate: "created_by_user" });
    },
    [fetchAgencies]
  );

  const loadClientsDataOnlyOnce = useCallback(
    (params) => {
      fetchClients({ ...params, populate: "created_by_user" });
    },
    [fetchClients]
  );

  const loadProjectsDataOnlyOnce = useCallback(
    (params) => {
      fetchProjects({ ...params, populate: "created_by_user" });
    },
    [fetchProjects]
  );

  useEffect(() => {
    if (visible) {
      loadAgenciesDataOnlyOnce({ limit: 99999 });
    }
  }, [loadAgenciesDataOnlyOnce, visible]);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Añadir Usuario
      </Button>

      <UserForm
        setVisible={setVisible}
        visible={visible}
        addUser={addUser}
        agencies={agencies}
        clients={clients}
        projects={projects}
        loadClientsDataOnlyOnce={loadClientsDataOnlyOnce}
        loadProjectsDataOnlyOnce={loadProjectsDataOnlyOnce}
      />
    </>
  );
};

export default AddUser;
