// react
import React, { memo } from "react";

// styles
import { Avatar } from "antd";
import AvatarProfile from "../../Profile/AvatarProfile";
import "./managerOnline.css";

const ManagersOnline = memo(({ users }) => {
  return users.length ? (
    <div style={{ display: "flex" }}>
      <Avatar.Group
        maxCount={4}
        maxStyle={{
          color: "white",
          backgroundColor: "#0188d1",
        }}
      >
        {users?.map((u) => (
          <AvatarProfile user={u} key={u?.id} />
        ))}
        {/* <AvatarProfile user={selfUser} key={selfUser?.id} profile={true} /> */}
      </Avatar.Group>
    </div>
  ) : (
    <></>
  );
});

export default ManagersOnline;
