// react
import React, { useContext, memo, useEffect } from "react";

// auth
import { AuthContext } from "../../../contexts/auth/AuthContext";

// helpers
import { GetParams } from "../../../helpers/ParamsUrl";

// styles
import { Spin, Row, Col, Layout, Typography } from "antd";
const { Title } = Typography;

const IntegrationPage = memo(() => {
  console.log("IntegrationPage");
  const { Content } = Layout;
  const { integrationLogin, auth } = useContext(AuthContext);

  useEffect(() => {
    const externalAuth = async () => {
      try {
        let _tokens = Object.assign(GetParams(window.location), {});
        console.log("_tokens", _tokens);
        // gc_token = token del cliente
        // gc_user = user del cliente
        if (_tokens?.gc_token && _tokens?.gc_user) {
          return integrationLogin(_tokens);
        }
      } catch (error) {
        console.error("externalAuth - error", error);
        return error;
      }
    };
    if (!auth.logged) {
      externalAuth();
    } else {
      // TODO: "TEST LOGGED... REDIRECT???
    }
  }, [auth?.logged, integrationLogin]);

  return (
    <>
      <Layout>
        <Content>
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ minHeight: "100vh" }}
          >
            {auth.logged ? (
              <Col>
                <Spin size="large" />
              </Col>
            ) : (
              <Title type="danger" level={1}>
                Unauthorized
              </Title>
            )}
          </Row>
        </Content>
      </Layout>
    </>
  );
});
export default IntegrationPage;
