import React, { useContext } from "react";
import { Switch, useRouteMatch, Redirect } from "react-router-dom";
import AgencyDashboardPage from "./AgencyDashboardPage";
import AgencyPrivateRoute from "../../router/agency/AgencyPrivateRoute";

import { AuthContext } from "../../contexts/auth/AuthContext";

// misc
import Loading from "../../components/Utils/Loading/Loading";

import ClientPage from "./Clients/ClientPage";
import ProjectPage from "./Projects/ProjectPage";
import UserPage from "./Users/UserPage";
import WebhookPage from "./Webhooks/WebhookPage";

const AgencyRouter = () => {
  const { auth, logout } = useContext(AuthContext);
  const { url } = useRouteMatch();
  const role = auth?.user?.role || "client_agent";

  if (auth.logged === null) {
    return <Loading />;
  }

  if (!auth?.is_admin && role !== "agency_admin") {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <Switch>
      <AgencyPrivateRoute path={`${url}/clients`}>
        <ClientPage auth={auth} logout={logout} />
      </AgencyPrivateRoute>
      <AgencyPrivateRoute path={`${url}/projects`}>
        <ProjectPage auth={auth} logout={logout} />
      </AgencyPrivateRoute>
      <AgencyPrivateRoute path={`${url}/users`}>
        <UserPage auth={auth} logout={logout} />
      </AgencyPrivateRoute>
      <AgencyPrivateRoute path={`${url}/webhooks`}>
        <WebhookPage auth={auth} logout={logout} />
      </AgencyPrivateRoute>
      <AgencyPrivateRoute path={`${url}`}>
        <AgencyDashboardPage auth={auth} logout={logout} />
      </AgencyPrivateRoute>
    </Switch>
  );
};

export default AgencyRouter;
