import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Switch } from "antd";
import "./WebhookForm.css";
import { errorNotification } from "../../Utils/Notification/Notification";

const WebhookForm = ({ visible, setVisible, updateWebhook, record }) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalTitle, setmodalTitle] = useState("Editar un Webhook");

  const handleOk = async () => {
    try {
      setConfirmLoading(true);

      const data = await form.validateFields();
      const populate = "agency";
      if (record) {
        const dataSend = {
          webhook_create_lead: {
            url: data.webhook_create_lead_url,
            endpoint: data.webhook_create_lead_endpoint,
            token: data.webhook_create_lead_token,
            is_active: data.webhook_create_lead_is_active,
            action: data.webhook_create_lead_action,
          },
        };
        await updateWebhook(record?.id, dataSend, { populate });
      }

      setVisible(false);
      setConfirmLoading(false);
      form.resetFields();
    } catch (error) {
      console.error(error);
      errorNotification("ERROR", error.message);
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (record) {
      setmodalTitle(
        `Editar Webhook Agencia: ${record.agency?.name || record.agency}`
      );
      form.setFieldsValue({
        webhook_create_lead_is_active:
          (record.webhook_create_lead?.is_active === "true" ||
          record.webhook_create_lead?.is_active === true
            ? true
            : false) || false,
        webhook_create_lead_url: record.webhook_create_lead?.url || "",
        webhook_create_lead_endpoint: record.webhook_create_lead?.endpoint || "",
        webhook_create_lead_token: record.webhook_create_lead?.token || "",
        webhook_create_lead_action: record.webhook_create_lead?.action || "",
      });
    }
  }, [form, record]);

  const buildFields = (_record) => {
    return (
      <>
        <Form.Item
          label="Activo"
          name="webhook_create_lead_is_active"
          id="webhook_create_lead_is_active"
          valuePropName="checked"
          checked={form.getFieldValue("webhook_create_lead_is_active")}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Url"
          name="webhook_create_lead_url"
          id="webhook_create_lead_url"
        >
          <Input
            placeholder="default"
            value={form.getFieldValue("webhook_create_lead_url")}
          />
        </Form.Item>
        <Form.Item
          label="Endpoint"
          name="webhook_create_lead_endpoint"
          id="webhook_create_lead_endpoint"
        >
          <Input
            placeholder="default"
            value={form.getFieldValue("webhook_create_lead_endpoint")}
          />
        </Form.Item>
        <Form.Item
          label="Token"
          name="webhook_create_lead_token"
          id="webhook_create_lead_token"
        >
          <Input
            placeholder="default"
            value={form.getFieldValue("webhook_create_lead_token")}
          />
        </Form.Item>
        <Form.Item
          label="Acción"
          name="webhook_create_lead_action"
          id="webhook_create_lead_action"
        >
          <Input
            placeholder="default"
            value={form.getFieldValue("webhook_create_lead_action")}
          />
        </Form.Item>
      </>
    );
  };

  return (
    <Modal
      forceRender
      getContainer={false}
      title={modalTitle}
      visible={visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      okText="Guardar"
      cancelText="Cancelar"
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        onFinish={handleOk}
        onFinishFailed={() => {
          console.error("form failed");
        }}
        initialValues={record}
      >
        {buildFields(record)}
      </Form>
    </Modal>
  );
};

export default WebhookForm;
