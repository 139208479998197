import React from "react";
import { Card, Typography } from "antd";
import PropTypes from "prop-types";
import { UserAddOutlined } from "@ant-design/icons";

const StatisticWidget = ({ title, value, status, subtitle, prefix }) => {
  const { Title } = Typography;
  return (
    <Card
      style={{
        width: "208px",
        height: "150px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
      }}
      bodyStyle={{}}
    >
      {title && (
        <h4 className="mb-0 font-weight-normal">
          {title}
        </h4>
      )}

      <div className="card-content">
        <div
          style={{ width: "20%" }}
          className={`${prefix ? "d-flex" : ""}`}
        >
          <div>
            <div
              style={{
                height: "50px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="d-flex align-items-center"
            >
              <span className={`card-content-icon font-size-xl ml-3`}>
                <UserAddOutlined />
              </span>
            </div>
          </div>
        </div>

        <div
          style={{ width: "80%" }}
          className={`${prefix ? "d-flex" : ""}`}
        >
          {prefix ? <div className="mr-2">{prefix}</div> : null}
          <div>
            <div
              style={{
                height: "50px",
                width: "100%",
                justifyContent: "right",
                alignItems: "right",
              }}
              className="d-flex align-items-right"
            >
              <Title style={{ fontSize: "40px" }} className="mb-0 font-weight-normal">
                {value}
              </Title>
              {/* {status ? (
              <span
                className={`font-size-md font-weight-bold ml-3 ${
                  status !== 0 && status > 0 ? "text-success" : "text-danger"
                }`}
              >
                {status}
                {status !== 0 && status > 0 ? (
                  <ArrowUpOutlined />
                ) : (
                  <ArrowDownOutlined />
                )}
              </span>
            ) : null} */}
            </div>
            {subtitle && <div className="text-gray-light mt-1">{subtitle}</div>}
          </div>
        </div>
      </div>
    </Card>
  );
};

StatisticWidget.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.string,
  subtitle: PropTypes.string,
  status: PropTypes.number,
  prefix: PropTypes.element,
};

export default StatisticWidget;
