import React, { memo } from "react";
import { withRouter, Link } from "react-router-dom";
import { Button, Row } from "antd";

const Home = memo(() => {
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "80vh" }}
    >
      <Button size="large" type="primary">
        <Link to="/login">Login</Link>
      </Button>
    </Row>
  );
});

export default withRouter(Home);
