// react
import React, { useEffect, useState, useCallback } from "react";

// styles
import { Table, Divider, Row, Popconfirm, Button } from "antd";

// helpers
import { newDate } from "../../helpers/date";
import { sorterByLetters } from "../../helpers/sorter";
import Filter from "../../components/Utils/Table/Filter/Filter";

// components
// import UpdateTag from "./UpdateTag";
import TableHead from "../../components/Utils/Table/TableHead";
import TableCell from "../../components/Utils/Table/TableCell.js";
import Popup from "../Table/RowPopup/Popup";
import ShowModal from "../Table/RowPopup/ShowModal";
import UpdateAgency from "./form/UpdateAgency";
// import TableCell from "../../../Utils/Table/TableCell";
// import TagTotalVisits from "./Utils/TagTotalVisits";

const AgenciesList = ({
  agencies,
  fetchAgencies,
  loading,
  agenciesPagination,
  setAgenciesPagination,
  updateAgency,
  deleteAgency,
}) => {
  const [popup, setPopup] = useState({
    visible: false,
    x: 0,
    y: 0,
    showModal: false,
    option: "",
  });

  const onRow = (record) => {
    return {
      onContextMenu: (event) => {
        event.preventDefault();
        if (!popup.visible) {
          if (!popup.showModal) {
            document.addEventListener(`click`, function onClickOutside() {
              setPopup((prev) => ({ ...prev, visible: false }));
              document.removeEventListener(`click`, onClickOutside);
            });
          }
        }
        setPopup({
          record,
          visible: true,
          x: event.pageX,
          y: event.pageY,
          showModal: false,
        });
      },
    };
  };

  const [filters, setFilters] = useState(null);

  const loadAgenciesDataOnlyOnce = useCallback(
    (params) => {
      fetchAgencies({
        ...params,
        populate: "created_by_user,clients,clients.projects",
      });
    },
    [fetchAgencies]
  );

  useEffect(() => {
    loadAgenciesDataOnlyOnce({ page: 1 });
  }, [loadAgenciesDataOnlyOnce]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (data) => data,
      width: "100px",
    },
    {
      title: "Fecha alta",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => newDate(data).toFormat("dd'-'LL'-'yyyy T"),
      width: "160px",
    },
    {
      title: <TableHead title="Nombre" align="left" data="Nombre" />,
      dataIndex: "name",
      key: "name",
      render: (data) => <TableCell data={data} />,
      sorter: (a, b) => sorterByLetters(a.name, b.name),
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Clientes vinculados" align="right" />,
      dataIndex: "clients",
      key: "clients",
      render: (data) => {
        return <TableCell data={data?.length || 0} />;
      },
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Proyectos vinculados" align="right" />,
      key: "projects",
      render: (_, record) => {
        const projects = record.clients
          .map((client) => {
            return client?.projects?.length;
          })
          .reduce((a, b) => a + b, 0);
        return <TableCell data={projects || 0} />;
      },
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Usuarios vinculados" align="right" />,
      key: "users",
      render: (_, record) => {
        const users = record.clients
          .map((client) => {
            return client?.projects
              ?.map((project) => {
                return project?.users.length;
              })
              .reduce((a, b) => a + b, 0);
          })
          .reduce((a, b) => a + b, 0);
        return <TableCell data={users || 0} />;
      },
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Creador" align="left" />,
      dataIndex: "created_by_user",
      key: "created_by_user",
      render: (data) => <TableCell data={data?.email} />,
      sorter: (a, b) => sorterByLetters(a?.email, b?.email),
      showSorterTooltip: false,
    },
    {
      title: "Editar",
      key: "editar",
      align: "center",
      width: "10%",
      render: (_, record) => (
        <UpdateAgency record={record} updateAgency={updateAgency} />
      ),
    },
    {
      title: "Eliminar",
      key: "eliminar",
      align: "center",
      width: "10%",
      render: (_, record) => (
        <Popconfirm
          title={
            <>
              Al eliminar se borrarán todos los datos relacionados con la
              agencia <br /> ¿Estás seguro?
            </>
          }
          okText="Si"
          cancelText="No"
          onConfirm={() => deleteAgency(record?.id)}
        >
          <Button type="primary">Eliminar</Button>
        </Popconfirm>
      ),
    },
  ];

  const pageChangeHandler = (pagination, filters, sorter) => {
    // console.log("pagination", pagination);
    // console.log("filters", filters);
    // console.log("sorter", sorter);
    setAgenciesPagination((prev) => ({ ...prev, current: pagination.current }));
    loadAgenciesDataOnlyOnce({
      ...filters,
      ...sorter,
      page: pagination.current,
    });
  };

  const handleFilter = () => {
    // console.log("------ handleFilter - filters", filters);
    const params = {
      ...filters,
      page: agenciesPagination?.current || 1,
      limit: agenciesPagination?.pageSize || 10,
    };
    // console.log("------ handleFilter - params", params);

    loadAgenciesDataOnlyOnce(params);
  };

  const fields = [{ label: "Nombre/Ref", type: "text", key: "name" }];

  return (
    <>
      <Row>
        <Row
          justify="space-between"
          style={{ minWidth: "100%", alignItems: "center" }}
        >
          <Filter
            setFilters={setFilters}
            fields={fields}
            handleFilter={handleFilter}
          />
        </Row>

        <Divider style={{ margin: "15px 0" }} />
      </Row>

      <Table
        rowKey={(record) => record?.id}
        columns={columns}
        dataSource={agencies}
        pagination={agenciesPagination}
        size="small"
        bordered={true}
        loading={loading}
        onRow={onRow}
        onChange={pageChangeHandler}
      />
      <Popup
        record={popup.record}
        visible={popup.visible}
        x={popup.x}
        y={popup.y}
        setPopup={setPopup}
        RT={false}
      />
      <ShowModal
        show={popup.showModal}
        setPopup={setPopup}
        popup={popup}
        record={popup.record}
      />
    </>
  );
};

export default AgenciesList;
