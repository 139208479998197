// resact
import React, { useContext, useEffect, /* useMemo, */ useState } from "react";

// styles
import { Row } from "antd";

import useVisitants from "../../../hooks/useVisitants";
import TableLeads from "./Table/TableLeads";
import { ProjectsContext } from "../../../contexts/projects/ProjectsContext";
import Filter from "../../Utils/Table/Filter/Filter";

const WorkMyLeads = () => {

  const { selectedProject } = useContext(ProjectsContext);
  const { leads, fetchLeads, setLeads, deleteLead} = useVisitants();
  const [ filters, setFilters ] = useState(null);

  const [ pagination, setPagination ] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} leads`,
    showSizeChanger: true,
    position: ['bottomCenter']
  });

  const handleTableChange = (onChangePagination) => {

    if (onChangePagination.current !== pagination.current || onChangePagination.pageSize !== pagination.pageSize) {

      setPagination((prev) => ({ ...prev, ...onChangePagination }));

      const params = {
        ...filters,
        project: selectedProject?.id,
        page: onChangePagination.current,
        pageSize: onChangePagination.pageSize,
      };

      fetchLeads(params);

    }

  };

  const handleFilter = () => {
    const params = {
      ...filters,
      project: selectedProject?.id,
      page: pagination.current,
      pageSize: pagination.pageSize,
    };
    fetchLeads(params);
  };

  useEffect(() => {
    if (selectedProject?.id && pagination) {
      const params = { project: selectedProject?.id, page: pagination.current, pageSize: pagination.pageSize };
      fetchLeads(params);
    }
    return () => {
      setLeads({ visits: [], count: 0 });
    };
    // eslint-disable-next-line
  }, [selectedProject?.id]);

  useEffect(() => {
    if (leads) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: leads.count,
      }));
    }
  }, [leads]);

  // const data = useMemo(
  //   () => [
  //     { title: "Total leads", key: "totalLeads", value: leads?.count },
  //     // { title: "Total páginas vistas", key: "totalPaginasVistas" },
  //   ],
  //   [leads]
  // );

  const fields = [
    // { label: "Nombre", type: "text" },
    // { label: "Primer apellido", type: "text" },
    // { label: "Segundo apellido", type: "text" },
    // { label: "Email", type: "text" },
    { label: "Gestor", type: "text", key: "usuario_captador" },
  ];

  return (
    <>
      {/* <Cards dataSource={data} /> */}
      <TableLeads
        title={
          <>
            <Row justify="space-between" style={{ minWidth: "100%", alignItems: "center" }}>
            <span style={{ fontSize: "24px", margin: " 0 0 10px 5px" }}>
                <strong>Leads</strong> obtenidos hasta hoy
              </span>
            </Row>
            <Row justify="space-between" style={{ minWidth: "100%", alignItems: "center" }}>
              <Filter setFilters={setFilters} fields={fields} handleFilter={handleFilter} />
            </Row>
          </>
        }

        leads={leads?.leads}
        pagination={pagination}
        handleTableChange={handleTableChange}
        deleteLead={deleteLead}
      />
    </>
  );
};

export default WorkMyLeads;
