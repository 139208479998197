import React, { useState, useCallback, useEffect } from "react";
import { Button } from "antd";

import useWebhooks from "../../../hooks/useWebhooks";

import WebhookForm from "./WebhookForm";

const UpdateWebhook = ({ updateWebhook, record }) => {
  const [visible, setVisible] = useState(false);
  const { webhooks } = useWebhooks();

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Editar
      </Button>

      <WebhookForm
        setVisible={setVisible}
        visible={visible}
        updateWebhook={updateWebhook}
        record={record}
        webhooks={webhooks}
      />
    </>
  );
};

export default UpdateWebhook;
