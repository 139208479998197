import React, { createContext } from "react";
import useProjects from "../../hooks/useProjects";

export const ProjectsContext = createContext("");

export const ProjectsProvider = ({ children }) => {

  const {
    projects,
    fetchProjects,
    addProject,
    deleteProject,
    selectProject,
    deselectProject,
    selectedProject,
    resetPixelTokenProject,
  } = useProjects();

  return (

    <ProjectsContext.Provider
      value={{
        projects,
        fetchProjects,
        addProject,
        deleteProject,
        selectProject,
        deselectProject,
        selectedProject,
        resetPixelTokenProject,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};
