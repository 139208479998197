// react
import React from "react";

import WebhooksList from "./WebhooksList";
import useWebhooks from "../../hooks/useWebhooks";

export default function WebhooksSection() {
  const {
    webhooks,
    fetchWebhooks,
    loading,
    updateWebhook,
    paginationWebhooks,
  } = useWebhooks();

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontSize: "24px", margin: " 0 0 10px 5px" }}>
          <strong>Gestión</strong> de Webhooks
        </span>
      </div>

      <WebhooksList
        webhooks={webhooks}
        paginationWebhooks={paginationWebhooks}
        fetchWebhooks={fetchWebhooks}
        updateWebhook={updateWebhook}
        loading={loading}
      />
    </>
  );
}
