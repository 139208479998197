const _ = require("lodash");

export const usersActions = {
  LOAD_USERS_ONLINE: "LOAD_USERS_ONLINE",
  CLEAN_USERS_ONLINE: "CLEAN_USERS_ONLINE",
  ADD_USER_ONLINE: "ADD_USER_ONLINE",
  UPDATE_USER_ONLINE: "UPDATE_USER_ONLINE",
  DELETE_USER_ONLINE: "DELETE_USER_ONLINE",
};

export const usersReducer = (state, action) => {
  switch (action.type) {
    case usersActions.LOAD_USERS_ONLINE:
      return [...action.payload];
    case usersActions.CLEAN_USERS_ONLINE:
      return [];
    case usersActions.ADD_USER_ONLINE:
      const id = action.payload.id;
      if (!_.find(state, {id})) {
        return [...state, action.payload];
      } else {
        return state;
      }
    case usersActions.UPDATE_USER_ONLINE:
      return state.map((u) => {
        if (u.id === action.payload.id) {
          return action.payload;
        }
        return u;
      });
    case usersActions.DELETE_USER_ONLINE:
      const users = state.filter((u) => u.id !== action.payload.id);
      return users;

    default:
      throw new Error();
  }
};
