import React, { useState } from "react";
import { Button } from "antd";
import UpdateChannelForm from "./UpdateChannelForm";

const UpdateChannel = ({ updateChannel, record }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Editar
      </Button>

      <UpdateChannelForm setVisible={setVisible} visible={visible} updateChannel={updateChannel} record={record} />
    </>
  );
};

export default UpdateChannel;
