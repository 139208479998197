// react
import React, { useState } from "react";
import { Button } from "antd";

import AgencyForm from "./AgencyForm";

const AddAgency = ({ addAgency }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <AgencyForm
        setVisible={setVisible}
        visible={visible}
        addAgency={addAgency}
      />
      <Button type="primary" onClick={showModal}>
        Añadir Agencia
      </Button>
    </>
  );
};

export default AddAgency;
