import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { chatActions, chatReducer } from "./chatReducer";
import { fetchData } from "../../helpers/fetch";
import _ from "lodash";
import { SocketContext } from "../sockets/SocketContext";
import { VisitContext } from "../visits/VisitsContext";
import { visitsActions } from "../visits/visitReducer";
import { AuthContext } from "../../contexts/auth/AuthContext";
import { ProjectsContext } from "../projects/ProjectsContext";

export const ChatContext = createContext();

const initialState = {
  // Guarda el visitor y el id del manager de los chats
  // que están activos y quien comenzó la el chat.
  activeChats: [
    //{
    //  chat_id: id,
    //  manager: id,
    //  visitor: id
    //}
  ],

  // Guarda un objeto con el id del chat y un array de los mensajes
  // por cada chat activo.
  chats: [
    //{
    //  chat_id: id,
    //  started_chat: bool,
    //  messages: [{user_sender: id, message:message}]
    //}
  ],
};

const ChatProvider = ({ children }) => {
  const [chatState, chatDispatch] = useReducer(chatReducer, initialState);
  const { socket } = useContext(SocketContext);
  const { dispatch } = useContext(VisitContext);
  const { auth } = useContext(AuthContext);
  const { selectedProject } = useContext(ProjectsContext);

  useEffect(() => {
    socket?.on("manager_joins_the_chat", (data) => {
      dispatch({
        type: visitsActions.managerJoinsTheChat,
        payload: data,
      });
    });

    socket?.on("manager_leaves_the_chat", (data) => {
      dispatch({
        type: visitsActions.managerLeavesTheChat,
        payload: data,
      });
    });
    return () => {
      socket?.removeAllListeners("manager_joins_the_chat");
      socket?.removeAllListeners("manager_leaves_the_chat");
      dispatch({
        type: visitsActions.cleanVisits,
      });
    };
  }, [socket, dispatch]);

  const joinChat = useCallback(
    (data) => {
      chatDispatch({ type: chatActions.activeChat, data });
    },
    [chatDispatch]
  );

  const leaveChat = useCallback(
    (chat_id) => {
      chatDispatch({ type: chatActions.inactiveChat, data: { chat_id } });
    },
    [chatDispatch]
  );

  const addMessage = useCallback(
    (data) => {
      chatDispatch({
        type: chatActions.addMessage,
        data: {
          chat_id: data.chat_id,
          user_sender: data.user_sender,
          message: data.message,
          createdAt: data.createdAt,
        },
      });
    },
    [chatDispatch]
  );

  const getMessages = useCallback(
    (chat_id) => {
      const chat = _.find(chatState.chats, (chat) => chat.chat_id === chat_id);
      return chat ? chat.messages : [];
    },
    [chatState]
  );

  const setMessages = useCallback(
    (data) => {
      // TODO: SET MESSAGES IN CHAT
      chatDispatch({
        type: chatActions.setMessages,
        data,
      });
    },
    [chatDispatch]
  );

  const getChatStarted = useCallback(
    (chat_id) => {
      const chat = _.find(chatState.chats, (chat) => chat.chat_id === chat_id);
      return chat ? chat.started_chat : false;
    },
    [chatState]
  );

  const setChatStarted = useCallback(
    (data) => {
      chatDispatch({
        type: chatActions.setChatStarted,
        data,
      });
    },
    [chatDispatch]
  );

  const getConversation = async (chat_id = "") => {
    const response = await fetchData("/messages/today", { chat_id }, "GET");
    if (response.status === 200) {
      const messages = _.map(response.data.messages, (msg) => {
        return {
          chat_id: msg.chat_id,
          user_sender: msg.user_sender,
          message: msg.message,
          createdAt: msg.createdAt,
        };
      });
      const data = {
        chat_id: response.data.chat_id,
        messages,
      };
      setMessages(data);
      return "OK";
    }
    return "NO_OK";
  };

  const getConversationBeforeToday = async (chat_id = "") => {
    const response = await fetchData(
      "/messages/before-today",
      { chat_id },
      "GET"
    );
    if (response.status === 200) {
      const messages = _.map(response.data.messages, (msg) => {
        return {
          chat_id: msg.chat_id,
          user_sender: msg.user_sender,
          message: msg.message,
          createdAt: msg.createdAt,
          interaction_type: "default",
        };
      });
      return messages;
      // return "OK";
    }
    return "NO_OK";
  };

  const initChat = useCallback(
    (visit) => {
      socket.emit("init_chat", {
        visitor: visit.visitor?.id,
        project: selectedProject.id,
      });
      socket.on("join_manager_client", (data) => {
        const chat_id = data.chat_id;
        let new_chat = {
          chat_id,
          visitor: visit.visitor?.id,
          started_chat: true,
        };
        let lsChats = JSON.parse(localStorage.getItem("chats"));
        if (lsChats === null || !_.find(lsChats, new_chat)) {
          let chats = lsChats !== null && lsChats.length ? lsChats : [];
          chats.push(new_chat);
          localStorage.setItem("chats", JSON.stringify(chats));
        }
        socket.emit("join_manager", { chat_id, complete: true });
        socket.emit("manager_joins_the_chat", {
          manager: auth.user,
          visitor: visit.visitor?.id,
          visit: visit.id,
        });
      });
    },
    [socket, auth.user, selectedProject]
  );

  const closeChat = useCallback(
    (visit, chat_id) => {
      const user_receiver = chat_id.split("_")[2];
      const user_sender = chat_id.split("_")[3];
      const dataMessage = {
        chat_id,
        user_sender,
        visitor: user_receiver,
        message: {
          text: "Si necesitas realizar una consulta en cualquier otro momento, aquí tienes mis datos:",
        },
        interaction_type: "close",
      };

      socket.emit("message", dataMessage);
      socket.emit("manager_leaves_the_chat", {
        manager: auth.user,
        visitor: visit.visitor?.id,
        visit: visit.id,
        chat_id,
      });
      socket.removeAllListeners("join_manager_client");
    },
    [socket, auth]
  );

  return (
    <ChatContext.Provider
      value={{
        chatState,
        chatDispatch,
        joinChat,
        leaveChat,
        addMessage,
        getMessages,
        getConversation,
        getConversationBeforeToday,
        setMessages,
        initChat,
        closeChat,
        getChatStarted,
        setChatStarted,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
