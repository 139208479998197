import React, { useState } from "react";
import { Button } from "antd";
import AddChannelForm from "./AddChannelForm";

const AddChannel = ({ addChannel }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Añadir Canal
      </Button>

      <AddChannelForm setVisible={setVisible} visible={visible} addChannel={addChannel} />
    </>
  );
};

export default AddChannel;
