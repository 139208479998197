// react
import { useCallback, useContext, useState } from "react";

// misc
import { errorNotification } from "../components/Utils/Notification/Notification";
import { ProjectsContext } from "../contexts/projects/ProjectsContext";
import { fetchDataToken } from "../helpers/fetch";

const useCampaigns = () => {
  const { selectedProject } = useContext(ProjectsContext);
  const [campaigns, setCampaigns] = useState([]);

  const fetchCampaigns = useCallback(async () => {
    try {
      if (selectedProject?.id) {
        const response = await fetchDataToken(
          "/campaign",
          { project: selectedProject?.id },
          "GET"
        );
        if (response.status === 200) {
          setCampaigns(response.data.results);
        }
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, [selectedProject?.id]);

  const addCampaign = useCallback(
    async (data) => {
      try {
        if (selectedProject?.id) {
          const response = await fetchDataToken(
            `/campaign/`,
            { ...data, project: selectedProject?.id },
            "POST"
          );
          if (response.status === 201) {
            setCampaigns((prev) => [...prev, response.data]);
          }
        }
      } catch (error) {
        if (error.response) {
          errorNotification("Error", error.response.data.msg);
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error.message);
        }
      }
    },
    [selectedProject?.id]
  );

  const updateCampaign = useCallback(async (id, data) => {
    try {
      const response = await fetchDataToken(`/campaign/${id}`, data, "PUT");
      if (response.status === 200) {
        setCampaigns((prev) =>
          prev.map((c) => {
            if (c.id === id) {
              return response.data;
            }
            return c;
          })
        );
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, []);

  const deleteCampaign = useCallback(async (id) => {
    try {
      const response = await fetchDataToken(`/campaign/${id}`, {}, "DELETE");
      if (response.status === 204) {
        setCampaigns((prev) => prev.filter((c) => c.id !== id));
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, []);

  return {
    campaigns,
    fetchCampaigns,
    deleteCampaign,
    addCampaign,
    updateCampaign,
  };
};

export default useCampaigns;
