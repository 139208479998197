import React, { memo } from "react";
import Projects from "./Projects";
import { Layout } from "antd";
import Navbar from "../../../components/Navbar/Navbar";

const ProjectsPage = memo(() => {
  const { Content } = Layout;

  return (
    <>
      <Navbar title="Webseller" />
      <Content style={{ padding: "30px 90px", background: "white", minHeight: 92 + "vh" }}>
        <Projects />
      </Content>
    </>
  );
});

export default ProjectsPage;
