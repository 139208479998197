import React from "react";
import ProjectsList from "./ProjectsList";

const Projects = () => {

  return (
    <div>
      <ProjectsList />
    </div>
  );
};

export default Projects;
