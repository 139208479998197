// react
import React from "react";
import { ConfigProvider } from "antd";
import locale from "antd/es/locale/es_ES";

// auth
import { AuthProvider } from "./contexts/auth/AuthContext";

import { UsersProvider } from "./contexts/users/UsersContext";
import GuidersRouter from "./router/GuidersRouter";

function Guiders() {
  return (
    <ConfigProvider locale={locale}>
      <AuthProvider>
          <UsersProvider>
            <GuidersRouter />
          </UsersProvider>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default Guiders;
