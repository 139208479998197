import { Typography } from "antd";
import React from "react";
import DataProfile from "./DataProfile";
import IconProfileChanger from "./IconProfileChanger";

const Profile = () => {
  const { Title } = Typography;
  return (
    <>
      <Title level={2}>Mi perfil</Title>
      <IconProfileChanger />
      <DataProfile />
    </>
  );
};

export default Profile;
