// react
import { useCallback, useContext, useState } from "react";

// misc
import { errorNotification } from "../../components/Utils/Notification/Notification";
import { ProjectsContext } from "../../contexts/projects/ProjectsContext";
import { fetchDataToken } from "../../helpers/fetch";

const useTagsType = () => {
  const { selectedProject } = useContext(ProjectsContext);
  const [tagsType, setTagsType] = useState([]);

  const getTagsType = useCallback(async () => {
    try {
      if (selectedProject?.id) {
        const response = await fetchDataToken(
          "/tag-type",
          { project: selectedProject?.id },
          "GET"
        );
        if (response.status === 200) {
          setTagsType(response.data.results);
        }
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, [selectedProject?.id]);

  const addTagType = useCallback(
    async (data) => {
      try {
        if (selectedProject?.id) {
          const response = await fetchDataToken(
            `/tag-type/`,
            { ...data, project: selectedProject?.id },
            "POST"
          );

          if (response.status === 201) {
            setTagsType((prev) => [...prev, response.data]);
          }
        }
      } catch (error) {
        if (error.response) {
          errorNotification("Error", error.response.data.msg);
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error.message);
        }
      }
    },
    [selectedProject?.id]
  );

  const updateTagType = useCallback(
    async (id, data) => {
      try {
        if (selectedProject?.id) {
          const response = await fetchDataToken(
            `/tag-type/${id}`,
            { project: selectedProject?.id, ...data },
            "PUT"
          );

          if (response.status === 200) {
            setTagsType((prev) =>
              prev.map((c) => {
                if (c.id === id) {
                  return response.data;
                }
                return c;
              })
            );
          }
        }
      } catch (error) {
        if (error.response) {
          errorNotification("Error", error.response.data.msg);
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error.message);
        }
      }
    },
    [selectedProject?.id]
  );

  const deleteTagType = useCallback(async (id) => {
    try {
      const response = await fetchDataToken(`/tag-type/${id}`, {}, "DELETE");

    //   console.log("deleteTagType - response", response);
      if (response.status === 204) {
        setTagsType((prev) => prev.filter((tagType) => tagType?.id !== id));
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, []);

  return { tagsType, getTagsType, deleteTagType, addTagType, updateTagType };
};

export default useTagsType;
