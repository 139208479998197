// react
import { useCallback, useState } from "react";
import { fetchDataToken } from "../helpers/fetch";

// misc
import {
  errorNotification,
  successNotification,
} from "../components/Utils/Notification/Notification";

// auth

export default function useWebhooks() {
  const [webhooks, setWebhooks] = useState([]);
  const [paginationWebhooks, setPaginationWebhooks] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchWebhooks = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await fetchDataToken("/webhook/", data, "GET");
      if (response.status === 200) {
        const { results, limit, totalPages, totalResults } = response?.data;
        setWebhooks(results || []);
        setPaginationWebhooks({ limit, totalPages, totalResults });
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
      setLoading(false);
    }
  }, []);

  const updateWebhook = useCallback(async (id, data, params = {}) => {
    try {
      const response = await fetchDataToken(
        `/webhook/${id}`,
        data,
        "PUT",
        params
      );

      if (response.status === 200) {
        setWebhooks((prev) => {
          return prev.map((webhook) => {
            if (webhook.id === response?.data?.id) {
              return response.data;
            }
            return webhook;
          });
        });
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, []);

  return {
    webhooks,
    fetchWebhooks,
    loading,
    updateWebhook,
    paginationWebhooks,
  };
}
