import React, { useState } from "react";
import { Form, Input, Modal, Select } from "antd";
import "./TagForm.css";

const TagForm = ({
  visible,
  setVisible,
  addTag,
  updateTag,
  record,
  tagsType,
}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  // const [input, setInput] = useState({ name: "", reference: "" });
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleOk = async () => {
    try {
      setConfirmLoading(true);

      const data = await form.validateFields();

      if (record === undefined) {
        await addTag(form.getFieldsValue());
      } else {
        await updateTag(record?.id, data);
      }

      setVisible(false);
      setConfirmLoading(false);
      form.resetFields();
      // setInput({});
    } catch (error) {
      console.error(error);
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  // const handleChange = (e) => {
  //   const { id, value } = e.target;
  //   console.log(id, value)
  //   setInput((prevState) => ({ ...prevState, [id]: value }));
  // };

  return (
    <Modal
      title="Crear/Editar una Etiqueta"
      visible={visible}
      onOk={handleOk}
      okText="Guardar"
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      cancelText="Cancelar"
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        onFinish={handleOk}
        onFinishFailed={() => {
          console.error("form failed");
        }}
        initialValues={{
          ...record,
          tag_type: record?.tag_type?.id || record?.tag_type,
        }}
      >
        <Form.Item
          label="Nombre"
          name="name"
          id="name"
          // onChange={handleChange}
          rules={[{ required: true, message: "Es necesario!" }]}
          labelAlign="left"
        >
          <Input placeholder="Nombre" value={form.getFieldValue("name")} />
        </Form.Item>

        <Form.Item
          label="Referencia"
          name="reference"
          id="reference"
          // onChange={handleChange}
          rules={[{ required: true, message: "Es necesario!" }]}
          labelAlign="left"
        >
          <Input
            placeholder="Referencia"
            value={form.getFieldValue("reference")}
          />
        </Form.Item>

        <Form.Item
          name="tag_type"
          label="Tipo de tags"
          /* onChange={handleChange} */ labelAlign="left"
        >
          <Select placeholder="Tipo de tags">
            <Option value="">No asignar</Option>
            {tagsType?.map((t) => {
              return (
                <Option value={t?._id} key={t?._id}>
                  {t?.reference}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TagForm;
