import React, { useState } from "react";
import { Button } from "antd";
import UpdateCampaignForm from "./UpdateCampaignForm";

const UpdateCampaign = ({ updateCampaign, record, channels }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Editar
      </Button>

      <UpdateCampaignForm
        setVisible={setVisible}
        visible={visible}
        updateCampaign={updateCampaign}
        record={record}
        channels={channels}
      />
    </>
  );
};

export default UpdateCampaign;
