import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AuthContext } from "../../../contexts/auth/AuthContext";
import { ChatContext } from "../../../contexts/chat/ChatContext";
import { intervalBetweenTwoDates, newDate } from "../../../helpers/date";
import ChatMessage from "./ChatMessage";

const ChatBodyHistory = ({ chat_id }) => {
  const { getConversationBeforeToday } = useContext(ChatContext);
  const [messages, setMessages] = useState([]);
  const { auth } = useContext(AuthContext);
  const chatConversation = useRef();
  const messagesEndRef = useRef(null);
  const MAP_TYPES_MESSAGES = useMemo(
    () => ({
      SENT: "messageSent",
      RECEIVED: "messageReceived",
      INFORMATION: "informationMessage",
    }),
    []
  );

  useEffect(() => {
    if (chat_id) {
      async function _getConversation() {
        setMessages(await getConversationBeforeToday(chat_id));
      }
      _getConversation();
    }
    // eslint-disable-next-line
  }, [chat_id]);

  const getTypeMessage = useCallback(
    (message = {}) => {
      const user_sender = message.user_sender?.id || message.user_sender || null;
      switch (user_sender) {
        case null:
          return MAP_TYPES_MESSAGES.INFORMATION;
        case auth.user.id:
          return MAP_TYPES_MESSAGES.SENT;
        default:
          return MAP_TYPES_MESSAGES.RECEIVED;
      }
    },
    [auth, MAP_TYPES_MESSAGES]
  );

  const renderMessages = useMemo(() => {
    let showTimestamp = true;

    const MessagesComponents = messages?.map((msg, index) => {
      const typeMessage = getTypeMessage(msg);
      let previous = messages[index - 1];
      if (previous && previous.user_sender) {
        let previousDuration = intervalBetweenTwoDates(previous.createdAt, msg.createdAt);
        if (previousDuration) {
          showTimestamp = false;
        } else {
          showTimestamp = true;
        }
      }
      return (
        <ChatMessage
          key={index}
          type={typeMessage}
          date={newDate(msg.createdAt).toLocal().toFormat("T")}
          message={msg.message.text}
          showTimestamp={showTimestamp}
          timestamp={newDate(msg.createdAt).toLocal().toFormat("D")}
          user_sender={msg.user_sender}
        />
      );
    });
    return <>{MessagesComponents}</>;
  }, [messages, getTypeMessage]);

  return (
    // TODO: Aquí debe hacer un map para recorrrer la lista de mensajes
    // y asignar a cada message el tipo que toque: messageReceived | messageSent | informationMessage
    <div id="pixel-chat-conversation" ref={chatConversation}>
      {renderMessages}
      <div id="last" ref={messagesEndRef} />
    </div>
  );
};

export default ChatBodyHistory;
