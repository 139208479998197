import React from "react";
import { Avatar, Tooltip } from "antd";

const AvatarProfile = ({ user, size = 40 }) => {
  const initials =
    user?.initials ||
    user?.name?.charAt(0)?.toUpperCase() +
      user?.first_lastname?.charAt(0)?.toUpperCase() ||
    "N/A";

    // console.log({size})

  return (
    <Tooltip
      title={`${user?.name} ${user?.first_lastname}`}
      placement="topRight"
    >
      {user?.avatar ? (
        <Avatar
          size={size}
          src={user.avatar}
          style={{
            userSelect: "none",
          }}
        />
      ) : (
        <Avatar
          style={{
            color: "white",
            backgroundColor: user?.avatar_color,
            display: "flex",
            alignItems: "flex-end",
            userSelect: "none",
          }}
          size={size}
        >
          <span style={{ fontSize: "15px" }}>{initials}</span>
        </Avatar>
      )}
    </Tooltip>
  );
};

export default AvatarProfile;
