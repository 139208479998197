import { notification } from "antd";

/**
 * Error Notification
 * @param {string} message Title for the Modal
 * @param {string} description Description for the Modal
 */

const errorNotification = (message, description) => {
  notification["error"]({
    message,
    description,
  });
};

/**
 * Info Notification
 * @param {string} message Title for the Modal
 * @param {string} description Description for the Modal
 */

const infoNotification = (message, description) => {
  notification["info"]({
    message,
    description,
  });
};

/**
 * Success Notification
 * @param {string} message Title for the Modal
 * @param {string} description Description for the Modal
 */
const successNotification = (message, description) => {
  notification["success"]({
    message,
    description,
  });
};

/**
 * Warning Notification
 * @param {string} message Title for the Modal
 * @param {string} description Description for the Modal
 */

const warningNotification = (message, description) => {
  notification["warning"]({
    message,
    description,
  });
};

export { errorNotification, infoNotification, successNotification, warningNotification };
