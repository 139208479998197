// react
import React, { createContext, useCallback, useContext, useEffect, useReducer } from "react";

// auth
import { AuthContext } from "../auth/AuthContext";

// misc
import { fetchDataToken } from "../../helpers/fetch";
import { SocketContext } from "../sockets/SocketContext";
import { usersActions, usersReducer } from "./usersReducer";

export const AgencyContext = createContext();

export const AgencyProvider = ({ children }) => {

  const { socket } = useContext(SocketContext);
  const { auth } = useContext(AuthContext);
  const [usersOnline, usersDispatch] = useReducer(usersReducer, []);

  useEffect(() => {

    const project = localStorage.getItem("project") && JSON.parse(localStorage.getItem("project"));
    socket?.emit("manager:connect", { user: auth?.user, project: project.id });

  }, [auth?.user, socket]);

  useEffect(() => {

    socket?.on("manager:connect", (data) => {
      usersDispatch({ type: usersActions.ADD_USER_ONLINE, payload: data });
    });
    socket?.on("manager:update", (data) => {
      usersDispatch({ type: usersActions.UPDATE_USER_ONLINE, payload: data });
    });
    socket?.on("manager:disconnect", (data) => {
      usersDispatch({ type: usersActions.DELETE_USER_ONLINE, payload: data });
    });
    return () => {
      socket?.removeAllListeners("manager:connect");
      socket?.removeAllListeners("manager:update");
      socket?.removeAllListeners("manager:disconnect");
      socket?.disconnect();
    };

  }, [socket]);

  const fetchUsersOnline = useCallback(async (params) => {
    try {
      const response = await fetchDataToken("/user", { ...params});
    //   console.log('fetchUsersOnline - response', response)
      if (response.status === 200) {
        usersDispatch({ type: usersActions.LOAD_USERS_ONLINE, payload: response.data?.results });
      }
    } catch (error) {
      if (error.response) {
        // errorNotification(error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error);
      }
    }

  }, []);

  const cleanUsersOnline = useCallback(() => {

    usersDispatch({ type: usersActions.CLEAN_USERS_ONLINE });

  }, []);

  return (
    <AgencyContext.Provider value={{ usersOnline, usersDispatch, fetchUsersOnline, cleanUsersOnline }}>
      {children}
    </AgencyContext.Provider>
  );
};
