import React, { useState } from "react";

import { deviceType } from "../../../../helpers/deviceType";
import { isValidUrl } from "../../../../helpers/url";

import { Tooltip, Table, Row, Divider, Tag } from "antd";
import {
    BellOutlined,
    ClockCircleOutlined,
    FieldTimeOutlined,
    FileSearchOutlined,
    FlagOutlined,
    GlobalOutlined,
    LaptopOutlined,
    QuestionCircleOutlined,
    TrophyOutlined,
    UndoOutlined,
    UserOutlined,
    VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import {
    formatFromMillisToTimeSession,
    newDate,
} from "../../../../helpers/date";
import {
    sorterByDate,
    sorterByLetters,
    sorterByNumbers,
} from "../../../../helpers/sorter";
import Popup from "../../../Utils/Table/RowPopup/Popup";
import ShowModal from "../../../Utils/Table/RowPopup/ShowModal";
import AvatarProfile from "../../../Profile/AvatarProfile";

const TableVisits = ({ title, visits, pagination, handleTableChange }) => {
    const [popup, setPopup] = useState({
        visible: false,
        x: 0,
        y: 0,
        showModal: false,
        option: "",
    });

    const onRow = (record) => {
        return {
            onContextMenu: (event) => {
                event.preventDefault();
                if (!popup.visible) {
                    if (!popup.showModal) {
                        document.addEventListener(`click`, function onClickOutside() {
                            setPopup((prev) => ({ ...prev, visible: false }));
                            document.removeEventListener(`click`, onClickOutside);
                        });
                    }
                }
                setPopup({
                    record,
                    visible: true,
                    x: event.pageX,
                    y: event.pageY,
                    showModal: false,
                });
            },
        };
    };

    const columns = [
        {
            title: (
                <Tooltip
                    placement="top"
                    title="Tipo de dispositivo"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <LaptopOutlined style={{ fontSize: "20px" }} />
                </Tooltip>
            ),
            key: "device_type",
            dataIndex: "device_type",
            ellipsis: true,
            showSorterTooltip: false,
            align: "center",
            width: "3%",
            render: (data, record) => (
                <Tooltip placement="top" title="">
                    {deviceType(record.bot, data)}
                </Tooltip>
            ),
            fixed: "left",
        },
        {
            title: (
                <Tooltip
                    placement="top"
                    title="Usuario"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <UserOutlined style={{ fontSize: "20px" }} />
                </Tooltip>
            ),
            key: "visitor",
            dataIndex: "visitor",
            ellipsis: true,
            align: "left",
            showSorterTooltip: true,
            width: "5%",
            render: (data) => (
                <>
                    <Tooltip
                        placement="top"
                        title={
                            data.name ? data.name : `El id único del usuario es: ${data?.id}`
                        }
                    >
                        {data.name ? data.name : `#${(data?.id).slice(-6)} `}
                    </Tooltip>
                </>
            ),
            fixed: "left",
        },
        {
            title: (
                <Tooltip
                    placement="top"
                    title="Usuario captador"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <UserOutlined style={{ fontSize: "20px" }} />
                </Tooltip>
            ),
            key: "visitor.user_catcher",
            dataIndex: "visitor",
            ellipsis: true,
            align: "left",
            showSorterTooltip: true,
            width: "60px",
            render: (_, record) =>
                record.visitor.user_catcher?.name ? (
                    <AvatarProfile user={record.visitor.user_catcher} size={30} />
                ) : (
                    <AvatarProfile user={{
                        initials: 'N/C',
                        name: 'No',
                        first_lastname: 'captado',
                        avatar_color: '#919191'
                    }} size={30} />
                ),
            fixed: "left",
        },

        {
            title: (
                <Tooltip
                    placement="top"
                    title="Procedencia"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <VerticalAlignBottomOutlined style={{ fontSize: "20px" }} />
                </Tooltip>
            ),
            key: "url_origin",
            dataIndex: "url_origin",
            ellipsis: true,
            align: "left",
            width: "auto",
            showSorterTooltip: false,
            render: (data) => (
                <Tooltip placement="top" title={data ? isValidUrl(data) : "Búsqueda"}>
                    {data ? isValidUrl(data) : "Búsqueda"}
                </Tooltip>
            ),
            sorter: (a, b) => sorterByLetters(a.url_origin, b.url_origin),
        },
        {
            title: (
                <Tooltip
                    placement="top"
                    title="Localización"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <FlagOutlined style={{ fontSize: "20px" }} />
                </Tooltip>
            ),
            key: "geolocation",
            dataIndex: "geolocation",
            ellipsis: true,
            align: "left",
            showSorterTooltip: false,
            render: (data, record) => (
                <Tooltip
                    placement="topLeft"
                    title={
                        record.city && record.country
                            ? `${record.city}, ${record.country}`
                            : "No disponible"
                    }
                >
                    {record.city && record.country ? (
                        <div>
                            {/* <img
                src={`https://www.countryflags.io/${record.geolocalizacion.country_code.toLowerCase()}/flat/16.png`}
                alt={record.pais}
              /> */}
                            <span style={{ marginLeft: "1px" }}>
                                {record.geolocation.country_code}
                            </span>
                        </div>
                    ) : (
                        <QuestionCircleOutlined style={{ fontSize: "20px" }} />
                    )}
                </Tooltip>
            ),
            sorter: (a, b) => {
                const localizationA = `${a.country}, ${a.city}`;
                const localizationB = `${b.country}, ${b.city}`;
                return sorterByLetters(localizationA, localizationB);
            },
            width: "4%",
        },
        {
            title: (
                <Tooltip
                    placement="top"
                    title="Navegador"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <GlobalOutlined style={{ fontSize: "20px" }} />
                </Tooltip>
            ),
            key: "browser",
            dataIndex: "browser",
            ellipsis: true,
            align: "left",
            showSorterTooltip: false,
            width: "7%",
            sorter: (a, b) => sorterByLetters(a.browser, b.browser),
        },
        {
            title: (
                <Tooltip
                    placement="top"
                    title="Fecha de llegada"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <ClockCircleOutlined style={{ fontSize: "20px" }} />
                </Tooltip>
            ),
            key: "createdAt",
            dataIndex: "createdAt",
            ellipsis: true,
            showSorterTooltip: false,
            align: "center",
            width: "7%",
            render: (data) => (
                <Tooltip
                    placement="topRight"
                    title={`El usuario ha visitado el día ${newDate(data)
                        .setLocale("es-ES")
                        .toFormat("dd' de 'LLLL' de 'yyyy ' a las ' T")}`}
                >
                    <div style={{ textAlign: "end" }}>
                        <span>{newDate(data).toFormat("dd'-'LL'-'yyyy ")}</span>
                    </div>
                </Tooltip>
            ),
            sorter: (a, b) => sorterByDate(a.createdAt, b.createdAt),
        },
        {
            title: (
                <Tooltip
                    placement="top"
                    title="Tiempo de sesión"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <FieldTimeOutlined style={{ fontSize: "20px" }} />
                </Tooltip>
            ),
            key: "time_session",
            dataIndex: "time_session",
            ellipsis: true,
            showSorterTooltip: false,
            align: "right",
            render: (data) =>
                Number.isInteger(data) ? (
                    <Tooltip
                        placement="topRight"
                        title={
                            <span>
                                {`Tiempo activo del usuario:
              ${formatFromMillisToTimeSession(data)}`}
                            </span>
                        }
                    >
                        {formatFromMillisToTimeSession(data)}
                    </Tooltip>
                ) : (
                    "No disponible"
                ),
            sorter: (a, b) => sorterByNumbers(b.time_session, a.time_session),
            width: "8%",
        },
        {
            title: (
                <Tooltip
                    placement="top"
                    title="El usuario ha venido"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <UndoOutlined style={{ fontSize: "20px" }} />
                </Tooltip>
            ),
            key: "recurrence",
            dataIndex: "recurrence",
            ellipsis: true,
            showSorterTooltip: false,
            align: "right",
            render: (data) => <>{data > 1 ? `${data} veces` : `${data} vez`}</>,
            sorter: (a, b) => sorterByNumbers(b.recurrence, a.recurrence),
            width: "5%",
        },
        {
            title: (
                <Tooltip
                    placement="top"
                    title="Páginas vistas"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <FileSearchOutlined style={{ fontSize: "20px" }} />
                </Tooltip>
            ),
            key: "pages",
            dataIndex: "pages",
            ellipsis: true,
            showSorterTooltip: false,
            align: "right",
            render: (data) => (data > 1 ? `${data} páginas` : `${data} página`),
            sorter: (a, b) => sorterByNumbers(a.pages, b.pages),
            width: "6%",
        },
        {
            title: (
                <Tooltip
                    placement="top"
                    title="Última página.."
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <FileSearchOutlined style={{ fontSize: "20px" }} />
                </Tooltip>
            ),
            key: "url_current",
            dataIndex: "url_current",
            ellipsis: true,
            align: "left",
            width: "auto",
            showSorterTooltip: false,
            render: (data) => (
                <Tooltip
                    placement="topLeft"
                    title={
                        new URL(data).pathname.length > 20 ? (
                            <a target="_blank" rel="noreferrer" href={data}>
                                {data}
                            </a>
                        ) : (
                            ""
                        )
                    }
                >
                    <a target="_blank" rel="noreferrer" href={data}>
                        {new URL(data).pathname === "/" ? "Home" : new URL(data).pathname}
                    </a>
                </Tooltip>
            ),
            sorter: (a, b) => sorterByLetters(a.url_current, b.url_current),
        },
        {
            title: (
                <Tooltip
                    placement="top"
                    title="Tags"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <TrophyOutlined style={{ fontSize: "20px" }} />
                </Tooltip>
            ),
            key: "tags",
            dataIndex: "tags",
            ellipsis: true,
            showSorterTooltip: false,
            align: "right",
            render: (data, record) => {
                return (
                    <>
                        {Object.values(record?.tags || {})?.map((t, i) => {
                            if (i < 3) {
                                return (
                                    <Tooltip
                                        placement="topLeft"
                                        title={t.reference ? `${t.reference}` : "N/R"}
                                        key={`${t.reference}-${t.count || 1}`}
                                    >
                                        <Tag key={`${t.reference}-${t.count || 1}`} color="green">{t.count || 1}</Tag>
                                    </Tooltip>
                                );
                            }
                            return <></>;
                        })}
                    </>
                );
            },
            // sorter: (a, b) => sorterByNumbers(a.scoring, b.scoring),
        },
        {
            title: (
                <Tooltip
                    placement="top"
                    title="Campaña"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <BellOutlined style={{ fontSize: "20px" }} />
                </Tooltip>
            ),
            key: "campaign",
            dataIndex: "campaign",
            ellipsis: true,
            showSorterTooltip: false,
            align: "left",
            width: "45px",
            sorter: (a, b) => {
                return sorterByLetters(a?.campaign?.name, b?.campaign?.name);
            },
            render: (data) => {
                return (
                    <Tooltip title={data?.nombre || " Sin campaña"}>
                        {data?.nombre ? (
                            <BellOutlined style={{ fontSize: "20px" }} />
                        ) : (
                            <BellOutlined style={{ fontSize: "20px", color: "lightgray" }} />
                        )}
                    </Tooltip>
                );
            },
        },
        // {
        //   title: (
        //     <Tooltip
        //       placement="top"
        //       title="Charlado con.."
        //       style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        //     >
        //       <MessageOutlined style={{ fontSize: "20px" }} />
        //     </Tooltip>
        //   ),
        //   key: "charla",
        //   dataIndex: "charla",
        //   ellipsis: true,
        //   width: "auto",
        //   showSorterTooltip: false,
        //   align: "center",
        //   sorter: true,
        //   render()
        // },
    ];

    return (
        <>
            <Row>
                {title}
                <Divider style={{ margin: "15px 0" }} />
            </Row>
            <Table
                columns={columns}
                dataSource={visits}
                rowKey={(record) => record?.id}
                onChange={handleTableChange}
                pagination={pagination}
                // loading={loading}
                onRow={onRow}
                // scroll={{ x: 1500 }}
                bordered={true}
                locale={{
                    triggerAsc: "Ordenar ascendentemente",
                    triggerDesc: "Ordenar descendenmente",
                    cancelSort: "No ordenar",
                }}
                size="small"
            />
            <Popup
                record={popup.record}
                visible={popup.visible}
                x={popup.x}
                y={popup.y}
                setPopup={setPopup}
                RT={false}
            />
            <ShowModal
                show={popup.showModal}
                setPopup={setPopup}
                popup={popup}
                record={popup.record}
            />
        </>
    );
};

export default TableVisits;
