import React, { useEffect, useState } from "react";
import { Steps, Tooltip } from "antd";
import { newDate } from "../../../../helpers/date";
import "./HistoryNavigation.css";
import { fetchDataToken } from "../../../../helpers/fetch";

const HistoryNavigation = ({ record }) => {
  const { Step } = Steps;
  const [historyNavigation, setHistoryNavigation] = useState([]);

  const fetchHistory = async(visitId) => {
    try {
      const response = await fetchDataToken(`/visit/${visitId}/history-navigation-today`, "GET");
      if (response.status === 200) {
        setHistoryNavigation(response.data.history);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchHistory(record?.id);
  }, [record?.id]);

  return (
    <Steps
      direction="vertical"
      size="small"
      style={{
        overflow: "auto",
        maxHeight: "100%",
      }}
    >
      {historyNavigation.map((v, i) => {
        return (
          <Step
            title={
              new URL(v.page.url).pathname === "/" ? (
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <Tooltip title={v.page.url} placement="topRight">
                    {v.page.name ? `Título: ${v.page.name} - ` : ""}
                    <a href={v.page.url} style={{ textOverflow: "ellipsis" }}>
                      Home
                    </a>
                  </Tooltip>
                  <span>{newDate(v.createdAt).toFormat("TT")}</span>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <Tooltip title={v.page.url} placement="topRight">
                    {v.page.name ? `Título: ${v.page.name} - ` : ""}
                    <a href={v.page.url} target="_blank" rel="noreferrer" style={{ textOverflow: "ellipsis" }}>
                      {new URL(v.page.url).pathname}
                    </a>
                  </Tooltip>

                  <span>{newDate(v.createdAt).toFormat("TT")}</span>
                </div>
              )
            }
            status="current"
            key={i}
            className="history-navigation-steps"
          />
        );
      })}
    </Steps>
  );
};

export default HistoryNavigation;
