import React from "react";
import { Tooltip, Table } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { formatFromMillisToTimeSession, newDate } from "../../../../helpers/date";
import { sorterByDate, sorterByNumbers, sorterByLetters } from "../../../../helpers/sorter";
import AvatarProfile from "../../../Profile/AvatarProfile";

const TableSellers = ({ title, usersSession, pagination, handleTableChange, loading }) => {
  const columns = [
    {
      title: "",
      // dataIndex: "name",
      key: "avatar",
      render: (_, record) => <AvatarProfile user={record.user} size={30} />,
      align: "center",
      width: "5px",
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Nombre"
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <UserOutlined style={{ fontSize: "20px", marginRight: "5px" }} />
          Nombre
        </Tooltip>
      ),
      key: "name",
      dataIndex: "user",
      render: (data) => (
        <Tooltip placement="top" title="name">
          <span>{data?.name}</span>
        </Tooltip>
      ),
      sorter: (a, b) => sorterByLetters(a.user?.name, b.user?.name),
      showSorterTooltip: false,
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Primer apellido"
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <UserOutlined style={{ fontSize: "20px", marginRight: "5px" }} />
          Primer apellido
        </Tooltip>
      ),
      key: "p_apellido",
      dataIndex: "user",
      render: (data) => (
        <Tooltip placement="top" title="Primer apellido">
          <span>{data?.first_lastname}</span>
        </Tooltip>
      ),
      sorter: (a, b) => sorterByLetters(a.user?.first_lastname, b.user?.first_lastname),
      showSorterTooltip: false,
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Segundo apellido"
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <UserOutlined style={{ fontSize: "20px", marginRight: "5px" }} />
          Segundo apellido
        </Tooltip>
      ),
      key: "s_apellido",
      dataIndex: "user",
      render: (data) => (
        <Tooltip placement="top" title="Segundo apellido">
          <span>{data?.second_lastname}</span>
        </Tooltip>
      ),
      sorter: (a, b) => sorterByLetters(a.user?.second_lastname, b.user?.second_lastname),
      showSorterTooltip: false,
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Fecha inicio"
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <UserOutlined style={{ fontSize: "20px", marginRight: "5px" }} />
          Fecha inicio
        </Tooltip>
      ),
      // key: "name",
      dataIndex: "start_date",
      render: (data) => (
        <Tooltip placement="top" title="Fecha inicio">
          <span>{newDate(data).toFormat("dd'-'LL'-'yyyy TT")}</span>
        </Tooltip>
      ),
      sorter: (a, b) => sorterByDate(a.start_date, b.start_date),
      showSorterTooltip: false,
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Fecha fin"
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <UserOutlined style={{ fontSize: "20px", marginRight: "5px" }} />
          Fecha fin
        </Tooltip>
      ),
      // key: "name",
      dataIndex: "end_date",
      render: (data) => (
        <Tooltip placement="top" title="Fecha fin">
          <span>{newDate(data).toFormat("dd'-'LL'-'yyyy TT")}</span>
        </Tooltip>
      ),
      sorter: (a, b) => sorterByDate(a.end_date, b.end_date),
      showSorterTooltip: false,
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Tiempo total"
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <UserOutlined style={{ fontSize: "20px", marginRight: "5px" }} />
          Tiempo total
        </Tooltip>
      ),
      // key: "name",
      dataIndex: "total_time",
      render: (data) => (
        <Tooltip placement="top" title="Tiempo total">
          <span>{formatFromMillisToTimeSession(data)}</span>
        </Tooltip>
      ),
      sorter: (a, b) => sorterByNumbers(a.total_time, b.total_time),
      showSorterTooltip: false,
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Asistencias realizadas"
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <UserOutlined style={{ fontSize: "20px", marginRight: "5px" }} />
          Asistencias realizadas
        </Tooltip>
      ),
      // key: "name",
      dataIndex: "assistsMade",
      render: (data) => (
        <Tooltip placement="top" title="Asistencias realizadas">
          <span>{data.length}</span>
        </Tooltip>
      ),
      sorter: (a, b) => sorterByNumbers(a.assistsMade, b.assistsMade),
      showSorterTooltip: false,
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Respuestas obtenidas"
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <UserOutlined style={{ fontSize: "20px", marginRight: "5px" }} />
          Respuestas obtenidas
        </Tooltip>
      ),
      // key: "name",
      dataIndex: "responsesObtained",
      render: (data) => (
        <Tooltip placement="top" title="Respuestas obtenidas">
          <span>{data.length}</span>
        </Tooltip>
      ),
      sorter: (a, b) => sorterByNumbers(a.responsesObtained, b.responsesObtained),
      showSorterTooltip: false,
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Leads captados"
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <UserOutlined style={{ fontSize: "20px", marginRight: "5px" }} />
          Leads captados
        </Tooltip>
      ),
      // key: "name",
      dataIndex: "leadsCaptured",
      render: (data) => (
        <Tooltip placement="top" title="Leads captados">
          <span>{data.length}</span>
        </Tooltip>
      ),
      sorter: (a, b) => sorterByNumbers(a.leadsCaptured, b.leadsCaptured),
      showSorterTooltip: false,
    },
  ];

  return (
    <>
      {title}
      <Table
        columns={columns}
        rowKey={(record) => (record?.id)}
        dataSource={usersSession}
        pagination={pagination}
        onChange={handleTableChange}
        bordered={true}
        scroll={{ x: 1100 }}
        loading={loading}
      />
    </>
  );
};

export default TableSellers;
