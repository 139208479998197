import { useCallback, useContext, useEffect, useState } from "react";
import { ProjectsContext } from "../contexts/projects/ProjectsContext";
import {
  getArrayLast15DaysInString,
  getArray24hourInString,
} from "../helpers/date";
import { fetchDataToken } from "../helpers/fetch";

const useDashboard = () => {
  const { selectedProject } = useContext(ProjectsContext);
  const [conversationsToday, setConversationsToday] = useState(0);
  const categories = getArrayLast15DaysInString();
  const [series, setSeries] = useState([]);
  const categoriesAverageCountVisits = ["1", "2", "3-5", "+5"];
  const [seriesAverageCountVisits, setSeriesAverageCountVisits] = useState([]);
  const [seriesCountActivity, setSeriesCountActivity] = useState([]);
  const [leadsToday, setLeadsToday] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchConversationsToday = useCallback(async () => {
    if (selectedProject?.id) {
      try {
        const response = await fetchDataToken("/conversations", {
          project: selectedProject?.id,
        });
        if (response.status === 200) {
          setConversationsToday(response.data.conversations);
          return response.data.conversations;
        }
      } catch (error) {
        if (error.response) {
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error);
        }
      }
    }
  }, [selectedProject?.id]);

  const fetchVisitEvolutions = useCallback(async () => {
    if (selectedProject?.id) {
      try {
        const response = await fetchDataToken("/visit/evolution", {
          project: selectedProject?.id,
        });
        if (response.status === 200) {
          setSeries(response.data);
          return response.data;
        }
      } catch (error) {
        if (error.response) {
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error);
        }
      }
    }
  }, [selectedProject?.id]);

  const fetchLeadsToday = useCallback(async () => {
    if (selectedProject?.id) {
      try {
        const response = await fetchDataToken("/visitor/leads/todays-percent", {
          project: selectedProject?.id,
        });
        if (response.status === 200) {
          setLeadsToday(response.data.leads);
          return response.data.leads;
        }
      } catch (error) {
        if (error.response) {
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error);
        }
      }
    }
  }, [selectedProject?.id]);

  const fetchAverageCountVisits = useCallback(async () => {
    if (selectedProject?.id) {
      try {
        const response = await fetchDataToken("/visit/average-count-visits", {
          project: selectedProject?.id,
        });
        if (response.status === 200) {
          const visitsCounter = response.data.visits_counter;
          const result = [
            {
              name: "visitas",
              data: Object.keys(visitsCounter).map(function (key) {
                return visitsCounter[key];
              }),
            },
          ];
          setSeriesAverageCountVisits(result);
          return result;
        }
      } catch (error) {
        if (error.response) {
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error);
        }
      }
    }
  }, [selectedProject?.id]);

  const fetchCountActivity = useCallback(async () => {
    if (selectedProject?.id) {
      const categoriesCountActivity = getArray24hourInString();
      try {
        const response = await fetchDataToken("/visit/count-activity", {
          project: selectedProject?.id,
        });
        if (response.status === 200) {
          const visitsCounter = response.data.data_date;
          const result = [
            {
              name: "Conexiones Hoy",
              data: Object.keys(visitsCounter).map((i) => {
                return {
                  x: categoriesCountActivity[i],
                  y: visitsCounter[i]?.total || 0,
                };
              }),
            },
          ];
          setSeriesCountActivity(result);
          return result;
        }
      } catch (error) {
        if (error.response) {
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error);
        }
      }
    }
  }, [selectedProject?.id]);

  useEffect(() => {
    setLoading(true);
    fetchConversationsToday().catch(console.error);
    fetchLeadsToday().catch(console.error);
    fetchVisitEvolutions().catch(console.error);
    fetchAverageCountVisits().catch(console.error);
    fetchCountActivity().catch(console.error);
  }, [fetchAverageCountVisits, fetchConversationsToday, fetchCountActivity, fetchLeadsToday, fetchVisitEvolutions]);

  useEffect(() => {
    if (
      (conversationsToday &&
        seriesAverageCountVisits &&
        seriesCountActivity &&
        leadsToday,
      series?.length)
    ) {
      setLoading(false);
    }
  }, [
    conversationsToday,
    leadsToday,
    series,
    seriesAverageCountVisits,
    seriesCountActivity,
  ]);

  return {
    conversationsToday,
    categories,
    series,
    leadsToday,
    loading,
    categoriesAverageCountVisits,
    seriesAverageCountVisits,
    seriesCountActivity,
  };
};

export default useDashboard;
