// react
import React, { useContext, useEffect, useState } from "react";

// styles
import { Row } from "antd";

// context
import { ProjectsContext } from "../../../contexts/projects/ProjectsContext";

// others
import Filter from "../../Utils/Table/Filter/Filter";
import TableVisits from "./Table/TableVisits";
import useVisits from "../../../hooks/useVisits";

const Visits = () => {
    const { selectedProject } = useContext(ProjectsContext);
    const { fetchVisits, visitsMongoDB, setVisitsMongoDB } = useVisits();
    const [filters, setFilters] = useState(null);

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 0,
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} visitas`,
        showSizeChanger: true,
        position: ['bottomCenter']
    });

    const handleTableChange = (onChangePagination) => {
        if (
            onChangePagination.current !== pagination.current ||
            onChangePagination.pageSize !== pagination.pageSize
        ) {
            setPagination((prev) => ({ ...prev, ...onChangePagination }));
            const params = {
                ...filters,
                pixel: selectedProject?.pixel_id,
                page: onChangePagination.current,
                pageSize: onChangePagination.pageSize,
                populate: "visitor,visitor.user_catcher,campaign",
            };
            fetchVisits(params);
        }
    };

    const handleFilter = () => {
        const params = {
            ...filters,
            pixel: selectedProject?.pixel_id,
            page: pagination.current,
            pageSize: pagination.pageSize,
            populate: "visitor,visitor.user_catcher,campaign",
        };
        fetchVisits(params);
    };

    useEffect(() => {
        if (selectedProject?.pixel_id && pagination) {
            const params = {
                pixel: selectedProject?.pixel_id,
                page: pagination.current,
                pageSize: pagination.pageSize,
                populate: "visitor,visitor.user_catcher,campaign",
            };
            fetchVisits(params);
        }
        return () => {
            setVisitsMongoDB({ visits: [], count: 0 });
        };
        // eslint-disable-next-line
    }, [selectedProject?.pixel_id]);

    useEffect(() => {
        if (visitsMongoDB) {
            setPagination((prevPagination) => ({
                ...prevPagination,
                total: visitsMongoDB.total,
            }));
        }
    }, [visitsMongoDB]);

    // const data = useMemo(
    //   () => [
    //     { title: "Total visitas", key: "totalVisitas", value: visitsMongoDB?.count },
    //     // { title: "Total páginas vistas", key: "totalPaginasVistas" },
    //   ],
    //   [visitsMongoDB]
    // );

    const fields = [
        // { label: "Nombre", type: "text" },
        // { label: "Primer apellido", type: "text" },
        // { label: "Segundo apellido", type: "text" },
        // { label: "Email", type: "text" },
        { label: "Campaña", type: "text", key: "campaign" },
    ];

    return (
        <>
            {/* <Cards dataSource={data} /> */}
            <TableVisits
                title={
                    <>
                        <Row
                            justify="space-between"
                            style={{ minWidth: "100%", alignItems: "center" }}
                        >
                            <span style={{ fontSize: "24px", margin: " 0 0 10px 5px" }}>
                                <strong>Visitas</strong> obtenidas hasta hoy
                            </span>
                        </Row>
                        <Row
                            justify="space-between"
                            style={{ minWidth: "100%", alignItems: "center" }}
                        >
                            <Filter
                                setFilters={setFilters}
                                handleFilter={handleFilter}
                                fields={fields}
                            />
                        </Row>
                    </>
                }
                visits={visitsMongoDB?.results}
                pagination={pagination}
                handleTableChange={handleTableChange}
            />
        </>
    );
};

export default Visits;
