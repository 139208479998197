import {
  DesktopOutlined,
  MobileOutlined,
  QuestionCircleOutlined,
  RobotOutlined,
  TabletOutlined,
} from "@ant-design/icons";

export const deviceType = (bot, option) => {
  if (bot) {
    return <RobotOutlined style={{ fontSize: "20px" }} />;
  }
  switch (option) {
    case "desktop":
      return <DesktopOutlined style={{ fontSize: "20px" }} />;
    case "smartphone":
      return <MobileOutlined style={{ fontSize: "20px" }} />;
    case "phablet":
      return <MobileOutlined style={{ fontSize: "20px" }} />;
    case "tablet":
      return <TabletOutlined style={{ fontSize: "20px" }} />;
    default:
      return <QuestionCircleOutlined style={{ fontSize: "20px" }} />;
  }
};
