import { DateTime, Duration } from "luxon";

export const fromMillis = (date) => {
  return DateTime.fromMillis(date);
};

export const formatFromMillis = (dateInMillis) => {
  if (typeof dateInMillis === "number") {
    const millis = DateTime.fromMillis(dateInMillis);
    if (millis.milliseconds > 3600000) {
      return millis.toFormat("h:m:ss");
    }
    return millis.toFormat("mm:ss");
  }
  return false;
};

export const newDate = (date) => {
  return DateTime.fromJSDate(new Date(date));
};

export const newDateFromString = (date) => {
  const jsDate = DateTime.fromFormat(date, "dd-LL-yyyy").toJSDate();
  return jsDate;
};

export const startOf = (option) => {
  const today = DateTime.utc();
  return today.startOf(option).toJSDate();
};
export const endOf = (option) => {
  const today = DateTime.utc();
  return today.endOf(option).toJSDate();
};

export const intervalBetweenTwoDates = (firstDate, lastDate) => {
  const firstDateISO = DateTime.fromISO(firstDate).toUTC();
  const lastDateISO = DateTime.fromISO(lastDate).toUTC();
  return firstDateISO.hasSame(lastDateISO, "day");
};

export const diffInMillisBetweenTwoDates = (firstDate, lastDate) => {
  const firstDateJS = DateTime.fromJSDate(firstDate);
  const lastDateJS = DateTime.fromJSDate(lastDate);
  const diff = lastDateJS.diff(firstDateJS).milliseconds;
  return diff;
};

export const getTimeSessionInMillis = (firstDate, lastDate) => {
  const diff = lastDate - firstDate;
  return diff;
};

export const formatFromMillisToTimeSession = (date) => {
  const millis = Duration.fromMillis(date);

  if (millis >= 86400000) {
    //   return millis.toFormat("h'h' 'y' m'm'");
    // } else if (millis >= 3600000) {
    return millis.toFormat("h'h' 'y' m'm'");
  } else if (millis >= 61000) {
    return millis.toFormat("m'm' 'y' s's'");
  } else if (millis >= 60000) {
    return millis.toFormat("m'm'");
  } else if (millis >= 1000) {
    return millis.toFormat("s's'");
  } else {
    return millis.toFormat("S'ms");
  }
};

export const getArrayLast15DaysInString = () => {
  const month = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];
  const array = [];
  for (let i = 14; i >= 0; i--) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    const dateString = date.getDate() + " " + month[date.getMonth()];
    array.push(dateString);
  }
  return array;
};

export const getArray24hoursin30MinInString = () => {
  const hours = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let min = 0; min < 2; min++) {
      let newHour = `${String(hour).padStart(2, "0")}:${String(
        min * 30
      ).padStart(2, "0")}`;
      hours.push(newHour);
    }
  }
  return hours;
};

export const getArray24hourInString = () => {
  const hours = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let min = 0; min < 1; min++) {
      let newHour = `${String(hour).padStart(2, "0")}:${String(
        min * 30
      ).padStart(2, "0")}`;
      hours.push(newHour);
    }
  }
  return hours;
};