import React from "react";
import { Table, Tooltip } from "antd";
import { newDate } from "../../../../helpers/date";
import UpdatePage from "./UpdatePage/UpdatePage";

const PagesList = ({ updatePage, pages, pagination, handleTableChange }) => {
  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: "20%",
      render: (data) => (
        <Tooltip title={data} placement="top">
          {data}
        </Tooltip>
      ),
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      ellipsis: true,
      width: "40%",
      render: (data) => (
        <Tooltip
          title={
            <a target="_blank" rel="noreferrer" href={data}>
              {data}
            </a>
          }
          placement="top"
        >
          <a target="_blank" rel="noreferrer" href={data}>
            {data}
          </a>
        </Tooltip>
      ),
    },
    {
      title: "Fecha alta",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => newDate(data).toFormat("dd'-'LL'-'yyyy T"),
    },
    {
      title: "Scoring",
      dataIndex: "scoring",
      key: "scoring",
    },
    {
      title: "Editar",
      key: "editar",
      render: (_, record) => (
        <UpdatePage record={record} updatePage={updatePage} />
      ),
    },
  ];

  return (
    <div style={{ margin: "25px 0" }}>
      <Table
        columns={columns}
        dataSource={pages}
        onChange={handleTableChange}
        pagination={pagination}
        size="small"
        bordered={true}
        rowKey={(record) => (record?.id)}
      />
    </div>
  );
};

export default PagesList;
