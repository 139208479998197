import React from "react";
import Header from "../Navbar/Navbar";
import Home from "./Home";
import { Layout } from "antd";

const HomePage = React.memo(()=> {
  const { Content } = Layout;

  return (
    <>
      <Header title="Welcome" />
      <Content style={{ padding: "30px" }}>
        <div className="site-layout-content">
          <Home />
        </div>
      </Content>
    </>
  );
})

export default HomePage;
