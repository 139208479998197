import React, { useContext, useEffect, useState } from "react";
import { Table, Tag, Button, Popconfirm, Divider, Row } from "antd";
import { ProjectsContext } from "../../../../contexts/projects/ProjectsContext";
import { UsersContext } from "../../../../contexts/users/UsersContext";
import { AuthContext } from "../../../../contexts/auth/AuthContext";

import UpdateUsers from "./UpdateUser/UpdateUsers";
import Filter from "../../../../components/Utils/Table/Filter/Filter";
import AvatarProfile from "../../../Profile/AvatarProfile";

const UsersList = () => {
  const { selectedProject } = useContext(ProjectsContext);
  const { users, fetchUsers, deleteUserFromProject } = useContext(UsersContext);
  const {
    auth: { user },
  } = useContext(AuthContext);
  const [setFilters] = useState(null);

  // const [data, setData] = useState(null);

  const handleFilter = () => {
    console.log("gooo filter");
  };

  const handleDelete = (record) => {
    const userData = { ...record };
    userData.proyecto_actual = selectedProject.id;
    deleteUserFromProject(userData);
  };

  useEffect(() => {
    if (selectedProject?.id) {
      let params = {};

      if (user.clients?.length) {
        params.clients = user.clients;
      }

      if (user.projects?.length) {
        params.projects = user.projects;
      }

      if (user.agency?.length) {
        params.agency = user.agency;
      }

      fetchUsers(params);
    }
  }, [selectedProject, fetchUsers]);

  const columns = [
    {
      title: "Avatar",
      // dataIndex: "name",
      key: "avatar",
      render: (_, record) => {
        return <AvatarProfile user={record} />;
      },
      align: "center",
      width: "5px",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Primer Apellido",
      dataIndex: "first_lastname",
      key: "first_lastname",
    },
    {
      title: "Segundo Apellido",
      dataIndex: "second_lastname",
      key: "second_lastname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Rol",
      key: "role",
      dataIndex: "role",
      render: (data) => <Tag color={"green"}>{data?.toUpperCase()}</Tag>,
    },
    {
      title: "Editar",
      key: "editar",
      align: "center",
      width: "10%",
      render: (text, record) => <UpdateUsers data={record} />,
    },
    {
      title: "Eliminar",
      key: "eliminar",
      align: "center",
      width: "10%",
      render: (text, record) => (
        <Popconfirm
          title="¿Estás seguro?"
          okText="Si"
          cancelText="No"
          onConfirm={() => handleDelete(record)}
        >
          <Button type="primary">Eliminar</Button>
        </Popconfirm>
      ),
    },
  ];

  const fields = [{ label: "Nombre/Ref", type: "text", key: "usuario" }];

  return (
    <>
      <Row>
        <Row
          justify="space-between"
          style={{ minWidth: "100%", alignItems: "center" }}
        >
          <Filter
            setFilters={setFilters}
            fields={fields}
            handleFilter={handleFilter}
          />
        </Row>

        <Divider style={{ margin: "15px 0" }} />
      </Row>

      <Table
        columns={columns}
        dataSource={users}
        pagination={false}
        size="small"
        bordered={true}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default UsersList;
