// react
import { useState } from "react";

// misc
import { errorNotification } from "../components/Utils/Notification/Notification";
// import { VisitContext } from "../contexts/sockets/visits/VisitsContext";
import { fetchDataToken } from "../helpers/fetch";

const useVisits = () => {
    const [visitsMongoDB, setVisitsMongoDB] = useState({ visits: [], count: 0 });
    // const [visitsMongoDBTodayOffline, setVisitsMongoDBTodayOffline] = useState({ visits: [] });
    // const { visitsMongoDBTodayOffline, setVisitsMongoDBTodayOffline } = useContext(VisitContext);

    const fetchVisits = async (params = {}) => {
        try {
            const response = await fetchDataToken("/visit", params);
            if (response.status === 200) {
                setVisitsMongoDB(response.data);
            }
        } catch (error) {
            if (error.response) {
                errorNotification(error.response.data.msg);
                console.error(error.response);
            } else if (error.request) {
                console.error(error.request);
            } else {
                console.error("Error", error);
            }
            return { visits: [], count: 0 };
        }
    };





    return {
        fetchVisits,
        visitsMongoDB,
        setVisitsMongoDB,
        // fetchVisitsTodayOffline,
        // visitsMongoDBTodayOffline,
        // setVisitsMongoDBTodayOffline,
    };
};

export default useVisits;
