import React, { useState, useEffect } from "react";

const TagTotalVisits = ({ record, getTotalTagVisits }) => {


  const [total, setTotal] = useState(0);

  const getTotal = async () => {
    try {
      const res = await getTotalTagVisits(record?.id);
      setTotal(res);

    } catch (error) {
      console.error(error);
    }
  };


  useEffect( () => {
    getTotal();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {total}
    </>
  );
};

export default TagTotalVisits;
