// react
import React from "react";

import UsersList from "./UsersList";
import useUsers from "../../hooks/useUsers";
import AddUser from "./form/AddUser"

export default function UsersSection() {
  const { users, fetchUsers, loading, addUser, updateUser, deleteUser, paginationUsers} = useUsers()

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontSize: "24px", margin: " 0 0 10px 5px" }}>
          <strong>Gestión</strong> de usuarios
        </span>
        <AddUser addUser={addUser} />
      </div>

      <UsersList
        users={users}
        fetchUsers={fetchUsers}
        loading={loading}
        updateUser={updateUser}
        deleteUser={deleteUser}
        paginationUsers={paginationUsers}
      />
    </>
  );
}
