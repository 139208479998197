import React, { createContext } from "react";
import useUsers from "../../hooks/useUsers";

export const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const {
    users,
    addUser,
    fetchUsers,
    setUsers,
    deleteUserFromProject,
    updateUser,
  } = useUsers();

  return (
    <UsersContext.Provider
      value={{
        users,
        addUser,
        fetchUsers,
        setUsers,
        deleteUserFromProject,
        updateUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
