// reac t
import { useCallback, useContext, useState } from "react";

// misc
import { errorNotification, successNotification } from "../components/Utils/Notification/Notification";
import { ProjectsContext } from "../contexts/projects/ProjectsContext";
import { fetchDataToken } from "../helpers/fetch";

const useVisitants = () => {
  const [leads, setLeads] = useState({ leads: [], count: 0 });
  const [todaysLeadCount, setTodaysLeadCount] = useState(0);
  const { selectedProject } = useContext(ProjectsContext);

  const updateVisitant = useCallback(async (data) => {
    try {
      const response = await fetchDataToken("/visitor", data, "PUT");
      if (response.status === 200) {
        successNotification("Lead añadido!");
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, []);

  const fetchLeads = async (params = {}) => {
    try {
      const response = await fetchDataToken("/visitor/leads", params);
      if (response.status === 200) {
        setLeads(response.data);
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  };

  const fetchTodaysLeadCount = useCallback(async () => {
    try {
      const response = await fetchDataToken(
        "/visitor/leads/today-count",
        { project: selectedProject?.id },
        "GET"
      );
      if (response.status === 200) {
        setTodaysLeadCount(response.data.count);
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    } // eslint-disable-next-line
  }, [selectedProject]);

  const deleteLead = useCallback(async (id) => {
    try {
      const response = await fetchDataToken(`/visitor/leads/${id}`, {}, "DELETE");

      if (response.status === 204) {
        setLeads((prev) => ({ leads: prev.leads.filter((l) => l.id !== id), count: prev.count - 1 }));
      }
    } catch (error) {
      if (error.response) {
        errorNotification("Error", error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  }, []);

  return { updateVisitant, fetchLeads, leads, setLeads, todaysLeadCount, fetchTodaysLeadCount, deleteLead };
};

export default useVisitants;
