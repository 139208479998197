import React from "react";
import useChannels from "../../../../hooks/useChannels";
import AddChannel from "./AddChannel/AddChannel";
import ChannelList from "./ChannelList";

export default function ChannelsSection() {
  
  const { addChannel, fetchChannels, channels, deleteChannel, updateChannel } = useChannels();

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
      <span style={{ fontSize: "24px", margin: " 0 0 10px 5px" }}>
        <strong>Gestión</strong> de canales
      </span>
        <AddChannel addChannel={addChannel} />
      </div>
  
      <ChannelList
        fetchChannels={fetchChannels}
        channels={channels}
        deleteChannel={deleteChannel}
        updateChannel={updateChannel}
      />
  
    </>
  );
}
