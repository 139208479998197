// react
import React, { useEffect, useState, useCallback } from "react";

// styles
import { Table, Divider, Row, Popconfirm, Button } from "antd";

// helpers
import { newDate } from "../../helpers/date";
import { sorterByLetters } from "../../helpers/sorter";
import Filter from "../Utils/Table/Filter/Filter";

// components
import TableHead from "../Utils/Table/TableHead";
import TableCell from "../Utils/Table/TableCell.js";
import Popup from "../Table/RowPopup/Popup";
import ShowModal from "../Table/RowPopup/ShowModal";
import UpdateUser from "./form/UpdateUser";

const UsersList = ({
  users,
  fetchUsers,
  loading,
  paginationUsers,
  updateUser,
  deleteUser,
}) => {
  const [popup, setPopup] = useState({
    visible: false,
    x: 0,
    y: 0,
    showModal: false,
    option: "",
  });

  const onRow = (record) => {
    return {
      onContextMenu: (event) => {
        event.preventDefault();
        if (!popup.visible) {
          if (!popup.showModal) {
            document.addEventListener(`click`, function onClickOutside() {
              setPopup((prev) => ({ ...prev, visible: false }));
              document.removeEventListener(`click`, onClickOutside);
            });
          }
        }
        setPopup({
          record,
          visible: true,
          x: event.pageX,
          y: event.pageY,
          showModal: false,
        });
      },
    };
  };

  const [filters, setFilters] = useState(null);

  const loadUsersDataOnlyOnce = useCallback(() => {
    fetchUsers({ populate: "created_by_user,agency,projects,clients" });
  }, [fetchUsers]);

  useEffect(() => {
    loadUsersDataOnlyOnce();
  }, [loadUsersDataOnlyOnce]);

  const columns = [
    {
      title: "Fecha alta",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => newDate(data).toFormat("dd'-'LL'-'yyyy T"),
      width: "160px",
    },
    {
      title: <TableHead title="Nombre" align="left" data="name" />,
      dataIndex: "name",
      render: (_, record) => (
        <TableCell data={`${record.name} ${record.first_lastname}`} />
      ),
      sorter: (a, b) => sorterByLetters(a.name, b.name),
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Rol" align="left" />,
      dataIndex: "role",
      render: (data) => <TableCell data={data} />,
      sorter: (a, b) => sorterByLetters(a.role, b.role),
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Agencia" align="left" />,
      dataIndex: "agency",
      render: (data) => {
        // console.log("data agency", data);
        return <TableCell data={data?.name || "N/A"} />;
      },
      sorter: (a, b) => sorterByLetters(a.name, b.name),
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Proyectos vinculados" align="left" />,
      dataIndex: "projects",
      render: (data) => {
        // console.log('projects - data: ', data);
        return <TableCell data={data?.length || 0} />;
      },
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Email" align="left" />,
      dataIndex: "email",
      render: (data) => <TableCell data={data} />,
      sorter: (a, b) => sorterByLetters(a, b),
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Creador" align="left" />,
      dataIndex: "created_by_user",
      render: (data) => <TableCell data={data?.email || 'Sistema'} />,
      sorter: (a, b) => sorterByLetters(a?.email, b?.email),
      showSorterTooltip: false,
    },
    {
      title: "Editar",
      key: "editar",
      align: "center",
      width: "10%",
      render: (_, record) => (
        <UpdateUser key={record?.id} record={record} updateUser={updateUser} />
      ),
    },
    {
      title: "Eliminar",
      key: "eliminar",
      align: "center",
      width: "10%",
      render: (_, record) => (
        <Popconfirm
          title={
            <>
              Al eliminar se borrarán todos los datos relacionados con el
              usuario <br /> ¿Estás seguro?
            </>
          }
          okText="Si"
          cancelText="No"
          onConfirm={() => deleteUser(record?.id)}
        >
          <Button type="primary">Eliminar</Button>
        </Popconfirm>
      ),
    },
  ];

  const handleFilter = () => {
    const params = {
      ...filters,
      page: paginationUsers?.page,
      pageSize: paginationUsers?.limit,
    };
    fetchUsers(params);
  };

  const fields = [{ label: "Nombre/Ref", type: "text", key: "name" }];

  return (
    <>
      <Row>
        <Row
          justify="space-between"
          style={{ minWidth: "100%", alignItems: "center" }}
        >
          <Filter
            setFilters={setFilters}
            fields={fields}
            handleFilter={handleFilter}
          />
        </Row>

        <Divider style={{ margin: "15px 0" }} />
      </Row>

      <Table
        columns={columns}
        dataSource={users}
        paginationUsers={paginationUsers}
        size="small"
        bordered={true}
        rowKey={(record) => record?.id}
        loading={loading}
        onRow={onRow}
      />
      <Popup
        record={popup.record}
        visible={popup.visible}
        x={popup.x}
        y={popup.y}
        setPopup={setPopup}
        RT={false}
      />
      <ShowModal
        show={popup.showModal}
        setPopup={setPopup}
        popup={popup}
        record={popup.record}
      />
    </>
  );
};

export default UsersList;
