export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const ACCESS_TOKEN_NAME = process.env.REACT_APP_ACCESS_TOKEN_NAME;
export const API_SOCKET_URL = process.env.REACT_APP_API_SOCKET_URL;
export const PIXEL_DOMAIN = process.env.REACT_APP_PIXEL_DOMAIN;
export const CURRENT_USER_ID = process.env.REACT_APP_CURRENT_USER_ID;
export const ROLES_MAP = [
  { value: "admin", text: "Administrador" },
  { value: "agency_admin", text: "Administrador de agencia" },
  { value: "client_admin", text: "Administrador de cliente" },
  { value: "agency_agent", text: "Agente de agencia" },
  { value: "client_agent", text: "Agente de cliente", default: true },
];
