import React from "react";
import "./proyectos.css";

const Proyectos = ({ record }) => {
//  console.log('record', record)

  return (
    <ul>
      {record.proyectos?.map((p) => (
        <li>{p?.nombre}</li>
      ))}
    </ul>
  );
};

export default Proyectos;
