import React, { useContext, useEffect, useMemo, useState } from "react";
// import Cards from "../../Utils/Cards/Cards";
// import useVisitants from "../../../../hooks/useVisitants";
import TableSellers from "./Table/Sellers";
import { ProjectsContext } from "../../../contexts/projects/ProjectsContext";
import { Row, Divider, Col } from "antd";
// import Filter from "../../../Table/Filter/Filter";
import useUsersSession from "../../../hooks/useUsersSessions";
// import { formatFromMillisToTimeSession } from "../../../helpers/date";
import Filter from "../../../components/Utils/Table/Filter/Filter";

import StatisticWidget from "../../Emilus-theme/components/StatisticWidget";

const AnalyzeSellers = () => {
  const { selectedProject } = useContext(ProjectsContext);
  const { fetchUsersSession, usersSession, usersFromProject, loading } =
    useUsersSession();
  const [filters, setFilters] = useState(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} sesiones`,
    showSizeChanger: true,
    position: ['bottomCenter']
  });

  const handleTableChange = (onChangePagination) => {
    if (
      onChangePagination.current !== pagination.current ||
      onChangePagination.pageSize !== pagination.pageSize
    ) {
      setPagination((prev) => ({ ...prev, ...onChangePagination }));
      const params = {
        ...filters,
        project: selectedProject?.id,
        page: onChangePagination.current,
        pageSize: onChangePagination.pageSize,
      };
      fetchUsersSession(params);
    }
  };

  // const handleFilter = () => {
  //   const params = {
  //     // ...filters,
  //     project: selectedProject?.id,
  //     page: pagination.current,
  //     pageSize: pagination.pageSize,
  //   };
  //   fetchUsersSession(params);
  // };

  useEffect(() => {
    if (selectedProject?.id && pagination) {
      const params = {
        project: selectedProject?.id,
        page: pagination.current,
        pageSize: pagination.pageSize,
      };
      fetchUsersSession(params);
    }
    // eslint-disable-next-line
  }, [selectedProject?.id]);

  useEffect(() => {
    if (usersSession) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: usersSession.count,
      }));
    }
  }, [usersSession]);

  const data = useMemo(
    () => [
      {
        title: "Total sesiones",
        key: "totalSessions",
        value: usersSession?.count || 0,
      },
    //   {
    //     title: "Total tiempo de sesiones",
    //     key: "totalSessionTime",
    //     value: formatFromMillisToTimeSession(usersSession?.totalSessionTime),
    //   },
      {
        title: "Total asistencias realizadas",
        key: "totalAssistsMade",
        value: usersSession?.totalAssistsMade || 0,
      },
      {
        title: "Total respuestas obtenidas",
        key: "totalResponsesObtained",
        value: usersSession?.totalResponsesObtained || 0,
      },
      {
        title: "Total leads captados",
        key: "totalLeadsCaptured",
        value: usersSession?.totalLeadsCaptured || 0,
      },
      // {
      //   title: "Sesiones por día",
      //   key: "Chats",
      //   value: <SplineArea labels={usersSession?.labels} values={usersSession?.values} />,
      // },
      // { title: "Total páginas vistas", key: "totalPaginasVistas" },
    ],
    [usersSession]
  );
  const handleFilter = () => {
    const params = {
      ...filters,
      date_from:
        filters?.date_from && new Date(filters?.date_from).toISOString(),
      date_to: filters?.date_to && new Date(filters?.date_to).toISOString(),
      project: selectedProject?.id,
      page: pagination.current,
      pageSize: pagination.pageSize,
    };
    fetchUsersSession(params);
  };
  const fields = [
    // { label: "Nombre", type: "text" },
    // { label: "Primer apellido", type: "text" },
    // { label: "Segundo apellido", type: "text" },
    // { label: "Email", type: "text" },
    { label: "Fecha inicial", type: "date_from", key: "date_from" },
    { label: "Fecha final", type: "date_to", key: "date_to" },
    {
      label: "Gestor",
      type: "select",
      key: "user",
      data: usersFromProject,
      placeholder: "Selecciona un gestor",
      keyToOptions: "id",
    },
  ];

  return (
    <TableSellers
      title={
        <>
          <Row
            justify="space-between"
            style={{ minWidth: "100%", alignItems: "center" }}
          >
            <span style={{ fontSize: "24px", margin: "0 0 15px 0px" }}>
              <strong>Actividad</strong> de los gestores
            </span>
          </Row>

          <>
            <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
              {data?.map((_data) => {
                return (
                  <Col key={_data?.key}>
                    <StatisticWidget
                      title={_data?.title}
                      value={(_data?.value ?? 0).toString()}
                    />
                  </Col>
                );
              })}
            </Row>
          </>

          <Row>
            <Row
              justify="space-between"
              style={{ minWidth: "100%", alignItems: "center" }}
            >
              <Filter
                setFilters={setFilters}
                fields={fields}
                handleFilter={handleFilter}
              />
            </Row>

            <Divider style={{ margin: "15px 0" }} />
          </Row>
        </>
      }
      usersSession={usersSession?.usersSessions}
      pagination={pagination}
      handleTableChange={handleTableChange}
      loading={loading}
    />
  );
};

export default AnalyzeSellers;
