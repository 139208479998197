import React from "react";

import { AgencyLayout } from "../../../components/Utils/Layout";
import WebhooksSection from "../../../components/Webhooks/WebhooksSection";

const WebhookPage = ({ auth, logout }) => {
  return (
    <AgencyLayout content_title="Webhooks" auth={auth} logout={logout}>
      <WebhooksSection />
    </AgencyLayout>
  );
};

export default WebhookPage;
