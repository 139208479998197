// react
import React, { useContext, useState } from "react";

// styles
import { Button, Divider, Drawer } from "antd";
import {
  PoweroffOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";

// auth
import { AuthContext } from "../../../contexts/auth/AuthContext";

// misc
import PixelProject from "../pixelProject/PixelProject";
import { Link, useParams } from "react-router-dom";
import AvatarProfile from "../../Profile/AvatarProfile";
import { AgencyContext } from "../../../contexts/agency/AgencyContext";
import ManagersOnline from "../managersOnline/ManagersOnline";
import { ProjectsContext } from "../../../contexts/projects/ProjectsContext";
import VisitsActive from "../visitsActive/visitsActive";

import { includes } from "lodash";

const UserOptions = () => {
  const { auth, logout } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const { selectedProject } = useContext(ProjectsContext);
  const { usersOnline } = useContext(AgencyContext);

  let { slug } = useParams();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleLogout = async () => {
    await logout();
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {selectedProject?.id && <VisitsActive />}
      {selectedProject?.id && (
        <ManagersOnline users={usersOnline} selfUser={auth.user} />
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        {!auth.isExternal ? (
          <div >
            <AvatarProfile user={auth.user} profile={true} />
          </div>
        ) : null}

        <Button onClick={showDrawer} type="text" style={{ height: "100%" }}>
          <SettingOutlined style={{ fontSize: "24px" }} />
        </Button>

        <Drawer
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <AvatarProfile user={auth.user} profile={true} />

              <span style={{ margin: "0px 0px 0px 10px", fontSize: "18px" }}>
                Perfil
              </span>
            </div>
          }
          onClose={onClose}
          visible={visible}
          placement="right"
          maskStyle={{ opacity: "0", backgroundColor: "rgba(0,0,0,0)" }}
        >
          <Link to="/profile">
            <Button type="text" block>
              <UserOutlined style={{ fontSize: "18px" }} />
              <span style={{ fontSize: "17px" }}>Mi perfil</span>
            </Button>
            <Divider style={{ margin: "10px 0px" }} />
          </Link>

          {includes(
            ["admin", "client_admin", "agency_admin"],
            auth?.user?.role
          ) && slug ? (
            <PixelProject />
          ) : null}

          {includes(["admin"], auth.user.role) ? (
            <Link to="/admin">
              <Button type="text" block>
                <UserOutlined style={{ fontSize: "18px" }} />
                <span style={{ fontSize: "17px" }}>Administración</span>
              </Button>
              <Divider style={{ margin: "10px 0px" }} />
            </Link>
          ) : null}

          {includes(["agency_admin"], auth.user.role) ? (
            <Link to="/agency">
              <Button type="text" block>
                <UserOutlined style={{ fontSize: "18px" }} />
                <span style={{ fontSize: "17px" }}>Agencia</span>
              </Button>
              <Divider style={{ margin: "10px 0px" }} />
            </Link>
          ) : null}

          {includes(["client_admin"], auth.user.role) ? (
            <Link to="/client">
              <Button type="text" block>
                <UserOutlined style={{ fontSize: "18px" }} />
                <span style={{ fontSize: "17px" }}>Cliente</span>
              </Button>
              <Divider style={{ margin: "10px 0px" }} />
            </Link>
          ) : null}

          <Button onClick={handleLogout} type="text" block>
            <PoweroffOutlined style={{ fontSize: "18px" }} />
            <span style={{ fontSize: "17px" }}>Salir</span>
          </Button>
        </Drawer>
      </div>
    </div>
  );
};

export default UserOptions;
