import React, { memo, useMemo, useState } from "react";
import { Modal } from "antd";
import HistoryNavigation from "./HistoryNavigation";
import AddLead from "./AddLead";
import { newDate } from "../../../../helpers/date"
import "./ShowModal.css";

const ShowModal = memo(({ show, setPopup, record, popup }) => {
  const handleCancel = () => {
    setPopup((prev) => ({ ...prev, showModal: false }));
  };
  const [title, setTitle] = useState("");

  const renderSwitch = useMemo(() => {
    switch (popup.option) {
      case "history":
        setTitle(
          `Navegación de ${
            record.visitor.first_lastname ? record.visitor.first_lastname : `#${record.visitor?.id?.slice(-6)}`
          } el ${newDate(record.createdAt).toFormat("dd/LL/yy")}`
        );
        return <HistoryNavigation record={record} />;
      case "addLead":
        if (record.visitor.name) {
          setTitle("Ver lead");
        } else {
          setTitle("Añadir lead");
        }
        return <AddLead record={record} handleCancel={handleCancel} />;
      default:
        return;
    }
    //eslint-disable-next-line
  }, [popup, record]);

  return (
    <>
      <Modal
        className="modal-rowpopup"
        visible={show}
        title={title}
        onCancel={handleCancel}
        cancelText="Cancelar"
        onOk={handleCancel}
        maskStyle={{
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1000,
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        }}
        destroyOnClose={true}
        maskClosable={false}
      >
        {renderSwitch}
      </Modal>
    </>
  );
});

export default ShowModal;
