import React, { useState } from "react";
import { Form, Input, InputNumber, Modal } from "antd";
import "./updatePage.css";

const UpdatePageForm = ({ visible, setVisible, updatePage, record }) => {
  const [form] = Form.useForm();
  const [input, setInput] = useState({
    name: "",
    url: "",
    scoring: "",
  });
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    try {
      setConfirmLoading(true);
      const data = await form.validateFields();
      await updatePage(record?.id, data);
      setVisible(false);
      setConfirmLoading(false);
      form.resetFields();
      setInput({});
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <Modal
      title="Editar página"
      visible={visible}
      onOk={handleOk}
      okText="Guardar"
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      cancelText="Cancelar"
    >
      <Form
        form={form}
        labelCol={{ span: 12 }}
        onFinish={handleOk}
        onFinishFailed={() => {
          console.error("form failed");
        }}
        initialValues={record}
      >
        <Form.Item
          label="Nombre"
          name="name"
          id="name"
          onChange={handleChange}
          rules={[{ required: true, message: "Es necesario!" }]}
          labelAlign="left"
        >
          <Input placeholder="Nombre" value={input.name} />
        </Form.Item>
        <Form.Item
          label="URL"
          name="url"
          id="url"
          onChange={handleChange}
          rules={[{ required: true, message: "Es necesario!" }]}
          labelAlign="left"
        >
          <Input placeholder="URL" value={input.url} disabled={true} />
        </Form.Item>
        <Form.Item
          label="Scoring"
          name="scoring"
          id="scoring"
          onChange={handleChange}
          rules={[{ required: true, message: "Es necesario!" }]}
          labelAlign="left"
        >
          <InputNumber placeholder="Scoring" value={input.scoring} min={0} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdatePageForm;
