import React, { useContext, useEffect } from "react";
import { Col, Row } from "antd";
import StatisticWidget from "../../Emilus-theme/components/StatisticWidget";
import { VisitContext } from "../../../contexts/visits/VisitsContext";
import { ProjectsContext } from "../../../contexts/projects/ProjectsContext";
import { AgencyContext } from "../../../contexts/agency/AgencyContext";
import useDashboard from "../../../hooks/useDashboard";
import ChartWidget from "../../Emilus-theme/components/ChartWidget";
// import GoalWidget from "../../Emilus-theme/components/GoalWidget";
import HeadMapWidget from "../../Emilus-theme/components/HeadMapWidget";
// import ApexChart from "react-apexcharts";
// import { apexLineChartDefaultOption, COLOR_2 } from "../../Emilus-theme/constants/ChartConstant";
import Loading from "../../Utils/Loading/Loading";

// const memberChartOption = {
//   ...apexLineChartDefaultOption,
//   ...{
//     chart: {
//       sparkline: {
//         enabled: true,
//       },
//     },
//     colors: [COLOR_2],
//   },
// };

const Dashboard = () => {
  const { visitState, fetchVisitsTodayOnline } = useContext(VisitContext);
  const { selectedProject } = useContext(ProjectsContext);
  const { usersOnline } = useContext(AgencyContext);
  const {
    conversationsToday,
    series,
    categories,
    leadsToday,
    loading,
    categoriesAverageCountVisits,
    seriesAverageCountVisits,
    seriesCountActivity,
  } = useDashboard();

  useEffect(() => {
    if (selectedProject?.pixel_id) {
      fetchVisitsTodayOnline({
        pixel: selectedProject?.pixel_id,
        online: true,
      });
    }
  }, [fetchVisitsTodayOnline, selectedProject?.pixel_id]);

  // const {visitsOnline} = useDashboard()
  return loading ? (
    <Loading fullHeight={false} />
  ) : (
    <div>
      <Row>
        <span style={{ fontSize: "20px", margin: "0 0 0 0px" }}>
          Resultados de la <b>actividad</b>
        </span>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col span={24}>
          <Row gutter={24}>
            {/* {
              annualStatisticData.map((elm, i) => (
                <Col xs={24} sm={24} md={24} lg={24} xl={8} key={i}>
                  <StatisticWidget
                    title={elm.title}
                    value={elm.value}
                    status={elm.status}
                    subtitle={elm.subtitle}
                  />
                </Col>
              ))
            } */}
            <Col gutter={16}>
              {/* <GoalWidget */}
              <StatisticWidget
                title="Leads captados hoy"
                value={
                  `${
                    (parseFloat(leadsToday?.leadsPercent) < 100.0
                      ? (parseFloat(leadsToday?.leadsPercent)?.toFixed(2) || 0)?.toString()
                      : "100") || "0"
                  }%` || "0%"
                }
                // subtitle={
                //   leadsToday?.newLeads > 0
                //     ? `Se han captado ${leadsToday?.newLeads} de los ${leadsToday?.newVisitants} visitantes nuevos de hoy`
                //     : `No ha habido ningún lead captado de los ${leadsToday?.newVisitants} visitantes nuevos de hoy`
                // }
                // extra={<Button type="primary">Learn More</Button>}
              />
            </Col>
            <Col gutter={16}>
              <StatisticWidget
                title={"Usuarios conectados ahora"}
                value={visitState?.visitsCount?.toString() || "0"}
                // status={"-11.4"}
                // subtitle={"Compare to last year (2019)"}
              />
            </Col>
            <Col gutter={16}>
              <StatisticWidget
                title={"Vendedores conectados ahora"}
                value={usersOnline?.length?.toString() || "0"}
                // status={"-11.4"}
                // subtitle={"Compare to last year (2019)"}
              />
            </Col>
            <Col gutter={16}>
              <StatisticWidget
                title={"Conversaciones mantenidas hoy"}
                value={conversationsToday?.toString() || "0"}
                // status={"-11.4"}
                // subtitle={"Compare to last year (2019)"}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "5px" }} gutter={12}>
            <Col span={12}>
              <ChartWidget
                title="Evolución de las visitas"
                series={series}
                xAxis={categories}
                height={"250px"}
                direction="rtl"
                bodyClass="chart-dashboard"
                customOptions={{
                  noData: {
                    text: "Cargando",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      color: undefined,
                      fontSize: "14px",
                      fontFamily: undefined,
                    },
                  },
                }}
              />
            </Col>
            <Col span={12}>
              <ChartWidget
                title="Número de veces que nos visita el usuario"
                series={seriesAverageCountVisits}
                xAxis={categoriesAverageCountVisits}
                height={"250px"}
                direction="rtl"
                type="bar"
                bodyClass="chart-dashboard"
                customOptions={{
                  noData: {
                    text: "Cargando",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      color: undefined,
                      fontSize: "14px",
                      fontFamily: undefined,
                    },
                  },
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "5px" }} gutter={12}>
            <Col span={24}>
              <HeadMapWidget
                title="Conexiones por hora en el dia"
                series={seriesCountActivity}
                height={"150px"}
                direction="rtl"
                type="heatmap"
                bodyClass="chart-dashboard"
                customOptions={{
                  noData: {
                    text: "Cargando",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      color: undefined,
                      fontSize: "14px",
                      fontFamily: undefined,
                    },
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
