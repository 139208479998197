export const sorterByLetters = (a, b) => {
  return b?.localeCompare(a);
};

export const sorterByNumbers = (a, b) => {
  return a - b;
};

export const sorterByDate = (a, b) => {
  return new Date(b) - new Date(a);
};
