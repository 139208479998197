import React from "react";

import { AgencyLayout } from "../../../components/Utils/Layout";
import ClientsSection from "../../../components/Clients/ClientsSection";

const ClientPage = ({auth, logout}) => {
  return (
    <AgencyLayout content_title="Clientes" auth={auth} logout={logout}>
      <ClientsSection/>
    </AgencyLayout>
  );
};

export default ClientPage;
