// react
import { useCallback, /* useContext */ useState } from "react";

// misc
import { errorNotification /* successNotification */ } from "../components/Utils/Notification/Notification";
import { fetchDataToken } from "../helpers/fetch";

// auth
import /* AuthContext */ "../contexts/auth/AuthContext";
import useUsers from "./useUsers";

const useUsersSession = () => {
  // const { auth } = useContext(AuthContext);
  const [usersSession, setUsersSession] = useState(null);
  const [usersFromProject, setUsersFromProject] = useState(null);
  const { fetchUsersFromProject } = useUsers();
  const [loading, setLoading] = useState(true);

  const fetchUsersSession = useCallback(
    async (data) => {
      try {
        setLoading(true);
        const response = await fetchDataToken(`/user-session/project/${data.project}/all-user-session`, data, "GET");
        if (response.status === 200) {
          setUsersSession(response.data);
          setUsersFromProject(await fetchUsersFromProject({ projects: [data.project] }));
          setLoading(false);
        }
      } catch (error) {
        if (error.response) {
          errorNotification("Error", error.response.data.msg);
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error.message);
        }
        setLoading(false);
      }
    },
    [fetchUsersFromProject]
  );

  return { usersSession, fetchUsersSession, usersFromProject, loading };
};

export default useUsersSession;
