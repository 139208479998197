import React, { memo } from "react";
import { Layout } from "antd";
import Navbar from "../Navbar/Navbar";
import Profile from "./Profile";

const ProfilePage = memo(() => {
  const { Content } = Layout;

  return (
    <>
      <Navbar title="Webseller" />
      <Content style={{ padding: "30px", backgroundColor: "#fbfbfb", minHeight: "91.7vh" }}>
        <div
          className="site-layout-content"
          style={{
            margin: "0 25vw",
            padding: "30px 5vw",
            backgroundColor: "white",
            borderRadius: "15px",
            minHeight: "50vh",
          }}
        >
          <Profile />
        </div>
      </Content>
    </>
  );
});

export default ProfilePage;
