// react
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

// misc
import { errorNotification } from "../../components/Utils/Notification/Notification";
import { fetchDataToken } from "../../helpers/fetch";
import { SocketContext } from "../sockets/SocketContext";
import { visitReducer, visitsActions } from "./visitReducer";

import _ from "lodash";

export const VisitContext = createContext();

const initialState = {
  visits: [],
  visitsCount: 0,
};

export const VisitProvider = ({ children }) => {
  const [visitState, dispatch] = useReducer(visitReducer, initialState);
  const [visitsMongoDBTodayOffline, setVisitsMongoDBTodayOffline] = useState(
    []
  );
  const { socket } = useContext(SocketContext);
  const [visitsActive, setVisitsActive] = useState({});
  const [visitsInactive, setVisitsInactive] = useState({});
  const [visitsTodayPagination, setVisitsTodayPagination] = useState({});

  const fetchVisitsTodayOffline = useCallback(async (params = {}) => {
    try {
      // params.sort = '{"createdAt":"-1"}';
      const response = await fetchDataToken("/visit", params);
      if (response.status === 200) {
        const {
          results,
          limit: pageSize,
          page: current,
          totalResults: total,
        } = response.data;
        setVisitsTodayPagination({ pageSize, current, total });
        setVisitsMongoDBTodayOffline(results || []);
      }
    } catch (error) {
      if (error.response) {
        errorNotification(error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error);
      }
    }
  }, []);

  const fetchVisitsActive = useCallback(async (project) => {
    try {
      const response = await fetchDataToken("/visit/active-inactive", {
        project,
      });

      if (response.status === 200) {
        setVisitsActive(response.data.active);
        setVisitsInactive(response.data.inactive);
      }
    } catch (error) {
      if (error.response) {
        // errorNotification(error.response.data.msg);
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error);
      }
    }
  }, []);

  const fetchVisitsTodayOnline = useCallback(async (params = {}) => {
    try {
      params.pagination = false;
      // params.sort = '{"createdAt":"-1"}';
      const response = await fetchDataToken("/visit", params);
      if (response.status === 200) {
        dispatch({
          type: visitsActions.loadVisits,
          payload: response.data?.results,
        });
      }
    } catch (error) {
      if (error.response) {
        console.error(error.response);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error);
      }
    }
  }, []);

  useEffect(() => {
    const project =
      localStorage.getItem("project") &&
      JSON.parse(localStorage.getItem("project"));

    socket?.on("connect-visit", (data) => {
      if (data.active) {
        setVisitsActive((prev) => ({
          ...prev,
          [data.visitor?.id]: true,
        }));
      } else {
        setVisitsInactive((prev) => ({
          ...prev,
          [data.visitor?.id]: false,
        }));
      }

      dispatch({
        type: visitsActions.addVisit,
        payload: data,
      });

      fetchVisitsTodayOffline({ pixel: project.pixel_id });
    });

    socket?.on("update-visit", (data) => {
      if (data?.active) {
        setVisitsActive((prev) => ({
          ...prev,
          [data.visitor?.id]: true,
        }));
        setVisitsInactive((prev) => {
          let newObject = Object.assign({}, prev);
          delete newObject[data.visitor?.id];
          return newObject;
        });
      } else {
        setVisitsInactive((prev) => ({
          ...prev,
          [data.visitor?.id]: false,
        }));
        setVisitsActive((prev) => {
          let newObject = Object.assign({}, prev);
          delete newObject[data.visitor?.id];
          return newObject;
        });
      }

      dispatch({
        type: visitsActions.updateVisit,
        payload: data,
      });
    });

    socket?.on("update-visit-tags", (data) => {
      const data_event = data || null;
      if (data_event && data_event !== {}) {
        // console.log("data_event", data_event);
        dispatch({
          type: visitsActions.updateItemsOfVisit,
          payload: data_event,
        });
      }
    });

    socket?.on("disconnect-visit", (data) => {
      if (data?.active) {
        setVisitsActive((prev) => {
          let newObject = Object.assign({}, prev);
          delete newObject[data.visitor?.id];
          return newObject;
        });
      } else {
        setVisitsInactive((prev) => {
          let newObject = Object.assign({}, prev);
          delete newObject[data.visitor?.id];
          return newObject;
        });
      }
      dispatch({
        type: visitsActions.deleteVisit,
        payload: data,
      });

      const project =
        localStorage.getItem("project") &&
        JSON.parse(localStorage.getItem("project"));
      fetchVisitsTodayOffline({ pixel: project.pixel_id });
    });

    return () => {
      socket?.removeAllListeners("connect-visit");
      socket?.removeAllListeners("update-visit");
      socket?.removeAllListeners("update-visit-tags");
      socket?.removeAllListeners("disconnect-visit");
    };
  }, [socket, dispatch, fetchVisitsTodayOffline]);

  return (
    <VisitContext.Provider
      value={{
        visitState,
        dispatch,
        visitsActions,
        visitsMongoDBTodayOffline,
        setVisitsMongoDBTodayOffline,
        fetchVisitsTodayOffline,
        fetchVisitsTodayOnline,
        fetchVisitsActive,
        visitsActive,
        visitsInactive,
        visitsTodayPagination,
        setVisitsTodayPagination,
      }}
    >
      {children}
    </VisitContext.Provider>
  );
};
