// react
import React, { memo } from "react";

// styles
import { Layout } from "antd";

// misc
import LoginForm from "../Login/LoginForm";
import Navbar from "../../Navbar/Navbar";

const LoginPage = memo(() => {
  const { Content } = Layout;
  return (
    <>
      <Layout>
        <Navbar title="Login" />
        <Content>
          <LoginForm />
        </Content>
      </Layout>
    </>
  );
});
export default LoginPage;
