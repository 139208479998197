import React, { useContext, useRef, useState } from "react";
import { SendOutlined } from "@ant-design/icons";
import { Input, Form, Button } from "antd";
import { SocketContext } from "../../../contexts/sockets/SocketContext";

const ChatFooter = ({ chat_id, startedChat}) => {
  const [message, setMessage] = useState(null);
  const { socket } = useContext(SocketContext);
  const [formMessage] = Form.useForm();
  const iMessageRef = useRef(null);

  const SendMessage = () => {
    if (socket && message) {

      const user_receiver = chat_id.split("_")[2];
      const user_sender = chat_id.split("_")[3];
      const dataMessage = {
        chat_id,
        user_sender,
        visitor: user_receiver,
        message: { text: message },
        interaction_type: "default",
      };

      socket.emit("message", dataMessage);
      setMessage("");
      formMessage.resetFields();
    }
    setTimeout(() => {
      iMessageRef.current.focus({ cursor: "start" });
    }, 10);
  };

  const handleMessageChange = (e) => {
    if (e.target.value.trim().length) {
      setMessage(e.target.value);
    }
  };

  return (
    <Form form={formMessage}>
      <div id="pixel-chat-footer">
        <div id="pixel-chat-input-message">
          <Form.Item
            name="message"
            value={message}
            onChange={handleMessageChange}
            rules={[{ required: true, message: "" }]}
          >
            <Input
              ref={iMessageRef}
              placeholder="Escribe un mensaje..."
              type="text"
              onPressEnter={SendMessage}
              // disabled={!startedChat}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item>
            <Button
              id="pixel-chat-button-send"
              onClick={SendMessage}
              className="chat-buttonSend"
              type="primary"
              disabled={!startedChat}
            >
              <SendOutlined style={{ fontSize: "20px", color: "white" }} />
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default ChatFooter;
