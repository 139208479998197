export function isValidUrl(string) {
  try {
    if (string === "android-app://com.google.android.gm/") return "Android Gmail App";
    else if (string === "android-app://com.google.android.googlequicksearchbox/")
      return "Android Google App";
    const urlParsed = new URL(string);
    return urlParsed.hostname;
  } catch (_) {
    return string;
  }
}
