import css from '../styles/leadcars.css';
// console.log('--------- css', css);
export const leadcars = css;

// export const leadcars = `
// div.ant-page-header {
//     background-color: #2A415A;
//   }

//   div.ant-page-header-heading-left>span>a {
//     color: white !important;
//   }

//   div.ant-page-header-heading-left>span.ant-page-header-heading-title {
//     color: #FFF !important;
//   }

//   div.ant-page-header-heading-left a.ant-dropdown-trigger {
//     color: #FFF !important;
//   }

//   ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
//     background-color: #AABAC8 !important;
//     color: #FFF !important;
//   }

//   li.ant-menu-item.ant-menu-item-selected {
//     background-color: #2A415A !important;
//     color: #FFF !important;
//   }

//   .ant-menu-inline .ant-menu-item::after,
//   .ant-menu-vertical .ant-menu-item::after,
//   .ant-menu-vertical-left .ant-menu-item::after,
//   .ant-menu-vertical-right .ant-menu-item::after {
//     border-right: none !important;
//   }

//   .ant-menu-item-active,
//   .ant-menu-item:hover,
//   .ant-menu-submenu-active,
//   .ant-menu-submenu-title:hover,
//   .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
//     color: #FFF !important;
//   }

//   .ant-btn-text {
//     color: #FFF;
//   }

//   .ant-drawer-content,
//   .ant-drawer-header {
//     background-color: #2A415A;
//   }

//   .ant-drawer-title div span {
//     color: #FFF !important;
//   }

//   div.ant-layout-sider-trigger {
//     display: none !important;
//   }

//   .ant-menu-submenu-popup>.ant-menu,
//   .ant-table-small .ant-table-thead>tr>th,
//   .ant-table-thead>tr>th {
//     background-color: #2A415A;
//     color: #FFF
//   }

//   .ant-menu-inline,
//   .ant-menu-vertical,
//   .ant-menu-vertical-left,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>.ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>.ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>.ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>.ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>.ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>.ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>.ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>.ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>.ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th {
//     /* border-right: 1px solid #CCCCCC !important; */
//   }

//   .ant-table table {
//     border-collapse: collapse !important;
//   }

//   .ant-table.ant-table-small .ant-table-footer,
//   .ant-table.ant-table-small .ant-table-tbody>tr>td,
//   .ant-table.ant-table-small .ant-table-thead>tr>th,
//   .ant-table.ant-table-small .ant-table-title,
//   .ant-table.ant-table-small tfoot>tr>td,
//   .ant-table.ant-table-small tfoot>tr>th {
//     padding: 4px !important;
//   }

//   .ant-btn-primary {
//     color: #fff;
//     background: #4A8FE2;
//     border-color: #4A8FE2;
//     text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
//     box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
//     border-radius: 0;
//   }

//   #pixel-chat-button-send {
//     width: 33px;
//     height: 33px;
//     border-radius: 25px;
//     align-items: center;
//     display: flex;
//     justify-content: center;
//     background-color: #A9CA58;
//     border: 1px solid #A9CA58;
//   }

//   div.ant-card {
//     background-color: #2A415A !important;
//     border-radius: 0 !important;
//   }

//   div.ant-card h2,
//   div.ant-card h5,
//   div.ant-card h4,
//   div.ant-card h1 {
//     color: #FFF;
//   }

//   div.chart-dashboard {
//     margin-top: 40px;
//   }

//   li.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open {
//     background-color: #2A415A !important;
//     color: #FFF !important;
//   }

//   ul.ant-menu.ant-menu-sub.ant-menu-inline {
//     color: #FFF !important;
//   }

//   li.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open i.ant-menu-submenu-arrow {
//     color: #FFF !important;
//   }

//   li.ant-menu-submenu.ant-menu-submenu-inline i.ant-menu-submenu-arrow {
//     color: #FFF !important;
//   }

//   .ant-page-header-heading-extra .counter-visit-container {
//     border-right: 2px solid white !important;
//   }
// `;
