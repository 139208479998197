// react
import { useCallback, useContext } from "react";

// misc
import { errorNotification } from "../../components/Utils/Notification/Notification";
import { ProjectsContext } from "../../contexts/projects/ProjectsContext";
import { fetchDataToken } from "../../helpers/fetch";

const useTagVisits = () => {
  const { selectedProject } = useContext(ProjectsContext);

  const getTotalTagVisits = useCallback(
    async (tag_id) => {
      try {
        if (selectedProject?.id) {
          const data = {
            tag: tag_id,
            project: selectedProject?.id,
          };

          const response = await fetchDataToken("/tag-visit/total-visits", data, "GET");
        //   console.log('getTotalTagVisits - response', response)

          if (response.status === 200) {
            return response.data?.total || 0;
          } else {
            return 0;
          }
        }
      } catch (error) {
        if (error.response) {
          errorNotification("Error", error.response.data.msg);
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error.message);
        }
      }
    },
    [selectedProject?.id]
  );

  return { getTotalTagVisits };
};

export default useTagVisits;
