// react
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useRouteMatch, Link } from "react-router-dom";

// styles
import { Button, Card, Col, Dropdown, Menu, Row, Modal } from "antd";
import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";

// context
import { ProjectsContext } from "../../../contexts/projects/ProjectsContext";

// auth
import { AuthContext } from "../../../contexts/auth/AuthContext";
import _ from "lodash";

const ProjectsList = () => {
  const { auth } = useContext(AuthContext);

  const clients = auth?.user?.clients;
  const agency = auth?.user?.agency?.id || auth?.user?.agency;

  const { projects, fetchProjects, deleteProject, selectProject } =
    useContext(ProjectsContext);
  let { path } = useRouteMatch();

  const handleDelete = async (data) => {
    deleteProject(data);
  };

  const handleSelectProject = (nombre) => {
    selectProject(nombre);
  };

  const loadProjectsDataOnlyOnce = useCallback(
    (params) => {
    //   console.log('params', params)
      fetchProjects({ ...params, populate: "created_by_user,agency,client" });
    },
    [fetchProjects]
  );

  useEffect(() => {
    loadProjectsDataOnlyOnce({ agency, clients });
  }, [agency, clients, loadProjectsDataOnlyOnce]);

  const MoreOptions = ({ project }) => {
    const { confirm } = Modal;
    async function showConfirm(project) {
      confirm({
        title: "Estás seguro que quieres eliminar el proyecto?",
        icon: <ExclamationCircleOutlined />,
        content: "Ten en cuenta que no se podrá recuperar.",
        okText: "Si",
        cancelText: "Cancelar",
        onOk() {
          handleDelete(project);
        },
        onCancel() {},
      });
    }
    const menu = (
      <Menu>
        {/* <Menu.Item key="0" onClick={() => editProject(project)}>
          Editar
        </Menu.Item> */}
        <Menu.Item key="1" onClick={() => showConfirm(project)}>
          Eliminar
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        size="small"
        style={{ paddingTop: "5px" }}
      >
        <Button style={{ border: "0px", padding: "0px 5px" }}>
          <MoreOutlined style={{ fontSize: "18px" }} />
        </Button>
      </Dropdown>
    );
  };

  return (
    <Row
      gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
      style={{ marginTop: "15px" }}
    >
      {projects?.length ? (
        <>
          {projects.map((project) => {
            return (
              <Col className="gutter-row" key={project.id}>
                <Card
                  title={project.name}
                  // extra={<MoreOptions project={project} />}
                  style={{
                    minWidth: "230px",
                    width: "16vw",
                    marginTop: "16px",
                    borderRadius: "10px",
                    border: "1px solid #dcdcdc",
                  }}
                >
                  <Row justify="space-between" key={project.id}>
                    <p>Cliente: {project.client.company_name}</p>
                    <Button type="primary">
                      <Link
                        to={{
                          pathname: `${path}/${project.name}`,
                        }}
                        onClick={() => handleSelectProject(project.name)}
                      >
                        Acceder
                      </Link>
                    </Button>
                  </Row>
                </Card>
              </Col>
            );
          })}
        </>
      ) : (
        <>
          <h2>No tienes proyectos</h2>
        </>
      )}
    </Row>
  );
};

export default ProjectsList;
