// react
import React, { useContext,  useState } from "react";

// styles
import { Col, Form, Input, Row, Modal } from "antd";
import "./updateUser.css";

// context
import { ProjectsContext } from "../../../../../contexts/projects/ProjectsContext"
import { UsersContext } from "../../../../../contexts/users/UsersContext";

// misc
// import UploadButton from "../../../../Utils/Upload/Upload";
// import DeleteButton from "../../../../Utils/Upload/Delete";

const UpdateUserForm = ({ visible, setVisible, data }) => {
  const { selectedProject } = useContext(ProjectsContext);
  const { updateUser } = useContext(UsersContext);
  const [form] = Form.useForm();

  const [input, setInput] = useState({
    id: data?.id,
    name: data.name,
    first_lastname: data.first_lastname,
    second_lastname: data.second_lastname,
    email: data.email,
    phone: data.phone,
    password: data.password,
    confirmPassword: data.password,
    avatar_color: Math.floor(Math.random()*16777215).toString(16),
  });

  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    
    try {
      const dataForm = await form.validateFields();
      const updateData = {
        ...dataForm,
        project: selectedProject.id,
        id: data?.id,
      };
      setConfirmLoading(true);
      await updateUser(updateData);
      setVisible(false);
      setConfirmLoading(false);
      form.resetFields();
      setInput({});
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <Modal
      title="Editar usuario"
      visible={visible}
      onOk={handleOk}
      okText="Guardar"
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      cancelText="Cancelar"
    >
      <Row type="flex" justify="center" align="middle">
        <Col>
          <Form
            form={form}
            labelCol={{ span: 12 }}
            onFinish={handleOk}
            onFinishFailed={() => {
              console.error("form failed");
            }}
            initialValues={data}
          >
            <Form.Item
              label="Nombre"
              name="name"
              id="name"
              onChange={handleChange}
              labelAlign="left"
              required={[{ required: true, message: "Es necesario!" }]}
            >
              <Input placeholder="Nombre" value={input.name} />
            </Form.Item>
            <Form.Item
              label="Primer apellido"
              name="first_lastname"
              id="first_lastname"
              onChange={handleChange}
              labelAlign="left"
              required={[{ required: true, message: "Es necesario!" }]}
            >
              <Input placeholder="Primer apellido" value={input.first_lastname} />
            </Form.Item>
            <Form.Item
              label="Segundo apellido"
              name="second_lastname"
              id="second_lastname"
              onChange={handleChange}
              labelAlign="left"
            >
              <Input placeholder="Segundo apellido" value={input.second_lastname} />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              id="email"
              onChange={handleChange}
              labelAlign="left"
              required={[{ required: true, message: "Es necesario!" }]}
            >
              <Input placeholder="Email" value={input.email} />
            </Form.Item>
            <Form.Item
              label="Teléfono"
              name="phone"
              id="phone"
              onChange={handleChange}
              labelAlign="left"
              required={[{ required: true, message: "Es necesario!" }]}
            >
              <Input placeholder="Telefono" value={input.phone} />
            </Form.Item>
            <Form.Item label="Contraseña" name="password" id="password" onChange={handleChange} labelAlign="left">
              <Input.Password placeholder="Contraseña" value={input.password} />
            </Form.Item>
            <Form.Item
              label="Confirma tu contraseña"
              name="confirmPassword"
              id="confirmPassword"
              onChange={handleChange}
              labelAlign="left"
            >
              <Input.Password placeholder="Contraseña" value={input.confirmPassword} />
            </Form.Item>
            {!data?.avatar && (
              <Form.Item
                label="Color de perfil"
                name="avatar_color"
                id="avatar_color"
                onChange={handleChange}
                labelAlign="left"
              >
                <Input type="color" value={input.avatar_color} />
              </Form.Item>
            )}
            {/* {!data?.avatar && <UploadButton id={data.id} />}
            {data?.avatar && <DeleteButton id={data.id} />} */}
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default UpdateUserForm;
