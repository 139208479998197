// react
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

// auth
import { AuthContext } from "../contexts/auth/AuthContext"

// misc
import Loading from "../components/Utils/Loading/Loading"

const PrivateRoute = ({ component, ...rest }) => {

  const {auth} = useContext(AuthContext)
  const isAuthenticated = auth.logged

  if (isAuthenticated === null) {
    return <Loading />;
  }
  if (!isAuthenticated) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return <Route {...rest} component={component} />;

};

export default PrivateRoute;
