// styles
import { Table, Button, Popconfirm, Divider, Row } from "antd";

import React, { useEffect, useState } from "react";
import { newDate } from "../../../../helpers/date";
import UpdateChannel from "./UpdateChannel/UpdateChannel";

import Filter from "../../../../components/Utils/Table/Filter/Filter";

const ChannelList = ({ fetchChannels, channels, deleteChannel, updateChannel }) => {
  
  const [ setFilters ] = useState(null);
  
  useEffect(() => {
    fetchChannels();
  }, [fetchChannels]);

  const handleFilter = () => {

    console.log("gooo filter");

  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Referencia",
      dataIndex: "channel_reference",
      key: "channel_reference",
    },
    {
      title: "Fecha alta",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => newDate(data).toFormat("dd'-'LL'-'yyyy T"),
    },
    {
      title: "Editar",
      key: "editar",
      align:"center",
      width: "10%",
      render: (_, record) => <UpdateChannel record={record} updateChannel={updateChannel} />,
    },
    {
      title: "Eliminar",
      key: "eliminar",
      align:"center",
      width: "10%",
      render: (_, record) => (
        <Popconfirm title="¿Estás seguro?" okText="Si" cancelText="No" onConfirm={() => deleteChannel(record?.id)}>
          <Button type="primary">Eliminar</Button>
        </Popconfirm>
      ),
    },
  ];

  const fields = [
    { label: "Nombre/Ref", type: "text", key: "usuario" },
  ];

  return (
    <>
      <Row>
        <Row justify="space-between" style={{ minWidth: "100%", alignItems: "center" }}>
          <Filter setFilters={setFilters} fields={fields} handleFilter={handleFilter} />
        </Row>

        <Divider style={{ margin: "15px 0" }} />
      </Row>

      <Table
        columns={columns}
        dataSource={channels}
        pagination={false}
        size="small"
        bordered={true}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default ChannelList;
