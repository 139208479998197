import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

// auth
import { AuthContext } from "../contexts/auth/AuthContext";

// misc
import Loading from "../components/Utils/Loading/Loading";

const PublicRoute = ({ component, ...rest }) => {
  const { auth } = useContext(AuthContext);
  const isAuthenticated = auth?.logged;
  const isAdmin = auth?.is_admin;
  const role = auth?.user?.role || "client_agent";

  if (isAuthenticated === null) {
    return <Loading />;
  }

  if (isAuthenticated && !isAdmin && role) {
    switch (role) {
      case "agency_admin":
        return <Redirect to={{ pathname: "/agency" }} />;
      case "client_admin":
        return <Redirect to={{ pathname: "/client" }} />;
      default:
        return <Redirect to={{ pathname: "/projects" }} />;
    }
  } else if (isAuthenticated && isAdmin) {
    return <Redirect to={{ pathname: "/admin" }} />;
  }

  return <Route {...rest} component={component} />;
};

export default PublicRoute;
