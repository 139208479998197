import React from "react";

import { ClientLayout } from "../../components/Utils/Layout";

const ClientDashboardPage = ({auth, logout}) => {
  return (
    <ClientLayout content_title="Dashboard" auth={auth} logout={logout}>
      <p>Client Dashboard content</p>
    </ClientLayout>
  );
};

export default ClientDashboardPage;
