// react
import { useCallback, useContext, useState } from "react";

// auth
import { AuthContext } from "../contexts/auth/AuthContext";

// misc
import { successNotification } from "../components/Utils/Notification/Notification";
import { fetchDataToken } from "../helpers/fetch";

const useUsers = () => {
  const { auth } = useContext(AuthContext);
  const [users, setUsers] = useState(null);

  const addUser = useCallback(
    async (data, params = {}) => {
      try {
        const response = await fetchDataToken("/user", data, "POST", params);
        if (response.status === 201) {
          successNotification("Usuario creado!");
          setUsers((prevUsers) => [...prevUsers, response.data]);
        }
      } catch (error) {
        throw error;
      }
    },
    [setUsers]
  );

  const updateUser = useCallback(
    async (id, data, params = {}) => {
      try {
        const response = await fetchDataToken(
          `/user/${id}`,
          data,
          "PATCH",
          params
        );
        // console.log("updateUser - data", response);
        if (response?.status === 200) {
          successNotification("Usuario actualizado!");
          setUsers((prevUsers) =>
            prevUsers?.map((user) => {
              if (user?.id === response?.data?.id) {
                return response.data;
              }
              return user;
            })
          );
        }
      } catch (error) {
        throw error;
      }
    },
    [setUsers]
  );

  const fetchUsers = useCallback(
    async (data) => {
      try {
        const response = await fetchDataToken("/user", data, "GET");
        if (response.status === 200) {
        //   console.log("users", response.data.results);
          setUsers(
            response.data.results.filter(
              (user) => user.email !== auth.user.email
            )
          );
        }
      } catch (error) {
        throw error;
      }
    },
    [setUsers, auth]
  );

  const deleteUser = useCallback(
    async (id) => {
      try {
        // console.log("deleteUserFromProject");
        const response = await fetchDataToken(`/user/${id}`, {}, "DELETE");
        if (response.status === 204) {
          setUsers((prevUsers) => {
            return prevUsers.filter(
              (user) => user.id !== auth.user.id && user.id !== id
            );
          });
        }
      } catch (error) {
        throw error;
      }
    },
    [setUsers, auth]
  );

  const fetchUsersFromProject = useCallback(async (data) => {
    try {
      const response = await fetchDataToken(
        "/user",
        { ...data, sortBy: "name" },
        "GET"
      );
      if (response.status === 200) {
        return response.data.results;
      }
    } catch (error) {
      throw error;
    }
  }, []);

  const fetchOwnUser = useCallback(async (user_id) => {
    try {
      const response = await fetchDataToken(
        "/user",
        { data: { userId: user_id } },
        "GET"
      );
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      throw error;
    }
  }, []);

  return {
    users,
    addUser,
    fetchUsers,
    setUsers,
    deleteUser,
    updateUser,
    fetchUsersFromProject,
    fetchOwnUser,
  };
};

export default useUsers;
