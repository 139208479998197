// react
import React, { useEffect, useState, useCallback } from "react";

// styles
import { Table, Divider, Row, Popconfirm, Button } from "antd";

// helpers
import { newDate } from "../../helpers/date";
import { sorterByLetters } from "../../helpers/sorter";
import Filter from "../Utils/Table/Filter/Filter";

// components
// import UpdateTag from "./UpdateTag";
import TableHead from "../Utils/Table/TableHead";
import TableCell from "../Utils/Table/TableCell.js";
import Popup from "../Table/RowPopup/Popup";
import ShowModal from "../Table/RowPopup/ShowModal";
import UpdateWebhook from "./form/UpdateWebhook";
// import TableCell from "../../../Utils/Table/TableCell";
// import TagTotalVisits from "./Utils/TagTotalVisits";

const WebhooksList = ({
  webhooks,
  fetchWebhooks,
  loading,
  webhooksPagination,
  setWebhooksPagination,
  updateWebhook,
}) => {
  const [popup, setPopup] = useState({
    visible: false,
    x: 0,
    y: 0,
    showModal: false,
    option: "",
  });

  const onRow = (record) => {
    return {
      onContextMenu: (event) => {
        event.preventDefault();
        if (!popup.visible) {
          if (!popup.showModal) {
            document.addEventListener(`click`, function onClickOutside() {
              setPopup((prev) => ({ ...prev, visible: false }));
              document.removeEventListener(`click`, onClickOutside);
            });
          }
        }
        setPopup({
          record,
          visible: true,
          x: event.pageX,
          y: event.pageY,
          showModal: false,
        });
      },
    };
  };

  const [filters, setFilters] = useState(null);

  const loadWebhooksDataOnlyOnce = useCallback(
    (params) => {
      fetchWebhooks({
        ...params,
        populate: "agency",
      });
    },
    [fetchWebhooks]
  );

  useEffect(() => {
    loadWebhooksDataOnlyOnce({ page: 1 });
  }, [loadWebhooksDataOnlyOnce]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (data) => data,
      width: "100px",
    },
    {
      title: <TableHead title="Agencia" align="right" />,
      dataIndex: "agency",
      key: "agency",
      render: (data) => {
        return <TableCell data={data?.name || data?.id} />;
      },
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Acción" align="right" />,
      dataIndex: "webhook_create_lead",
      key: "action",
      render: (data) => {
        return <TableCell data={data?.action || "Sin descripción"} />;
      },
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Activo" align="right" />,
      dataIndex: "webhook_create_lead",
      key: "webhook_create_lead_is_active",
      render: (data) => {
        const isActive = data?.is_active ? "Activado" : "Inactivo";
        return <TableCell data={isActive} />;
      },
      showSorterTooltip: false,
    },
    {
      title: <TableHead title="Url" align="right" />,
      dataIndex: "webhook_create_lead",
      key: "webhook_create_lead_url",
      render: (data) => {
        return <TableCell data={data?.url || "N/A"} />;
      },
      showSorterTooltip: false,
    },
    {
      title: "Editar",
      key: "editar",
      align: "center",
      width: "20%",
      render: (_, record) => (
        <UpdateWebhook record={record} updateWebhook={updateWebhook} />
      ),
    },
  ];

  const pageChangeHandler = (pagination, filters, sorter) => {
    // console.log("pagination", pagination);
    // console.log("filters", filters);
    // console.log("sorter", sorter);
    setWebhooksPagination((prev) => ({ ...prev, current: pagination.current }));
    loadWebhooksDataOnlyOnce({
      ...filters,
      ...sorter,
      page: pagination.current,
    });
  };

  const handleFilter = () => {
    // console.log("------ handleFilter - filters", filters);
    const params = {
      ...filters,
      page: webhooksPagination?.current || 1,
      limit: webhooksPagination?.pageSize || 10,
    };
    // console.log("------ handleFilter - params", params);

    loadWebhooksDataOnlyOnce(params);
  };

  const fields = [{ label: "Nombre", type: "text", key: "agency.name" }];

  return (
    <>
      <Row>
        <Row
          justify="space-between"
          style={{ minWidth: "100%", alignItems: "center" }}
        >
          <Filter
            setFilters={setFilters}
            fields={fields}
            handleFilter={handleFilter}
          />
        </Row>

        <Divider style={{ margin: "15px 0" }} />
      </Row>

      <Table
        rowKey={(record) => record?.id}
        columns={columns}
        dataSource={webhooks}
        pagination={webhooksPagination}
        size="small"
        bordered={true}
        loading={loading}
        onRow={onRow}
        onChange={pageChangeHandler}
      />
      <Popup
        record={popup.record}
        visible={popup.visible}
        x={popup.x}
        y={popup.y}
        setPopup={setPopup}
        RT={false}
      />
      <ShowModal
        show={popup.showModal}
        setPopup={setPopup}
        popup={popup}
        record={popup.record}
      />
    </>
  );
};

export default WebhooksList;
