// react
import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";

// auth
import { AuthContext } from "../../../contexts/auth/AuthContext";

// styles
import { Form, Input, Button, Checkbox, Row, Col, Card } from "antd";

// misc
import {
  errorNotification,
  infoNotification,
} from "../../Utils/Notification/Notification";

const LoginForm = () => {
  const { login } = useContext(AuthContext);

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmitClick = async (e) => {
    e.preventDefault();
    const haveEmailAndPassword =
      (state.email?.trim()).length && (state.password?.trim()).length;
    if (!haveEmailAndPassword) {
      infoNotification("Introduce las credenciales");
    }
    if (haveEmailAndPassword) {
      const data = {
        email: state.email.trim(),
        password: state.password.trim(),
      };

      try {
        await login(data);
      } catch (error) {
        if (error.response) {
          errorNotification("Error", error.response.data.msg);
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error("Error", error.message);
        }
      }
    }
  };

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "92vh" }}
    >
      <Col>
        <Card>
          <Form
            initialValues={{
              remember: true,
            }}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Por favor introduce tu email" },
              ]}
              id="email"
              value={state.email}
              onChange={handleChange}
            >
              <Input placeholder="ejemplo@ejemplo.com" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Por favor introduce tu contraseña",
                },
              ]}
              id="password"
              placeholder="Password"
              value={state.password}
              onChange={handleChange}
            >
              <Input.Password placeholder="Tu contraseña" />
            </Form.Item>
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Recuérdame</Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleSubmitClick}
                style={{ width: "100%" }}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default withRouter(LoginForm);
