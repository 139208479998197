// react
import React, { useState, useCallback, useEffect } from "react";
import { Button } from "antd";

import ProjectForm from "./ProjectForm";

import useAgencies from "../../../hooks/useAgencies";
import useClients from "../../../hooks/useClients";

const AddProject = ({ addProject }) => {
  const [visible, setVisible] = useState(false);
  const { agencies, fetchAgencies } = useAgencies();
  const { clients, fetchClients } = useClients();

  const showModal = () => {
    setVisible(true);
  };

  const loadAgenciesDataOnlyOnce = useCallback(
    (params) => {
      fetchAgencies({ ...params, populate: "created_by_user" });
    },
    [fetchAgencies]
  );

  useEffect(() => {
    if (visible) {
      loadAgenciesDataOnlyOnce({ limit: 99999 });
    }
  }, [loadAgenciesDataOnlyOnce, visible]);

  const loadClientsDataOnlyOnce = useCallback(
    (params) => {
      fetchClients({
        ...params,
        // populate: "created_by_user,projects",
      });
    },
    [fetchClients]
  );

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Añadir Proyecto
      </Button>

      <ProjectForm
        setVisible={setVisible}
        visible={visible}
        addProject={addProject}
        agencies={agencies}
        clients={clients}
        loadClientsDataOnlyOnce={loadClientsDataOnlyOnce}
      />
    </>
  );
};

export default AddProject;
